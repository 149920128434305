import { Box, Card, Grid, Typography } from '@mui/material';
import React from 'react'
import { FormatPrice } from '../utils/FormatPrice';
import { Link } from 'react-router-dom';

const HomeItem = ({item}) => {

    const { _id, title, price, images } = item;

    return (
        <Grid key={_id} item xs={6} sm={4} md={3} mb={6}>
            <Card sx={{ borderRadius: '6px', boxShadow: 'none' }}>
                <Box>
                    <Link to={`/singleproduct/${_id}`}>
                        <img src={images[0]} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Link>
                </Box>

            </Card>
            <Box pt={3}>
                <Link to={`/singleproduct/${_id}`}>
                    <Typography noWrap textTransform='uppercase' fontWeight={600} color='#222222' fontFamily='Apple Symbols , Open Sans Helvetica Neue'>
                        {title}
                    </Typography>
                </Link>
                <Typography variant="subtitle2" noWrap color='#999' mt={1}>
                    {<FormatPrice price={price} />}
                </Typography>
            </Box>
        </Grid>
    )
}

export default HomeItem;