// this is the review of the user's order

import { Grid, Box, Typography, Container, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import CheckoutSteps from '../Components/CheckoutSteps';
import axios from 'axios';
import { toast } from 'react-toastify';
import { orderPlaced } from '../store/slices/OrderSlice';

const Order = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [coupon, setCoupon] = useState(null);
    const [discountPercentage, setDiscountPercentage] = useState(null);

    const cartProducts = useSelector((state) => state.carts);
    const { cartItems } = cartProducts;
    const { shippingDetails } = useSelector((state) => state.user);
    const { name, street, city, State, phone, country, pincode } = shippingDetails;
    
    const subtotal = cartItems.reduce((total, item) => total + item.cartQuantity * item.product.price, 0);

    const handleCoupon = async () => {
        try {
            if (coupon) {
                const resp = await axios.post('https://swarnsutra.com/api/validateCoupon', { couponCode: coupon });
                if (resp.data.status === 200) {
                    setDiscountPercentage(resp.data.discount);
                    toast.success(resp.data.message);
                }else{
                    toast.error(resp.data.message);
                }
            }
            else {
                toast.warn("Please Enter Your Coupon Code")
            }
            
        } catch (error) {
            toast.error('Internal Server Error');
        }
        
    }
    
    const discount = Math.round((subtotal * discountPercentage / 100));
    const totalAmount = subtotal - discount;

    const handleOrder = () => {
        const order = { cartItems , totalAmount, discount};
        dispatch(orderPlaced(order));
        navigate('/order/payment');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
       {cartItems.length === 0 && <Navigate to='/' ></Navigate>}
        <Container sx={{ padding: { xs: '1rem 2rem', md: '1.5rem 1rem'} }}>
            <CheckoutSteps activeStep={1} />
            <Grid container mt={2}>
                <Grid item xs={12} md={8} lg={8}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={11}>
                            <Box border='1px solid #E5E7EB' borderRadius='0.325rem' p={2}>
                                <Typography variant='h6' color='#111827' fontFamily='Apple Symbols,Open Sans, Helvetica Neue'>Shipping Address:</Typography>
                                <Typography color='#6B7280'>Name: {name}</Typography>
                                <Typography color='#6B7280'>Mob No: {phone}</Typography>
                                <Typography color='#6B7280'>Address: {street}, {city}, {State} -{pincode}, {country}</Typography>
                            </Box>
                        </Grid>
                        
                        <Grid item xs={12} sm={11} p={2}>

                            <Typography variant='h6' color='#111827' fontFamily='Apple Symbols,Open Sans, Helvetica Neue'>Your Cart Items</Typography>
                            <Box mt={2}>

                                {
                                    cartItems && cartItems.map((item) => (
                                        <Box key={item._id} display='flex' sx={{ py: { xs: '1.5rem', sm: '2.5rem' } }} borderTop='1px solid #E5E7EB'>
                                            <Box>
                                                <Box component='img' src={item.product.images[0]} sx={{ objectFit: 'cover', objectPosition: 'center', borderRadius: '0.375rem', maxWidth: '100%', width: { xs: '6rem', sm: '8rem' }, height: { xs: '8rem', sm: '8rem', md:'8rem'} }} />
                                            </Box>
                                            <Box display='flex' justifyContent='space-between' flexDirection='column' sx={{ ml: { xs: '1rem', sm: '1.5rem' } }} flex='1 1 0%'>
                                                <Box sx={{ display: { sm: 'grid' }, gridTemplateColumns: { sm: 'repeat(2, minmax(0, 1fr))' }, columnGap: { sm: '1.5rem' } }}>
                                                    <Box>
                                                        <Box display='flex' justifyContent='space-between'>
                                                            <p color='#1F2937'  style={{fontWeight:'500', textTransform:'uppercase', fontFamily:'Apple Symbols,Open Sans, Helvetica Neue', lineHeight: '1.25rem', margin:'0px'}}>{item.product.title}</p>
                                                        </Box>
                                                        <Box display='flex'  lineHeight='1.25rem' mt='0.25rem'>
                                                            <p style={{ color: '#6B7280' }}>{item.product.category}</p>
                                                            <p style={{ color: '#6B7280', paddingLeft: '1rem', marginLeft: '1rem', borderLeft:'1px solid #9CA0A5' }}>{item.product.color}</p>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{mt:{sm:'0px'}, textAlign:{sm:'right'}}}>
                                                       <p style={{ color: '#6B7280', marginTop:'0px'}}>₹{item.product.price}.00</p>
                                                    </Box>
                                                </Box>

                                                <p style={{ color: '#111827', fontWeight: '500', fontSize: '0.875rem',lineHeight:'1.25rem', margin:'0px', marginTop:'0.25rem' }}>Qty: {item.cartQuantity}</p>
                                            </Box>

                                        </Box>
                                    ))
                                }
                            </Box>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} lg={4} mt={{md:'12rem'}}>
                    <Box display='flex' flexWrap='wrap' gap={2} mb={4} >

                        <TextField
                            id="coupon"
                            label="Enter Coupon Code"
                            type="text"
                            fullWidth
                            onChange={(e) => setCoupon(e.target.value)}
                        />
                        <Button
                            onClick={() => handleCoupon()}
                            variant='contained'
                            sx={{ textTransform: 'capitalize', border: 'none', color: '#fff', backgroundColor: '#ae12ae', '&:hover': { bgcolor: '#d616d6', border: 'none' } }}
                        >
                            Apply Coupon
                        </Button>
                    </Box>
                    <Box border='1px solid #e3d7d7' borderRadius={2} p={2}>
                        <Typography fontSize='1.15rem' textAlign='center'>Order Summary</Typography>
                        <Box >

                            <Box display='flex' justifyContent='space-between' mt={3} pb={1} borderBottom='1px solid #e3d7d7'>
                                <Typography color='#6B7280'>Subtotal:</Typography>
                                <span>₹{subtotal}</span>
                            </Box>
                            <Box display='flex' justifyContent='space-between' mt={1} pb={1} borderBottom='1px solid #e3d7d7'>
                                <Typography color='#6B7280'>Shipping Charge:</Typography>
                                <span>₹0.00</span>
                            </Box>
                            {
                                discountPercentage && <Box color='green' display='flex' justifyContent='space-between' mt={1} pb={1} borderBottom='1px solid #e3d7d7'>
                                    <Typography>discount:</Typography>
                                    <span>₹{discount}</span>
                                </Box>
                            }

                            <Box display='flex' justifyContent='space-between' mt={2}>
                                <Typography fontWeight='bold'>Total:</Typography>
                                <span><b> ₹{totalAmount}</b></span>
                            </Box>
                            <Button variant='contained' fullWidth sx={{ textTransform: 'capitalize', fontSize: '1rem', marginTop: '15px', border: 'none', backgroundColor: '#ae12ae', '&:hover': { bgcolor: '#d616d6', border: 'none' } }} onClick={() => handleOrder()}>Proceed to Payment</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </>
    )
}

export default Order;
