import { Box, Card, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {FormatPrice} from '../utils/FormatPrice';
import LocalMallIcon from '@mui/icons-material/LocalMall'; // product
import GroupsIcon from '@mui/icons-material/Groups';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useLocation } from 'react-router-dom';
import Loading from '../Pages/Loading';

const columns = [
  { id: 'orderId', label: 'Order-Id', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 100 },
  {
    id: 'address',
    label: 'Address',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  { id: 'mob', label: 'Mob. No.', minWidth: 170, align: 'right'},
  {
    id: 'price',
    label: 'Price',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
];

function createData(orderId, name, address, mob, price) {
  return { orderId, name, address, mob, price };
}

const Dashboard = () => {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [latestOrder, setLatestOrder] = useState([]);
  const location = useLocation();
  const [loading,setLoading]=useState(false);
  // console.log(location.pathname)

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const totalAmt = latestOrder.reduce((prevVal, currVal)=> { 
    const total = prevVal + parseInt(currVal.totalAmount);
    return total;
  }, 0);
  
  const Icons = [
    
    { title: "Customers", total: 9, icon: <GroupsIcon />, iconcolor: 'rgb(12, 83, 183)', cardcolor: 'rgb(4, 41, 122)', cardbgcolor: 'rgb(208,242,252)' },
    { title: "Orders", total: `${latestOrder.length}`, icon: <LocalMallIcon />, iconcolor: 'rgb(183, 129, 3)', cardcolor: 'rgb(122,79,1)', cardbgcolor: 'rgb(255, 247, 205)' },
    { title: "Total Sale", total: `${totalAmt}`, icon: <CurrencyRupeeIcon />, iconcolor: 'rgb(183, 33, 54)', cardcolor: 'rgb(122, 12, 46)', cardbgcolor: 'rgb(255, 231, 217)' }
  ]
  const getlatestOrder= async()=>{
      setLoading(true);
      const resp = await fetch('https://swarnsutra.com/api/getAdminOrder');
      resp.json().then((data)=> {
         const order = data.slice(-10, data.length).reverse();
         setLatestOrder(order);
         setLoading(false);
        
      });
      
  }
 
  useEffect(()=>{
      getlatestOrder();
  }, [location.pathname])

  if(loading){
    return(
      <Loading/>
    )
  }
  return (
    <Box sx={{ margin: { xs: '56px 0 0 0',sm: '64px 0 0 0', md: '64px 0 0 280px' }, padding: '10px 0px' , backgroundColor: 'rgb(248,250, 255)'}}>
      <Container maxWidth="xl">
        <Typography variant="h4"  fontWeight={700} sx={{ mb: 5 , fontSize:{xs:'1.25rem', sm:'1.25rem', md:'1.5rem'}}}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {
            Icons.map((icon) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={icon.title}>
                  <Card
                    sx={{
                      py: 5,
                      boxShadow: 0,
                      textAlign: 'center',
                      color: `${icon.cardcolor}`,
                      bgcolor: `${icon.cardbgcolor}`

                    }}

                  >
                    <Box
                      sx={{
                        margin: 'auto auto 22px',
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '64px',
                        height: '64px',
                        color: `${icon.iconcolor}`,
                        backgroundImage: 'linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)'
                      }}
                    >
                      {icon.icon}
                    </Box>

                    <Typography variant="h3" fontWeight={700} lineHeight={1.52} sx={{ fontSize: { xs: '1.5rem', sm: '1.625rem', md: '1.875rem' } }}>{icon.total}</Typography>

                    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                      {icon.title}
                    </Typography>
                  </Card>
                </Grid>
              )
            })
          }

        </Grid>


        <Typography variant="h5" mt={4} mb={2}>
          Latest Orders
        </Typography>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{padding:'0 1rem 1rem 1rem'}}>
            <Table stickyHeader aria-label="table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth , fontWeight: 600}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                { latestOrder &&
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  latestOrder.slice(0,5).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                        {/* {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })} */}
                        <TableCell align='left'>{row._id}</TableCell>
                        <TableCell align='left'>{row.address.name}</TableCell>
                        <TableCell align='right'>{row.address.street}, {row.address.city}, {row.address.state}-{row.address.pincode}</TableCell>
                        <TableCell align='right'>{row.address.phone}</TableCell>
                        <TableCell align='right'>{<FormatPrice price={row.totalAmount}/>}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          
        </Paper>

      </Container>
    </Box>
  )
}

export default Dashboard;