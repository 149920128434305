// when user forgot there password this components will help

import React, { useState } from 'react'
import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { json, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './Loading';
import axios from 'axios';

const Forgot = () => {
  const [email,setEmail]=useState('')
  const [name,setName]=useState('')
  const [OTP,setOTP]=useState('')
  const [password,setPassword]=useState('')
  const [cPassword,setCPassword]=useState('')
  const [show2,setShow2]=useState(false);
  const [loading,setLoading]=useState(false);
  const [show,setShow]=useState(false);

const navigate=useNavigate();
const handleSubmit=async(e)=>{
  e.preventDefault();
  setLoading(true);
  try{
    const resp=await axios.post('https://swarnsutra.com/api/forgotPassword',{email:email});
    if(resp.data.status==200){
      setLoading(false);
      toast.success(`${resp.data.message}`);
      setName(resp.data.name);
      setShow(true);
    }else{
      setLoading(false);
      toast.error(`${resp.data.message}`);
    }
  }catch(error){
    setLoading(false);
    toast.error("Something went wrong try later!");
  }
}

const handleVerify=async(e)=>{
  e.preventDefault();
  setShow(false);
  setLoading(true);
  try{
    const data={
      email:email,
      otp:OTP
    }
    const resp=await axios.post('https://swarnsutra.com/api/verifySignup',data);
    if(resp.data.status==200){
      setLoading(false);
      toast.success(`${resp.data.message}`);
      setShow2(true);
    }else{
      setLoading(false);
      toast.error(`${resp.data.message}`);
      setShow(true);
    }
  }catch(error){
    setLoading(false);
    toast.error("Something went wrong try later!");
    setShow(true);
  }
}

const handleVerify2=async(e)=>{
  e.preventDefault();
  setShow2(false);
  setLoading(true);
  if(password==cPassword){
    try {
      const data={
        email:email,
        password:password
      }
      const resp=await axios.put('https://swarnsutra.com/api/updatePassword',data);
      if(resp.data.status==200){
        setLoading(false);
        setShow2(true);
        toast.success(`${resp.data.message}`);
        navigate('/login');
      }else{
        setLoading(false)
        setShow2(true);
        toast.error(`${resp.data.message}`);
      }
    } catch (error) {
      setLoading(false)
      setShow2(true);
      toast.error(`Something went wrong try later!`);
    } 
  }else{
    setLoading(false);
    setShow2(true);
    toast.error("Password not matched !")
}
}

  if(loading){
    return(
      <Loading/>
    )
  }

  if(show){
    return(
      <Container component="main" maxWidth="xs" sx={{paddingBottom:"2rem",boxShadow:"rgb(32 32 32 / 8%) 0px 10px 32px 0px;"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify Your Account
          </Typography>
          <Box component="form" onSubmit={handleVerify} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  disabled
                  label={name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  disabled
                  label={email}
                  fullWidth
                />
              </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Enter OTP"
                  fullWidth
                  required
                  onChange={(e)=>setOTP(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Validate With OTP
            </Button>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>
    )
  }

if(show2){
  return(
    <Container component="main" maxWidth="xs" sx={{paddingBottom:"2rem",boxShadow:"rgb(32 32 32 / 8%) 0px 10px 32px 0px;",marginBottom:"2rem"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Update Your Password
          </Typography>
          <Box component="form" onSubmit={handleVerify2} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  disabled
                  label={name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  disabled
                  label={email}
                  fullWidth
                />
              </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Password"
                  type='password'
                  required
                  fullWidth
                  onChange={(e)=>setPassword(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Confirm Password"
                  required
                  type='password'
                  fullWidth
                  onChange={(e)=>setCPassword(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Update
            </Button>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>
  )
}

  return (
    <Container component="main" maxWidth="xs" sx={{paddingBottom:"2rem",boxShadow:"rgb(32 32 32 / 8%) 0px 10px 32px 0px;",marginBottom:"2rem"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Your Account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Enter Your Email"
                  fullWidth
                  required
                  autoComplete="email"
                  onChange={(e)=>setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send OTP
            </Button>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>
  )
}

export default Forgot;
