import React, { useState, useEffect } from 'react'
import { Container , Box, Typography, Grid} from '@mui/material';
import { useLocation } from 'react-router-dom';
import img1 from '../images/g2.jpg'

const StoryPage = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' margin='20px auto' padding='10px 20px'>
                <Typography variant="h4" mb={2}
                    sx={{
                        position: 'relative',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            width: '100%',
                            height: '3px',
                            display: 'block',
                            margin: '0 auto',
                            backgroundColor: '#4caf50'
                        }
                    }}
                >
                    Our Story
                </Typography>
                <Typography fontSize='1.175rem' color='#666'>"Embrace Elegance with Swarn Sutra's Nature-Inspired Handloom Fabrics: Weaving a Bright Future for Indigenous Artisans"</Typography>
            </Box>

            <Grid container spacing={4} mt={2} mb={2} padding='10px 20px'>
                <Grid item sm={12} overflow='hidden'>

                    <Box component='img' src={img1} maxWidth='100%' width='100%' sx={{
                        height: 'auto', objectFit: 'cover', borderRadius: '8px'
                        
                    }}
                       
                    />
                </Grid>

                <Grid item sm={12} >
                    <Typography variant="h5" fontWeight='bold'>Where does it come from?</Typography>
                    <Typography mt={2} fontSize='1.125rem'>
                    At Swarnsutra, we have embarked on a mission to reclaim and revive the glory of Indian traditional handloom textiles, making them accessible and affordable to all. Our journey began with a deep appreciation for the artistic heritage and cultural significance embedded in each thread of handloom fabrics.
                    </Typography>
                    <Typography mt={2} fontSize='1.125rem'>Recognizing the challenges faced by traditional artisans, we set out to create a platform that not only showcased their incredible craftsmanship but also provided fair opportunities for their sustainable livelihoods. With a firm belief in the transformative power of handlooms, we sought to bridge the gap between tradition and modernity.
                    </Typography>
                    <Typography mt={2} fontSize='1.125rem'>
                    Through extensive research and collaboration with skilled weavers across different regions of India, we have curated a diverse collection of handloom textiles that reflect the incredible diversity and richness of our country's heritage. Every piece we offer is a testament to the skill, passion, and dedication of our artisans.
                    </Typography>
                    <Typography mt={2} fontSize='1.125rem'>Our commitment to affordability has been unwavering. We firmly believe that everyone should have the opportunity to experience the beauty of handloom textiles without breaking the bank. By streamlining our supply chain and minimizing intermediaries, we have managed to offer our products at affordable prices, ensuring that more people can embrace the elegance of Indian handlooms.
                    </Typography>
                    <Typography Typography mt={2} fontSize='1.125rem'>
                    With every purchase made, you become a part of our story - a story of sustainable fashion, cultural preservation, and social empowerment. Together, we are creating a ripple effect that not only supports artisans but also encourages the growth and sustainability of the Indian traditional handloom industry.
                    </Typography>
                    <Typography Typography mt={2} fontSize='1.125rem'>
                    Join us on this inspiring journey as we celebrate the artistry, heritage, and timeless elegance of Indian handlooms. Together, we can make a difference and contribute to a more inclusive and vibrant future for our artisans and their extraordinary craft.
                    </Typography>
                </Grid>

            </Grid>
        </Container>
    )
}

export default StoryPage;