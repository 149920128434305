import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';

import Home from '../Pages/Home';
import Product from '../Pages/Product';
import About from '../Pages/About';
import GoalPage from '../Pages/GoalPage';
import StoryPage from '../Pages/StoryPage';
import DesignerPage from '../Pages/DesignerPage';
import Blog from '../Pages/Blog';
import Header from './Header';
import Cart from '../Pages/Cart';
import SingleProduct from '../Pages/SingleProduct';
import SingleBlog from '../Pages/SingleBlog';
import Login from '../Pages/Login';
import Signup from '../Pages/Signup';
import CategoryProducts from '../Pages/CategoryProducts';

import ProtectRoutes from '../utils/ProtectRoutes';
import Profile from '../Pages/Profile';
import Shipping from '../Pages/Shipping';
import Order from '../Pages/Order';
import Payment from '../Pages/Payment';
import Succeess from '../Pages/Success';
import OrderHistory from '../Pages/OrderHistory';
import ProfileUpdate from '../Pages/ProfileUpdate';
import Dashboard from '../admin/Dashboard';
import AllProducts from '../admin/AllProducts';
import NewProduct from '../admin/NewProduct';
import AdminOrders from '../admin/AdminOrders';
import UserPage from '../admin/UserPage';
import Checking from '../Pages/Checking';

import VerifyLogin from '../Pages/VerifyLogin';
import VerifyOTP from '../Pages/VerifyOTP';
import Forgot from '../Pages/Forgot';

import TermsCondition from '../Pages/TermsCondition';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import ReturnPolicy from '../Pages/ReturnPolicy';
import Footer from '../Pages/Footer';
import AdminNav from '../admin/AdminNav';
import { useDispatch } from 'react-redux';
import { userLogedIn } from '../store/slices/UserSlice';
import AdminProtectedRoutes from '../utils/AdminProtectedRoutes';
import { productsFetch } from '../store/slices/FilterSlice';
import { fetchCategories } from '../store/slices/categorySlice';
import Pagenotfound from '../Pages/Pagenotfound';
import ChangePassword from '../Pages/ChangePassword';
import Update from '../admin/Update';
import EditBill from '../admin/EditBill';
import Coupon from '../admin/Coupon';
import NewCategory from '../admin/NewCategory';
import Successfull from '../Pages/Successfull';
import { fetchCartItemsAsync } from '../store/slices/CartSlice';
import DeleteCategory from '../admin/DeleteCategory';

const Layout = () => {
    const dispatch = useDispatch();
    const loaction = useLocation();
    const {pathname} = useLocation();
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchCategories());
        dispatch(productsFetch());
        

        if (userInfo) {
            dispatch(userLogedIn(userInfo));
            dispatch(fetchCartItemsAsync(userInfo.userId))
        }
    }, [dispatch, pathname]);

    return (
        <>

            {JSON.parse(localStorage.getItem("userInfo"))?.role === "admin" && loaction.pathname.startsWith('/dashboard') ? <AdminNav /> : <Header />}
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/products' element={<Product />} />
                <Route path='/singleproduct/:id' element={<SingleProduct />} />
                <Route path='/products/:category' element={<CategoryProducts />} />
                <Route path='/about' element={<About />} />
                <Route path='/goal' element={<GoalPage />} />
                <Route path='/story' element={<StoryPage />} />
                <Route path='/designer' element={<DesignerPage />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/blog/:id' element={<SingleBlog/>} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<Signup />} />

                <Route path='/verifylogin' element={<VerifyLogin />} />
                <Route path='/verifyotp' element={<VerifyOTP />} />
                <Route path='/resetaccount' element={<Forgot />} />
                <Route path='*' element={<Pagenotfound />} />

                {/* User Protected */}
                <Route element={<ProtectRoutes />}>
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/shipping' element={<Shipping />} />
                    <Route path='/order' element={<Order />} />
                    <Route path='/order/payment' element={<Payment />} />
                    <Route path='/order/success' element={<Succeess />} />
                    <Route path='/orderhistory' element={<OrderHistory />} />
                    <Route path='/profileupdate' element={<ProfileUpdate />} />
                    <Route path='/status' element={<Checking />} />
                    <Route path='/changepassword' element={<ChangePassword />} />
                    <Route path='/order/successfull' element={<Successfull />} />
                </Route>

                {/* Admin Protected */}
                <Route element={<AdminProtectedRoutes />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/dashboard/allproducts' element={<AllProducts />} />
                    <Route path='/dashboard/products/new' element={<NewProduct />} />
                    <Route path='/dashboard/orders' element={<AdminOrders />} />
                    <Route path='/dashboard/users' element={<UserPage />} />
                    <Route path='/dashboard/editBill' element={<EditBill />} />
                    <Route path='/dashboard/coupon' element={<Coupon />} />
                    <Route path='/dashboard/update/:productId' element={<Update />} />
                    <Route path='/dashboard/category/new' element={<NewCategory />} />
                    <Route path='/dashboard/category/delete' element={<DeleteCategory />} />
                </Route>

                <Route path='/termsAndCondition' element={<TermsCondition />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} />
                <Route path='/return-refund-policy' element={<ReturnPolicy />} />
            </Routes>

            {loaction.pathname.startsWith('/dashboard') ? '' : <Footer />}
        </>
    )
}

export default Layout;