import { Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';

const Action =({id})=>{
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOrderStatus =async (event, orderId) => {
        setAnchorEl(null);
        
         const resp=await axios.put('https://swarnsutra.com/api/changeOrderStatus',{orderId, status:event.target.innerText});

         if(resp.data.status == 200){
            window.location.reload();
         } 
    };

    return(
        <div>
        <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
    >
        Actions
    </Button>
    <Menu
        id="demo-customized-menu"
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
    >
        <MenuItem onClick={(e) =>handleOrderStatus(e, id)} disableRipple>
            Dispatched
        </MenuItem>
        <MenuItem onClick={(e) =>handleOrderStatus(e, id)} disableRipple>
            Shipped
        </MenuItem>
        <MenuItem onClick={(e) =>handleOrderStatus(e, id)} disableRipple>
            Delivered
        </MenuItem>
    </Menu>
    </div>

    )
}

export default Action;