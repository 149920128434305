// this is the loader components

import { Box, Grid } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'

function Loading() {
  return (
      <Grid container>
        <Grid item textAlign="center" m={10} p={10} xs={12}>
          <span className="loader"></span>
        </Grid>
      </Grid>
  )
}

export default Loading
        // <Box m={10} p={10} textAlign="center">
        // </Box>