import { Container, Grid } from '@mui/material'
import React from 'react'

function Pagenotfound() {
  return (
    <Grid Container textAlign='center'>
        <Grid item xs={12} p={10}>
            Page not found!
        </Grid>
    </Grid>
  )
}

export default Pagenotfound