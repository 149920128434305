import { configureStore } from "@reduxjs/toolkit";

import categorySlice from "./slices/categorySlice";
import filterSlice from "./slices/FilterSlice";
import cartSlice from "./slices/CartSlice";
import userSlice from './slices/UserSlice';
import orderSlice from './slices/OrderSlice';


const store = configureStore({
    reducer:{
        category: categorySlice,
        filters: filterSlice,
        carts: cartSlice,
        user: userSlice,
        order: orderSlice
    }
});

export default store;