import React, { useState } from 'react'
import { Avatar, Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './Loading';
import axios from 'axios';
import { userLogOut } from '../store/slices/UserSlice';
import { useDispatch } from "react-redux";

function ChangePassword() {
    const [oldPassword,setOldPassword]=useState('');
    const [newPassword,setNewPassword]=useState('');
    const [cNewPassword,setCNewPassword]=useState('');
    const [loading,setLoading]=useState(false);
    const dispatch = useDispatch();
    const navigate=useNavigate();
    var userInfo=JSON.parse(localStorage.getItem("userInfo"));

    const handleSubmit=async(e)=>{
        e.preventDefault();
        if(newPassword==cNewPassword){
            setLoading(true);
            const data={
                email:userInfo.email,
                oldPassword:oldPassword,
                newPassword:cNewPassword

            }
            const resp=await axios.put('https://swarnsutra.com/api/changePassword',data,{headers:{'authorization':userInfo.token}});
            if(resp.data.status==505){
                setLoading(false);
                dispatch(userLogOut());
                localStorage.removeItem("userInfo")
                toast.error(`${resp.data.message}`);
                navigate('/login');
            }else if(resp.data.status==200){
                setLoading(false);
                toast.success(`${resp.data.message}`);
                navigate('/profile');
            }else{
                setLoading(false);
                toast.error(`${resp.data.message}`);
            }
        }else{
            toast.error('Password not matched!');
        }
        
    }


    if(loading){
        return(
            <Loading/>
        )
    }

    return(
        <Container component="main" maxWidth="xs" sx={{paddingBottom:"2rem",boxShadow:"rgb(32 32 32 / 8%) 0px 10px 32px 0px;",marginBottom:"2rem"}}>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Change your password
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      disabled
                      fullWidth
                      label={userInfo.email}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      label="Old Password"
                      type='password'
                      required
                      fullWidth
                      onChange={(e)=>setOldPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      label="New Password"
                      required
                      type='password'
                      fullWidth
                      onChange={(e)=>setNewPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      label="Confirm New Password"
                      required
                      type='password'
                      fullWidth
                      onChange={(e)=>setCNewPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Update
                </Button>
                </Grid>
              </Grid>
              </Box>
            </Box>
          </Container>
      )
}

export default ChangePassword