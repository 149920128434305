import React, { useState, useEffect } from 'react'
import { Container , Box, Typography, Grid} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Designer from '../images/Designer.jpg'

const DesignerPage = () => {
    const [isHovered, setIsHovered] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <Container>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' margin='20px auto' padding='10px 20px'>
                <Typography variant="h4" mb={2}
                    sx={{
                        position: 'relative',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            width: '100%',
                            height: '3px',
                            display: 'block',
                            margin: '0 auto',
                            backgroundColor: '#4caf50'
                        }
                    }}
                >
                    Our Designer
                </Typography>
            </Box>

            <Grid container spacing={4} mt={2} mb={2} padding='10px 20px'>
                <Grid item  xs={12} md={6} overflow='hidden'>

                    <Box component='img' src={Designer} maxWidth='100%' sx={{
                        height: { xs: 'auto', md: '400px' }, objectFit: 'cover', borderRadius: '8px', transition:'0.5s ease',
                        transform: isHovered ? 'scale(1.5)' : ''
                    }}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    />
                </Grid>

                <Grid item  xs={12} md={6}>
                   <h3>About Designer</h3>
                    <Typography mt={2} fontSize='1.125rem'>
                    Pranjali is a talented designer who smoothly combines traditional Indian handloom methods with contemporary trends. She exhibits the rich tradition of Indian textiles by working with materials such as cotton, linen, merino wool, pashmina silk, eri silk, organic cotton, and Banarasi silk. Her delicate threadwork, zari work, butta work, and appliqué motifs pay respect to the timeless beauty of Indian handicraft. Pranjali is committed to ethical fashion practises and works directly with local craftspeople to ensure fair salaries and long-term sustainability. Experience the fascination of her masterpieces as she mixes materials and ancient techniques to celebrate Indian textile creativity.
                    </Typography>
                    
                </Grid>

            </Grid>
        </Container>
  )
}

export default DesignerPage;