import React, { useEffect } from 'react'
import { Container, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
        <Typography textAlign='center' mt={4}><strong>Privacy Policy</strong></Typography>&nbsp;
        <Typography><strong>What we do with the information we gather</strong></Typography>
            <Typography mt={1}>We require this information primarily to provide better service by understanding your needs along with the following reasons:</Typography>
    
            <ul>
                <li>Internal record keeping</li>
                <li>Improvement of our products and services</li>
                <li>Marketing purposes to send periodically promotional emails about new products, special offers or other information of interest to you. We may contact you by email or phone for the same.</li>
            </ul>


            &nbsp;
            <Typography><strong>Security</strong></Typography>
            <Typography mt={1}>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure this information. </Typography>

            &nbsp;
            <Typography><strong>How we use cookies</strong></Typography>
            <Typography mt={1}>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</Typography>&nbsp;
            <Typography>We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We use this information for statistical analysis purposes and then data is removed from the system.</Typography>&nbsp;
            <Typography>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any other information about you, other than the data you choose to share with us.</Typography>&nbsp;
            <Typography>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</Typography>

            &nbsp;
            <Typography><strong>Controlling your personal information</strong></Typography>
            <Typography mt={1}>You may choose to restrict sharing or use of your personal information in the following ways:</Typography>
            <Typography mt={1}>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information from time-to-time about third parties which might be of interest to you, provided you express the same to us. You may request details of personal information about you that we hold under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held about you please send an email requesting this information to support@Beardo.in</Typography>

            &nbsp;
            <Typography><strong>Collection of Information and Use</strong></Typography>
            <Typography mt={1}>The Personal Information or Sensitive Personal Data or Information which You may provide to the Platform and/or which We may collect as part of the information provided is or could be the following:</Typography>
            <ul>
                <li>Your Registration Details Which May Include the Name, Email Address, Mobile/Contact Details, Age, Gender, Password, Location, Pin Code, Area Code, Occupation, Provided By You.</li>&nbsp;
                <li>Your Billing, Tax Registration, And Other Information Pertaining To Your Transactions On The Website Or Related To The Services.</li>&nbsp;
                <li>You May In Some Cases Share Your Debit Or Credit Card Details Or Other Bank Account Details While Accessing Any services Offered On The Site On A Subscription Model</li>&nbsp;
                <li>Your Comments or Other Content related to Surveys That You Publicly Post Or Share On The Website.</li>&nbsp;
                <li>If You Access or Engage With The Website Or Services Associated With The Website Through A Social Media Service, We May Collect Your User ID Or User Name, Any Information You Have Permitted The Social Media Service To Share With Us, And Any Information You Have Made Public With The Social Media Service.</li>&nbsp;
                <li>Your Usage Behavior of The Website Or Interests, Purchasing Behavior, Other Traceable Activities Online, On Apps And On Mobile Such As Destinations Visited, Viewed Advertisements Or Content Consumed.</li>&nbsp;
                <li>Details Of the Computer System Or Computer Network Which You Use To Visit The Website And Undertake Any Activity On The Website, Such As A Unique Device Identifier Including User Behavior From Web Based Cookies, Session Identifiers From Device ID, Commercially Available Services, Data Aggregator Platforms.</li>&nbsp;
                <li>User Preferences (E.G., Preferred Language Settings).</li>&nbsp;
                <li>IP Address, Operating System, Application Installation related Information, Browser Type, Browser Version, Browser Configuration, Name of Internet Service Provider, And Other Types of Computer And Connection Related Information Relevant To Identifying Your Type Of Device, Connecting To The Website, Enabling Data Exchange With You And Your Device, And Ensuring A Convenient Use Of The Website;</li>&nbsp;
                <li>The Uniform Resource Locator (URL) And IP Address of The Website From Which You Accessed, Or Were Directed To, Our Website, Including Date And Time.</li>&nbsp;
                <li>Subpages Visited While on Our Website, Links Followed On The Website, Including Date And Time.</li>&nbsp;
                <li>The Full URL Click Stream To, Through and From The Website, Including Date And Time;</li>&nbsp;
                <li>Service Requests and Orders Placed.</li>&nbsp;
                <li>Transaction History, Including Open and Completed Transactions.</li>&nbsp;
                <li>Search Terms Entered.</li>&nbsp;
                <li>Services/Products Viewed or Searched for On the Website.</li>&nbsp;
                <li>Shopping Cart and Payment Information.</li>&nbsp;
                <li>Credit Card Information.Information Collected by Cookies Or Similar Technologies (As Described In The Next Paragraph);
                    <ul style={{listStyleType:'circle'}}>&nbsp;
                       <li>Newsletter Subscriptions, Enrollment for Promotions, Use Of Special Offers, Etc.</li>&nbsp;
                       <li>Consents, Authorizations, Etc. Granted.</li>&nbsp;
                       <li>Survey Answers, Reviews, Ratings and Other Types of Feedback Provided.</li>&nbsp;
                       <li>Content Of Any Communication Sent Through the Website, Including Any Information Posted In Social Communities On The Website Or Otherwise Shared With Platform And/or Other Users, As Well As Chat Messages And Chat Transcripts;</li>&nbsp;
                       <li>Information On Software Downloaded from The Website; And</li>&nbsp;
                       <li>Any Other Information That You Enter On, Or Upload To, The Website (E.G., Content That You Fill into An Online Form, A Photo That You Upload).</li>
                    </ul>
                </li>
                
            </ul>
            <Typography mb={2}>If you believe that any information about you is incorrect or incomplete, please email us as soon as possible. We ensure a prompt action to correct the same.</Typography>
    </Container>
  )
}

export default PrivacyPolicy;