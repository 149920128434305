import { Box, Container } from '@mui/material'
import React from 'react'

function NetworkError() {
  return (
    <Container>
    <Box m={10} p={10} textAlign="center">
      <h3>Please check your internet connection!</h3>
    </Box>
  </Container>
  )
}

export default NetworkError