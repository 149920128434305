// import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const ProtectRoutes = ()=>{
    // const  isAuthenticated  = useSelector((state) => state.user.isAuthenticated);
    let login = JSON.parse(localStorage.getItem("isLogin"));
    // const login = JSON.parse(localStorage.getItem("userInfo")).role === "user" ? true : false;
    // JSON.parse(localStorage.getItem("userInfo"))?.login ? setLogin(JSON.parse(localStorage.getItem("userInfo"))?.login) :setLogin( false);
    // const isLogin = login === undefined ? true : false;
    // console.log("login", isAuthenticated);
   return(
    login ? <Outlet/> : <Navigate to='/login'/>
   )
}

export default ProtectRoutes;