import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {toast} from 'react-toastify';

const initialState = {
    isAuthenticated: '',
    userName: "",
    userEmail:"",
    shippingDetails: localStorage.getItem("ShippingInfo") ? 
    JSON.parse(localStorage.getItem("ShippingInfo")):
    {},
    isLoading: false,
    userInfo: null,
    
}

export const fetchUserInfoAsync = createAsyncThunk(
    "fetchUserInfoAsync",
    async(email)=>{
            const response = await axios.post("https://swarnsutra.com/api/userProfile", {email})
            return response?.data;
    }
)

export const addUserAddressAsync = createAsyncThunk(
    "addUserAddressAsync",
    async(address)=>{
            const response = await axios.post("https://swarnsutra.com/api/userAddAddress", address)
            return response?.data;
    }
)

export const updateUserAddressAsync = createAsyncThunk(
    "updateUserAddressAsync",
    async(updatedAddress)=>{
            const response = await axios.put("https://swarnsutra.com/api/userAddressUpdate", updatedAddress)
            return response?.data;
    }
)

export const updateUserProfileAsync = createAsyncThunk(
    "updateUserProfileAsync",
    async(profile)=>{
            const response = await axios.put("https://swarnsutra.com/api/profile", profile)
            return response?.data;
    }
)

const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{
        userLogedIn:(state, action)=>{
            localStorage.setItem("userInfo", JSON.stringify(action.payload));

            if(localStorage.getItem("userInfo")){
                state.isAuthenticated = JSON.parse(localStorage.getItem("userInfo")).token;
                state.userName = JSON.parse(localStorage.getItem("userInfo")).name;
                state.userEmail = JSON.parse(localStorage.getItem("userInfo")).email;
                localStorage.setItem("isLogin", true);
            }
        },
        userLogOut:(state, action)=>{
            state.userName = "";
            state.userEmail = "";
            state.isAuthenticated = '';
            localStorage.setItem("isLogin", false);
        },
        addShippingDetails:(state, action)=>{
            state.shippingDetails = action.payload;
        },
       

    },
    extraReducers: (builder)=>{
        builder.addCase(fetchUserInfoAsync.pending, (state, action)=>{
            state.isLoading = true;
        });
        builder.addCase(fetchUserInfoAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.userInfo = action.payload.userinfo;
            // console.log(action.payload);
        })
        builder.addCase(addUserAddressAsync.pending, (state, action)=>{
            state.isLoading = true;
        });
        builder.addCase(addUserAddressAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            // state.userInfo = action.payload.userinfo;
            console.log(action.payload);
        })
        builder.addCase(updateUserAddressAsync.pending, (state, action)=>{
            state.isLoading = true;
        });
        builder.addCase(updateUserAddressAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            // state.userInfo = action.payload.userinfo;
            console.log(action.payload);
        })
        builder.addCase(updateUserProfileAsync.pending, (state, action)=>{
            state.isLoading = true;
        });
        builder.addCase(updateUserProfileAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            console.log(action.payload);
        });
    }
});

export default userSlice.reducer;

export const selectUserInfo = (state)=> state.user.userInfo;
export const selectIsLoading = (state)=> state.user.isLoading;

export const {userLogedIn, userLogOut,addShippingDetails} = userSlice.actions;