import { Box, Button, Card, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { addToCartAsync, selectCartItems, updateCartItemAsync } from '../store/slices/CartSlice';
import { FormatPrice } from '../utils/FormatPrice';
import { toast } from 'react-toastify';

const Item = ({ item }) => {
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(false);
    const cartItems = useSelector(selectCartItems);
    const { _id, title, price, category, images, quantity } = item;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const handleCart = () => {
        const itemIndex = cartItems.findIndex((item) => item.product._id === _id);
        if (itemIndex >= 0) {

            if (cartItems[itemIndex].cartQuantity >= quantity) {
                toast.warning("max quantity reached");
            }
            else {
                var newQuantity = cartItems[itemIndex].cartQuantity;
                newQuantity += 1;
                dispatch(updateCartItemAsync({ id: cartItems[itemIndex]._id, quantity: newQuantity }));
                toast.success(`${title} quantity increased`);
            }
        }
        else {
            dispatch(addToCartAsync({ user: userInfo.userId, product: _id, cartQuantity: 1 }));
            toast.success(`${title} added to cart`);
        }
    }

    return (
        <Grid key={_id} item xs={6} sm={4} md={3} mb={3} >
            <Card
                sx={{ position: 'relative', boxShadow: 'none', borderRadius: '6px', width:'fit-content' }}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
            >
                <Box >
                    <Link to={`/singleproduct/${_id}`}>
                        <Box component='img' src={images[0]} sx={{ width: {xs:'100%', lg:'17rem'}, height: {xs:'18rem', sm:'22rem', md:'24rem'}, objectFit: 'cover' , objectPosition:'center'}} />
                    </Link>
                </Box>
                <Box
                    display={isHovered ? "block" : "none"}
                    position="absolute"
                    bottom="0"
                    left="0"
                    width="100%"
                >
                    {
                        quantity == 0 ? (
                            <Button sx={{
                                borderRadius: '0px',
                                backgroundColor: '#666666', color: "black", '&:hover':
                                {
                                    backgroundColor: '#f68872'
                                },
                            }} fullWidth>Not Available</Button>
                        )
                            :
                            (
                                <Button
                                    onClick={() => handleCart()}
                                    sx={{
                                        borderRadius: '0px',
                                        backgroundColor: '#666666', color: "white", '&:hover':
                                        {
                                            backgroundColor: '#f68872'
                                        }
                                    }}
                                    fullWidth
                                >
                                    Add to Cart
                                </Button>
                            )
                    }


                </Box>

            </Card>

            <Box pt={3}>
                <Typography variant="subtitle2" textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue'>
                    {category
                    }
                </Typography>
                <Link to={`/singleproduct/${_id}`}>
                    <Typography  textTransform='uppercase' fontWeight={600} color='#222222' fontFamily='Apple Symbols , Open Sans Helvetica Neue'>
                        {title}
                    </Typography>
                </Link>
                <Typography variant="subtitle2" noWrap color='#999' mt={1} fontWeight="bold">
                    {<FormatPrice price={price} />}
                </Typography>
            </Box>
        </Grid>
    )
}

export default Item;
