import { Box, TextField,Typography, Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { deleteCategory, deleteMainCategory } from '../store/slices/categorySlice';


const DeleteCategory = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();
   const [value, setValue] = useState('false');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Box sx={{
      margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 60px 280px' },
      padding: '20px 45px',
      backgroundColor: 'rgb(248,250, 255)',
    }}
      component="form"
      onSubmit={handleSubmit((data) => {
        if(value === 'false'){
            dispatch(deleteCategory(data));
        }
        else{ 
            dispatch(deleteMainCategory(data));
        }
        reset();
      })}
    >
      <Box mb={2} py={2} bgcolor='dodgerblue' display='flex' alignItems='center' sx={{width: '100%'}}>
        <Typography color='white' pl={2}>Please fill below details to add the category.</Typography>
      </Box>

      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">Would you like to delete Main Category ?</FormLabel>
        <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value }
            onChange={handleChange}
        >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
        </RadioGroup>
       </FormControl>

      <Box my={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Main Category"
          {
          ...register('mainCategory', {
            required: 'Main Category is Required'
          })
          }
          error={Boolean(errors.mainCategory)}
          helperText={errors.mainCategory?.message}
        />
      </Box>
      {
         value === 'false' && <Box my={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Sub Category"
          {
          ...register('category', {
            required: 'Sub Category is Required'
          })
          }
          error={Boolean(errors.category)}
          helperText={errors.category?.message}
        />
      </Box>
      }


      <Button variant='contained' type='submit'>Delete</Button>
    </Box>
  );
}

export default DeleteCategory;