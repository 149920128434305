import { Box, TextField,Typography, Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addCategory } from '../store/slices/categorySlice';


const NewCategory = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const dispatch = useDispatch();

  return (
    <Box sx={{
      margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 60px 280px' },
      padding: '20px 45px',
      backgroundColor: 'rgb(248,250, 255)',
    }}
      component="form"
      onSubmit={handleSubmit((data) => {
        dispatch(addCategory(data));
        reset();
      })}
    >
      <Box mb={2} py={2} bgcolor='dodgerblue' display='flex' alignItems='center' sx={{width: '100%'}}>
        <Typography color='white' pl={2}>Please fill below details to add the category.</Typography>
      </Box>

      <Box my={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Main Category"
          {
          ...register('mainCategory', {
            required: 'Main Category is Required'
          })
          }
          error={Boolean(errors.mainCategory)}
          helperText={errors.mainCategory?.message}
        />
      </Box>
      <Box my={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Sub Category"
          {
          ...register('category', {
            required: 'Sub Category is Required'
          })
          }
          error={Boolean(errors.category)}
          helperText={errors.category?.message}
        />
      </Box>


      <Button variant='contained' type='submit'>Add</Button>
    </Box>
  );
}

export default NewCategory;