import { Box } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';



const NavLinks = ({ setOpen }) => {
    const { category } = useSelector(state=>state.category);
    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");
    

    const links = [
        {
            name: "Home",
            link: '/',
            submenu: false,
        },
        {
            name: "Products",
            submenu: true,
            sublinks: category // here category will from api
        },
        {
            name: "Blog",
            link: '/blog',
            submenu: false,
        },
        {
            name: "About",
            link: '/about',
            submenu: false,
        }
    ];

    return (
        <>
            {
                links.map((link) => (
                    <Box key={link.name}>
                        <Box className="menu-item">
                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <Link className="link" to={link.link} onClick={() => {
                                    if (heading !== link.name) {
                                        setHeading(link.name);
                                        if (link.name !== "Products") {
                                            setOpen(false);
                                        }
                                    }
                                    else {
                                        setHeading("");
                                        setSubHeading("");
                                    }
                                }}>
                                    {link.name}
                                </Link>

                            </Box>

                            {link.submenu && (
                                <Box>
                                    <Box className="absolute">
                                        <Box style={{ paddingTop: '0.75rem', paddingBottom: '0.75rem' }}>
                                            <Box style={{ position: 'absolute', width: '1rem', height: '1rem', marginTop: '0.25rem', left: '0.75rem', rotate: '45deg', backgroundColor: '#d6d6d6' }}>
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            overflowY: 'auto', maxHeight: '32rem', '&::-webkit-scrollbar': {
                                                width: '8px'
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                backgroundColor:'#fff',
                                                borderRadius:'8px'
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,0.6)',
                                                borderRadius:'8px'
                                            }
                                        }}>
                                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', padding: '1.25rem', backgroundColor: '#d6d6d6' }}>
                                                {
                                                    link.sublinks.map((mysublinks) => (
                                                        <div key={mysublinks.mainCategory}>
                                                            <h1 className="text-lg font-semibold">{mysublinks.mainCategory}</h1>
                                                            {mysublinks.category.map((subCategory) => (
                                                                <li className="text-sm text-gray-600 my-2" key={subCategory}>
                                                                    <Link className="text-primary" to={`products/${subCategory}`}>{subCategory}</Link>
                                                                </li>
                                                            ))}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                        </Box>


                        {/* mobile list on clicking */}
                        <Box className={`${heading === link.name ? "display" : "hidden"}`}>
                            {/* sublinks */}
                            {link.submenu && link.sublinks.map((slinks) => (
                                <Box key={slinks.mainCategory}>

                                    <h1
                                        onClick={() =>
                                            subHeading !== slinks.mainCategory
                                                ? setSubHeading(slinks.mainCategory)
                                                : setSubHeading("")
                                        }
                                        className="head"
                                    >
                                        {slinks.mainCategory}


                                        {/* {subHeading === slinks.Head ? <Box component='span' sx={{display:{xs:'inline', md:'none'}}} className="span1">
                                        </Box> : <Box component='span' sx={{display:{xs:'inline', md:'none'}}} className="span1"></Box>} */}


                                    </h1>
                                    <Box className={`${subHeading === slinks.mainCategory ? "display" : "hidden"}`}>
                                        {slinks.category.map((subCategory) => (
                                            <li className="sublink" key={subCategory}>
                                                <Link to={`products/${subCategory}`} onClick={() => setOpen(false)}>{subCategory}</Link>
                                            </li>
                                        ))}
                                    </Box>

                                </Box>
                            ))}

                        </Box>
                    </Box>
                ))
            }
        </>
    )
}

export default NavLinks;