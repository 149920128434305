import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Card, Container, InputAdornment, OutlinedInput, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../Pages/Loading';
import { useDispatch } from "react-redux";
import { userLogOut } from "../store/slices/UserSlice";

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'address', label: 'Address', alignRight: false },
    { id: 'verified', label: 'Verified', alignRight: false },
    { id: 'mob', label: 'Mob. No.', alignRight: false },
];


const UserPage = () => {
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const location = useLocation();
    const [loading,setLoading]=useState(false);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterName, setFilterName] = useState('');
    var userInfo=JSON.parse(localStorage.getItem("userInfo"));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };
    const handleClick = (event, name) => {
        // console.log(event);
    }

    const getUsers= async()=>{
        setLoading(true);
        try{
            const resp = await axios('https://swarnsutra.com/api/getAdminUser');
            if(resp.data.status==505){
                setLoading(false);
                dispatch(userLogOut());
                localStorage.removeItem("userInfo")
                toast.error(`${resp.data.message}`);
                navigate('/login');
              }else if(resp.data){
                setUsers(resp.data);
                setLoading(false);
               }else{
                setLoading(false);
                toast.error(`Something went wrong!`);
               }
        }catch(error){
            setLoading(false);
            toast.error(`Check your internet connection!`);
        }
        
    }
  
    useEffect(()=>{
        getUsers();
    }, [location.pathname]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

    if(loading){
        return(
            <Loading/>
        )
    }

    return (
        <Box sx={{ margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 0 280px' }, padding: '30px 15px', backgroundColor: 'rgb(248,250, 255)' }}>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom fontWeight={700} sx={{ fontSize: { xs: '1.25rem', sm: '1.25rem', md: '1.5rem' } }}>
                        User
                    </Typography>
                    <Button variant="contained" startIcon={<>+</>}>
                        New User
                    </Button>
                </Stack>

                <Card sx={{ borderRadius: '16px' }} >
                    <Toolbar sx={{ height: '96px' }}>
                        <OutlinedInput
                            value={filterName}
                            placeholder="Search user..."
                            sx={{
                                width: '240px',
                                transition: 'box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                '&.Mui-focused': {
                                    width: 320,
                                    boxShadow: 'rgba(145,158,171,0.16) 0px 8px 16px 0px',
                                },
                                '& fieldset': {
                                    borderWidth: `1px !important`,
                                    borderColor: `rgba(145,158,171,0.32) !important`,
                                },

                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            }

                            onChange={(e)=>setFilterName(e.target.value)}
                        />
                    </Toolbar>

                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >

                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map((headCell) => (
                                        <TableCell
                                            sx={{ backgroundColor: "rgb(244, 246, 248)", color: 'rgb(99, 115, 129)', fontWeight: 600 }}
                                            key={headCell.id}
                                            align={headCell.alignRight ? 'right' : 'left'}

                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { _id, name, email, address, phone, verify } = row;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.name)}
                                            role="checkbox"
                                            //aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={_id}
                                            //selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >

                                            <TableCell component="th" scope="row" >
                                                <Typography variant="subtitle2" noWrap fontWeight={600}>
                                                    {name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">{email}</TableCell>
                                            <TableCell align="left">{address.length > 0 ? 'Yes' : "Address is not added"}</TableCell>
                                            <TableCell align="left">{verify ? 'Yes' : 'No'}</TableCell>
                                            <TableCell align="left">{phone}</TableCell>
                                        </TableRow>
                                    );
                                })
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Box>
    )
}


export default UserPage;