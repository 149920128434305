import React, { useEffect } from 'react'
import { Container, Typography } from '@mui/material';
import { Link, useLocation} from 'react-router-dom';

const TermsCondition = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
    return (
        <Container>
            <Typography textAlign='center' mt={4}><strong>Swarn Sutra Terms & Conditions</strong></Typography>
            <Typography mt={4}>Access to and use of <Link to='http://swarnsutra.com/' style={{ color: "#333", textDecoration: 'none' }}>www.swarnsutra.com</Link> in and the products and service available through the website are subject to the following terms, conditions and notices {"(“"}<strong>Terms of Service</strong>{"”)"}. By browsing through these Terms of Service and using the services provided by our <Link to='http://swarnsutra.com/' style={{ color: "#333", textDecoration: 'none' }}>www.swarnsutra.com</Link> you agree to all Terms of Service along with the Privacy Policy on our website, which may be updated by us from time to time. Please check this page regularly to take notice of any changes we may have made to the Terms of Service.</Typography>
            <p>&nbsp;</p>
            <Typography><strong>Introduction</strong></Typography>
            <Typography mt={1}>The domain name www.swarnsutra.com is a site operated by PC enterprise’s., a company incorporated under laws of India with our registered office at  swarn sutra PC Enterprises, Sec- 28, H NO. 458, Gautam Buddha Nagar, Noida, Uttar Pradesh, 201301. </Typography>

            &nbsp;
            <Typography><strong>Services</strong></Typography>
            <Typography mt={1}>Swarnsutra.com is an online retailer of apparel and lifestyle products offered at great values to the consumer. Membership allows customers to purchase a variety of products. Upon placing an order, www.swarnsutra.com shall ship the product to you and be entitle to its payment for the service. </Typography>

            &nbsp;
            <Typography><strong>Third Party Websites and Content</strong></Typography>
            <Typography mt={1}>Our website provides links for sharing our content on Facebook, twitter and other such third party website. These are only for sharing and/or listing purpose and we take no responsibility of the third party websites and/or their contents listed on our website (www.swarnsutra.com ) and disclaim all our liabilities arising out of any or all third party websites. </Typography>&nbsp;
            <Typography>We disclaim all liabilities and take no responsibility for the content that may be posted on such third party websites by the users of such websites in their personal capacity on any of the above mentioned links for sharing and/or listing purposes as well as any content and/or comments that may be posted by such user in their personal capacity on any official webpage of swarnsutra on any social networking platform.</Typography>

            &nbsp;
            <Typography><strong>Privacy</strong></Typography>
            <Typography mt={1}>Our <strong>Privacy Policy</strong> incorporated by reference in these Terms of Service, sets out how we will use personal information you provide to us. By using this Website, you agree to be bound by the Privacy Policy, and warrant that all data provided by you is accurate and up to date. </Typography>

            &nbsp;
            <Typography><strong>Exactness of the Product</strong></Typography>
            <Typography mt={1}>The images of the items on the website are for illustrative purposes only. The actual colour combination of the product slightly vary as per the customer’s respective screen resolution.</Typography>

            &nbsp;
            <Typography><strong>Customization</strong></Typography>
            <Typography mt={1}>1. In case of  product is faulty or damaged from our end then the customer can proceed the return request.</Typography>
            <Typography>2. The colour of the product  may slightly vary as per the customer's screen resolution & camera and light effects also.</Typography>

            &nbsp;
            <Typography><strong>Pricing</strong></Typography>
            <Typography mt={1}>We ensure that all details of prices appearing on the website are accurate, however errors may occur. If we discover an error in the price of any goods which you have ordered, we will inform you of this as soon as possible. If we are unable to contact you we will treat the order as cancelled. If you cancel and you have already paid for the goods, after we reviewing the product quality, you will receive a full refund withing time period 8-10 working days</Typography>&nbsp;
            <Typography>Additionally, prices for items may change from time to time without notice. However, these changes will not affect orders that have already been dispatched. The price of an item includes GST (or similar sales tax) at the prevailing rate for which we are responsible as a seller. Please note that the prices listed on the website are only applicable for items purchased on the website and not through any other source.
            </Typography>


            &nbsp;
            <Typography><strong>Payment</strong></Typography>
            <Typography mt={1}> Upon receiving your order we carry out a standard pre-authorization check on your payment card to ensure there are sufficient funds to fulfill the transaction. Goods will not be dispatched until this pre-authorization check has been completed. Your card will be debited once the order has been accepted. For any further payment related queries, please check our <b>FAQs </b>on Payment Mode.</Typography>

            &nbsp;
            <Typography><strong>Delivery</strong></Typography>
            <Typography mt={1}> You will be given various options for delivery of items during the order process. The options available to you will vary depending on where you are ordering from. An estimated delivery time is displayed on the order summary page. On placing your order, you will receive an email containing a summary of the order and also the estimated delivery time to your location. Sometimes, delivery may take longer due to unforeseen circumstances. In such cases, we will proactively reach out to you by e-mail and SMS. However, we will not be able to compensate for any mental agony caused due to delay in delivery.</Typography>

            &nbsp;
            <Typography><strong>Returns & Refund</strong></Typography>
            <Typography mt={1}> Please note that the single products/combo products are not subject to return once the product is delivered. However, in case the product has an issue or defect in the material or print, you can submit the return request. For more visit Return Policy. </Typography>

            &nbsp;
            <Typography><strong>Intellectual Property Rights</strong></Typography>
            <Typography mt={1}>All and any intellectual property rights in connection with the products shall be owned absolutely by the Company.</Typography>

            &nbsp;
            <Typography><strong>Law and Jurisdiction</strong></Typography>
            <Typography mt={1}>These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the courts at Udaipur.</Typography>

            &nbsp;
            <Typography><strong>Indemnification</strong></Typography>
            <Typography mt={1}>You agree to indemnify, defend and hold harmless the Company, its directors, officers, employees, consultants, agents, and affiliates, from any and all third party claims, liability, damages or costs arising from your use of this website, your breach of these Terms of Service, or infringement of any intellectual property right.</Typography>

            &nbsp;
            <Typography><strong>Violation & Termination</strong></Typography>
            <Typography mt={1} mb={2}>You agree that the Company may, in its sole discretion and without prior notice, terminate your access to the website and block your future access if we determine that you have violated these Terms of Service or any other policies. If you or the Company terminates your use of any service, you shall still be liable to pay for any service that you have already ordered till the time of such termination.</Typography>
            
        </Container>
    )
}

export default TermsCondition;