
import { Outlet, Navigate } from "react-router-dom";

const AdminProtectedRoutes = ()=>{
    const login = JSON.parse(localStorage.getItem("userInfo"))?.role === "admin" ? true : false;

   return(
    login ? <Outlet/> : <Navigate to='/login'/>
   )
}

export default AdminProtectedRoutes;