import React, { Fragment } from "react";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const CheckoutSteps=({activeStep})=>{
    const steps = [
        {
            label:<Typography>Shipping Details</Typography>,
            icon: <LocalShippingIcon/>,
        },
        {
            label:<Typography>Confirm Order</Typography>,
            icon: <LibraryAddCheckIcon/>,
        },
        {
            label:<Typography>Payment</Typography>,
            icon: <AccountBalanceIcon/>,
        }
    ]
    return(
       <Fragment>
          <Stepper alternativeLabel activeStep={activeStep} style={{boxSizing:'border-box'}}>
             {
                steps.map((step, index)=>(
                    <Step key={index} active={activeStep === index ? true : false} completed={activeStep >= index ? true : false}>
                        <StepLabel icon={step.icon} style={{color: activeStep >= index ? 'tomato' : 'rgba(0,0,0,0.649)'}}>
                            {step.label}
                        </StepLabel>
                    </Step>
                ))
             }
          </Stepper>
       </Fragment>
    )
}

export default CheckoutSteps;