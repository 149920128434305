import React, { useState } from 'react'
import { Drawer, Button, Radio, Slider, Box, FormControl, FormLabel, FormControlLabel, RadioGroup, Container } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import { sortByCategory, sortByRange, sortByPrice } from '../store/slices/FilterSlice';

const FilterDrawerComp = () => {
    const [openFilterDrawer, setFilterOpenDrawer] = useState(false);
    const [openSortDrawer, setSortOpenDrawer] = useState(false);
    const dispatch = useDispatch();
    const { category } = useSelector((state) => state.category);
    const { maxPrice, minPrice } = useSelector((state) => state.filters);

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(sortByCategory({ name, value }));
    }
    const filterByRange = (event) => {
        const value = event.target.value;
        dispatch(sortByRange(value));
    }
    const filterByPrice = (event) => {
        const value = event.target.value;
        dispatch(sortByPrice(value));
    }

    return (
        <Container>
            <Drawer open={openFilterDrawer} anchor={'left'} 
            sx={{
                '& .MuiDrawer-paper': {
                    '&::-webkit-scrollbar': {
                        width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#c9f0f0',
                        borderRadius: '8px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#88c7c7',
                        borderRadius: '8px'
                    }
                }
            }}
            onClose={() => setFilterOpenDrawer(false)}>
                {
                    category.map((category) => (
                        <Box p={2} width={250} key={category.mainCategory}>
                            <FormControl>
                                <FormLabel>{category.mainCategory}</FormLabel>
                                <RadioGroup name="category">
                                    {
                                        category.category.map((subCategory, index) => (
                                            <FormControlLabel key={index} value={subCategory} control={<Radio />} label={subCategory} onChange={(e) => handleChange(e)} />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    ))
                }

                <Box sx={{ width: 220 }} p={2} mt={2}>
                    <FormLabel >Sort By Range</FormLabel>
                    <Slider
                        aria-label="Restricted values"
                        defaultValue={1000}
                        min={minPrice}
                        max={maxPrice}
                        valueLabelDisplay="auto"
                        onChange={(e) => filterByRange(e)}
                    />
                </Box>

            </Drawer>

            <Drawer open={openSortDrawer} anchor={'bottom'} onClose={() => setSortOpenDrawer(false)}>
                <Box pl={2} mt={2}>
                    <FormControl>
                        <FormLabel >Sort By Price</FormLabel>
                        <RadioGroup
                            defaultValue="null"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="LTH" control={<Radio />} label="Low to High" onChange={(e) => filterByPrice(e)} />
                            <FormControlLabel value="HTL" control={<Radio />} label="High to Low" onChange={(e) => filterByPrice(e)} />
                        </RadioGroup>
                    </FormControl>
                </Box>

            </Drawer>


            <Box display='flex' flexWrap='wrap' justifyContent='space-between' padding='1rem 0rem' width='100%'>
                <Button variant="contained" startIcon={<SortIcon />} onClick={() => setSortOpenDrawer(!openSortDrawer)}>Sort</Button>
                <Button variant="contained" startIcon={<FilterListIcon />} onClick={() => setFilterOpenDrawer(!openFilterDrawer)}>Filter</Button>
            </Box>

        </Container>
    )
}

export default FilterDrawerComp;
