import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Container, InputAdornment, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useLocation } from 'react-router-dom';
import { FormatPrice } from '../utils/FormatPrice';
import { toast } from 'react-toastify';
import Loading from '../Pages/Loading';
import axios from 'axios';
import Action from './Action';
import ViewOrders from './ViewOrders';


const TABLE_HEAD = [
    { id: 'ordId', label: 'Order Id', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    { id: 'payment', label: 'Payment', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'actions', label: 'Update Status', alignRight: false }, // for action
    { id: 'view', label: 'View Order', alignRight: false },
    { id: 'bill', label: 'Print Bill', alignRight: false },
];

const AdminOrders = () => {
    

    const location = useLocation();
    const [orders, setOrders] = useState([]);
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [filterName, setFilterName] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const getOrders = async () => {
        setLoading(true)
        try {
            const resp = await axios('https://swarnsutra.com/api/getAdminOrder');
            if (resp.data) {
                setOrders(resp.data);
                setLoading(false)
            } else {
                setLoading(false)
                toast.error(`Something went wrong!`);
            }
        } catch (error) {
            setLoading(false)
            toast.info(`Check your internet connection!`);
        }

    }

    const handleBill=async (orderId)=>{
        setLoading(true);
        try {
            const resp = await axios.post('https://swarnsutra.com/api/getInvoice', { orderId: orderId });
            if(resp.data.status==200) {
                setLoading(false);
                window.location.href=resp.data.URL;
            }
            else{
                setLoading(false);
                return toast.error('Some server issue try later!');
            }
            
        } catch (error) {
            setLoading(false);
            toast.error('Something went wrong!')
        }
    }

    useEffect(() => {
        getOrders();
    }, [location.pathname]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;
    // const isNotFound = !orders.length;

    if (loading) {
        return (
            <Loading />
        )
    }
    return (
        <Box sx={{ margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 0 280px' }, padding: '30px 15px', backgroundColor: 'rgb(248,250, 255)' }}>
            <Container>

                <Typography variant="h4" gutterBottom fontWeight={700} mb={5} sx={{ fontSize: { xs: '1.25rem', sm: '1.25rem', md: '1.5rem' } }}>
                    Order
                </Typography>

                <Card sx={{ borderRadius: '16px' }} >
                    <Toolbar sx={{ height: '96px' }}>
                        <OutlinedInput
                            value=''
                            placeholder="Search order..."
                            sx={{
                                width: '240px',
                                transition: 'box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                '&.Mui-focused': {
                                    width: 320,
                                    boxShadow: 'rgba(145,158,171,0.16) 0px 8px 16px 0px',
                                },
                                '& fieldset': {
                                    borderWidth: `1px !important`,
                                    borderColor: `rgba(145,158,171,0.32) !important`,
                                },

                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                </InputAdornment>
                            }
                        />
                    </Toolbar>

                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'
                        >

                            <TableHead>
                                <TableRow>
                                    {TABLE_HEAD.map((headCell) => (

                                        <TableCell
                                            sx={{ backgroundColor: "rgb(244, 246, 248)", color: 'rgb(99, 115, 129)', fontWeight: 600 }}
                                            key={headCell.id}
                                            align={headCell.alignRight ? 'right' : 'left'}

                                        >
                                            {headCell.label}
                                        </TableCell>


                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => {
                                    const { _id, address, totalAmount, discount, payment_method, status } = order;
                                    return (
                                        <TableRow key={_id} >

                                            <TableCell component="th" scope="row" >
                                                <Typography variant="subtitle2" noWrap fontWeight={600}>
                                                    {_id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">{address.name}</TableCell>
                                            <TableCell align="left">{<FormatPrice price={totalAmount-discount} />}</TableCell>
                                            <TableCell align="left">{payment_method}</TableCell>
                                            <TableCell align="left">
                                                {
                                                status === "Order received" ? <Box bgcolor='rgba(253, 181, 40, 0.12)' color='rgb(253, 181, 40)' padding='3px 5px' borderRadius='3px' fontSize='14px' textAlign='center'>Processing</Box>
                                                : status === "Dispatched" ? <Box bgcolor='rgba(38,198,249,0.12)' color='rgb(38,198,249)' padding='3px 5px' borderRadius='3px' fontSize='14px' textAlign='center'>Dispatched</Box>
                                                : status === "Delivered" ? <Box bgcolor='rgba(102,108,255,0.12)' color='rgb(102,108,255)' padding='3px 5px' borderRadius='3px' fontSize='14px' textAlign='center'>Delivered</Box>
                                                : status === "Shipped" ? <Box bgcolor='rgba(253, 0, 180, 0.12)' color='rgb(253, 0, 180)' padding='3px 5px' borderRadius='3px' fontSize='14px' textAlign='center'>Shipped</Box>
                                                : status === "Cancelled" ? <Box bgcolor='rgba(253, 0, 180, 0.12)' color='rgb(253, 0, 180)' padding='3px 5px' borderRadius='3px' fontSize='14px' textAlign='center'>Cancelled</Box>
                                                : <Box bgcolor='rgba(253, 0, 180, 0.12)' color='rgb(253, 0, 180)' padding='3px 5px' borderRadius='3px' fontSize='14px' textAlign='center'>Error</Box>
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                               <Action id={_id}/>
                                            </TableCell>
                                            <TableCell align="right">
                                               <ViewOrders order={order}/>
                                            </TableCell>
                                            <TableCell align="right">
                                               <Button variant='outlined' onClick={()=>handleBill(_id)}>Print</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {/* {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Not found
                                                </Typography>

                                                <Typography variant="body2">
                                                    No results found for &nbsp;
                                                    <strong>&quot;{filterName}&quot;</strong>.
                                                    <br /> Try checking for typos or using complete words.
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )} */}
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={orders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Box>
    )
}

export default AdminOrders;