import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import { FormatPrice } from '../utils/FormatPrice';


const ViewOrders = ({ order }) => {
     console.log(order);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
                View
            </Button>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='md'
            >
                <DialogTitle>Order Id: {order._id}</DialogTitle>
                <DialogContent>
                    <Box color='rgba(0, 0, 0, 0.6)'>
                        <Typography>Delivery Address: </Typography>
                        <Typography>{order.address.name}</Typography>
                        <Typography>{order.address.street}</Typography>
                        <Typography>{order.address.city}, {order.address.state}-{order.address.pincode}, {order.address.country}</Typography>
                        <Typography>Mobile No: {order.address.phone}</Typography>
                    </Box>
                    <Grid container mt={4}>
                        <Grid item xs={3}>
                            <Typography fontWeight='bold'>Image</Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Typography fontWeight='bold'>Title</Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Typography fontWeight='bold'>Price</Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Typography fontWeight='bold'>Product Code</Typography>
                        </Grid>
                    </Grid>
                    {
                        order.productName.map((item) => {
                            return (
                                <Grid container mt={4} key={item._id}>
                                    <Grid item xs={3}>
                                        <Box component='img' src={item.product.images[0]} alt={item.product.title} sx={{width:'6rem', height:'8rem'}} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>{item.product.title}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography><FormatPrice price={item.product.price}/></Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>{item.product.productCode}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Typography mt={2} textAlign='center' fontWeight='bold'>Total Amount: <FormatPrice price={order.totalAmount}/></Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ViewOrders;