import blog1 from './images/blog1.webp'
import blog3 from './images/blog3.jpg'
import blog4 from './images/blog4.jpg'


const content = [
    {
        "id":1,
        "image":blog1,
        "title":"How fashion affect our daily life",
        "date":"19 August, 2023",
        "desc":" Fashion is a part of our daily life that we often take for granted. It affects not only how we present ourselves to the world but also how we feel about ourselves.",
         "imageText": 'main image description',
         "linkText": 'Continue reading…',
         "fulldesc":"Fashion is a part of our daily life that we often take for granted. It affects not only how we present ourselves to the world but also how we feel about ourselves. From the clothes we wear to the accessories we choose, fashion has a significant impact on our daily lives. Here are some ways in which fashion affects us:",
         "desc2":"Self-expression: Fashion allows us to express our individuality and personality through the clothes we wear. Our fashion choices reflect our interests, moods, and even our values. The way we dress can communicate our social status, our profession, and even our aspirations.",
         "desc3":"Confidence: When we feel good in what we wear, we are more confident and self-assured. Fashion can give us a sense of empowerment and help us feel more in control of our lives.",
         "desc4":"Social interaction: Our fashion choices can also affect how we interact with others. Dressing appropriately for different occasions can help us fit in with different social groups, while standing out from the crowd can help us make a statement and get noticed.",
         "desc5":"Creativity: Fashion allows us to be creative and experiment with different styles and trends. It can inspire us to try new things and push our boundaries.",
         "desc6":"Sustainability: Fashion also has a significant impact on the environment. As consumers, our fashion choices can contribute to the fast fashion industry, which has negative impacts on the environment. Choosing sustainable fashion options can help reduce the carbon footprint of the fashion industry and contribute to a more eco-friendly lifestyle.In conclusion, fashion is an integral part of our daily lives that affects us in various ways. From self-expression to confidence, social interaction to creativity, fashion has a profound impact on how we perceive ourselves and interact with the world around us. By being mindful of our fashion choices, we can make a positive impact on our lives and the environment."
    },
    
    {
        "id":2,
        "image":blog3,
        "title":"How Well Do You Know Indian Handlooms",
        "date":"20 August, 2023",
        "desc":" Indian handlooms are renowned worldwide for their unique designs, patterns, and weaving techniques. The artisans across the country have been perfecting.",
         "imageText": 'main image description',
         "linkText": 'Continue reading…',
         "fulldesc":"Indian handlooms are renowned worldwide for their unique designs, patterns, and weaving techniques. The artisans across the country have been perfecting their skills for generations, and the result is a diverse range of handloom fabrics that are unmatched in beauty and quality.The different regions of India have their distinct handloom styles that reflect the local culture and traditions.",
         "desc2":"For instance, the Banarasi silk from Varanasi is famous for its intricate zari work, while Chanderi handloom from Madhya Pradesh is known for its lightweight and sheer texture.",
         "desc3":"Similarly, Maheshwari handloom from Madhya Pradesh is known for its reversible designs and Paithani handloom from Maharashtra is known for its intricate motifs. Each handloom style has its unique characteristics and tells a story of the region it comes from.",
         "desc4":"The weavers use various techniques like pit loom, frame loom, and shuttle loom to create these fabrics. The yarn used in handlooms is made from natural fibers like cotton, silk, wool, and jute, making them eco-friendly and sustainable.One of the most remarkable things about Indian handlooms is the diversity of designs and patterns. The weavers use various techniques like Jamdani, Bandhani, and Tie and Dye to create unique designs and motifs.",
         "desc5":"The Jamdani technique from West Bengal involves weaving intricate designs on the fabric, while Bandhani from Rajasthan involves creating dots on the fabric using a tie and dye technique.Indian handlooms have not only stood the test of time but have also evolved with changing times. Today, many weavers are incorporating modern designs and colors into their handloom fabrics to cater to the changing tastes of consumers.",
         "desc6":" The Indian government has taken various initiatives to promote and support the handloom industry. The National Handloom Development Corporation (NHDC) provides financial assistance to weavers, while various exhibitions and fairs are organized to showcase the work of the artisans. The government has also implemented policies to protect the rights of weavers and ensure fair wages."
    },
    {
        "id":3,
        "image":blog4,
        "title":"Traditional Weaving in our socity",
        "date":"21 August, 2023",
        "desc":" Weaving is an ancient tradition that has been an integral part of human culture for thousands of years. In many societies, weaving is not just a craft.",
         "imageText": 'main image description',
         "linkText": 'Continue reading…',
         "fulldesc":"Weaving is an ancient tradition that has been an integral part of human culture for thousands of years. In many societies, weaving is not just a craft but a deeply rooted tradition that reflects the cultural identity of the people. Traditional weaving has played a significant role in shaping the history and cultural heritage of many communities around the world. Here are some reasons why traditional weaving culture is essential in our society:",
         "desc2":"Preservation of cultural identity: Traditional weaving is a way to preserve the cultural heritage of a community. Weaving patterns, colors, and motifs are often passed down from generation to generation, providing a tangible link to the past. By continuing to weave in the traditional way, communities can ensure that their cultural identity is not lost.",
         "desc3":"Empowerment of women: Weaving has traditionally been a craft done by women, and it has provided a source of income and empowerment for many women around the world. Through weaving, women can contribute to the economic growth of their communities while preserving their cultural traditions.",
         "desc4":" Environmental sustainability: Traditional weaving is often done with natural fibers and dyes, which are more sustainable and environmentally friendly than synthetic materials. By using locally sourced materials and natural dyes, traditional weavers can create textiles that are not only beautiful but also sustainable.",
         "desc5":"Economic development: Weaving can provide a source of income for communities, especially in rural areas where there may be limited job opportunities. Traditional weaving can also help create a niche market for handmade textiles, attracting tourists and contributing to the local economy.",
         "desc6":"Artistic expression: Traditional weaving is not just a craft but also an art form. Weaving patterns, colors, and motifs are often unique to a particular community, reflecting the artistic expression of the weavers. By continuing to weave in the traditional way, weavers can express their creativity and produce textiles that are truly one-of-a-kind.In conclusion, traditional weaving culture is a vital part of our society. It provides a tangible link to our cultural heritage, empowers women, promotes environmental sustainability, contributes to economic development, and allows for artistic expression. By supporting traditional weaving communities, we can help preserve this ancient tradition and ensure that it continues to play a significant role in our society."
    }
]

export default content;