import { Box, Button, Card, Container, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { clearCartAsync } from '../store/slices/CartSlice';
import { useDispatch } from 'react-redux';
import { clearCurrentOrder } from '../store/slices/OrderSlice';

function Successfull() {
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    useEffect(()=>{
        dispatch(clearCurrentOrder())
        dispatch(clearCartAsync(userInfo.userId));
        localStorage.removeItem("ShippingInfo");
    }, [])

  return (
    <Container sx={{ padding: '2rem' }}>
                <Card sx={{ maxWidth: 345, margin:'auto', padding:'2rem',boxShadow:'none' }}>
                  <Box textAlign='center'>
                      <CheckCircleIcon color="success" sx={{ fontSize: '3rem' }} />
                      <Typography>Your Order has been Placed successfully</Typography>
                      <Button variant='contained'
                          sx={{
                              marginTop: '10px',
                              backgroundColor: '#3498db',
                              color: '#fff',
                              '&:hover': {
                                  backgroundColor: '#484848',
                                  color: '#fff',
                              },
                          }}
                          onClick={()=>navigate('/orderhistory')}
                      >View Orders</Button>
                  </Box>
                  </Card>
              </Container>
  )
}

export default Successfull