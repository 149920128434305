import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {toast} from 'react-toastify';

const initialState = {
    // cartItems:localStorage.getItem("cartItems")?
    // JSON.parse(localStorage.getItem("cartItems")):
    // [],
    cartItems: [],
    cartTotalAmount: localStorage.getItem("Amount")?
    JSON.parse(localStorage.getItem("Amount")):
    0,
    isLoading: false
}

export const fetchCartItemsAsync = createAsyncThunk(
    "fetchCartItemsAsync",
    async (userId) => {
        const response = await axios.post("https://swarnsutra.com/api/getCartItem", {userId});
        return response?.data;
    }
)

export const addToCartAsync = createAsyncThunk(
    "addToCartAsync",
    async (item) => {
        const response = await axios.post("https://swarnsutra.com/api/addToCart", item);
        return response?.data;
    }
)

export const updateCartItemAsync = createAsyncThunk(
    "updateCartItemAsync",
    async (updateItem) => {
        const response = await axios.post("https://swarnsutra.com/api/updateCart", updateItem);
        return response?.data;
    }
)

export const removeItemAsync = createAsyncThunk(
    "removeItemAsync",
    async (itemId) => {
        const response = await axios.post("https://swarnsutra.com/api/deleteCartItem", {id: itemId});
        return response?.data;
    }
)

export const clearCartAsync = createAsyncThunk(
    "clearCartAsync",
    async (userId) => {
        const response = await axios.post("https://swarnsutra.com/api/clearCart", {id: userId});
        return response?.data;
    }
)

const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{
        addToCart:(state, action)=>{
            const itemIndex = state.cartItems.findIndex((item)=> item._id == action.payload._id);
            if(itemIndex >= 0){
               state.cartItems[itemIndex].cartQuantity += 1;
               toast.success(`${state.cartItems[itemIndex].title} quantity increased`)
            }
            else{
                const tempProduct = {...action.payload , cartQuantity:1};
                state.cartItems.push(tempProduct);
                toast.success(`${action.payload.title} added to cart`);
            }

            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            state.cartTotalAmount = state.cartItems.reduce((total, item)=>{
                total = total + Number(item.price) * Number(item.cartQuantity);

                return total;
            }, 0);

            localStorage.setItem("Amount", JSON.stringify(state.cartTotalAmount));
            
        },

        deleteItem:(state, action)=>{
            state.cartItems = state.cartItems.filter((item)=> item._id !== action.payload);
             // total amount
             state.cartTotalAmount = state.cartItems.reduce((total, item)=>{
                total = total + Number(item.price) * Number(item.cartQuantity);

                return total;
            }, 0);
            toast.success(`Item removed from the cart`)
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            localStorage.setItem("Amount", JSON.stringify(state.cartTotalAmount))
        },

        increaseQuantity:(state, action)=>{
            const itemIndex = state.cartItems.findIndex((item)=> item._id == action.payload);
            
            if(state.cartItems[itemIndex].cartQuantity >= state.cartItems[itemIndex].quantity){
                state.cartItems[itemIndex].cartQuantity = state.cartItems[itemIndex].quantity;
                toast.warning('Max Quantity Reached')
            }
            else{
                state.cartItems[itemIndex].cartQuantity  += 1; 
                toast.success(`${ state.cartItems[itemIndex].title} Quantity increased`, {position:"bottom-left"});
            }
            
            // total amount
            state.cartTotalAmount = state.cartItems.reduce((total, item)=>{
                total = total + Number(item.price) * Number(item.cartQuantity);

                return total;
            }, 0);
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
            localStorage.setItem("Amount", JSON.stringify(state.cartTotalAmount))
           
        },

        decreaseQuantity:(state, action)=>{
            const itemIndex = state.cartItems.findIndex((item)=> item._id == action.payload);
            if(state.cartItems[itemIndex].cartQuantity <= 1){
                state.cartItems = state.cartItems.filter((item)=> item._id !== action.payload);
                toast.success(`Item removed from the cart`,{position:"bottom-left"})
                // total amount
                state.cartTotalAmount = state.cartItems.reduce((total, item)=>{
                    total = total + Number(item.price) * Number(item.cartQuantity);
    
                    return total;
                }, 0);
                localStorage.setItem("Amount", JSON.stringify(state.cartTotalAmount))
            }
            else{
                state.cartItems[itemIndex].cartQuantity -= 1;
                toast.success(`${ state.cartItems[itemIndex].title} Quantity decreased`, {position:"bottom-left"});
                // total amount
                state.cartTotalAmount = state.cartItems.reduce((total, item)=>{
                    total = total + Number(item.price) * Number(item.cartQuantity);
                    return total;
                }, 0);
                localStorage.setItem("Amount", JSON.stringify(state.cartTotalAmount))
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        clearCart:(state)=>{
            state.cartItems = [];
            state.cartTotalAmount = 0;
            localStorage.removeItem("cartItems");
            localStorage.removeItem("Amount");
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCartItemsAsync.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCartItemsAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.cartItems = action.payload.cartItems;
        })
        builder.addCase(addToCartAsync.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(addToCartAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.cartItems.push(action.payload.result);
        })
        builder.addCase(updateCartItemAsync.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateCartItemAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            const itemIndex = state.cartItems.findIndex((item)=> item._id === action.payload.result._id);
            state.cartItems[itemIndex] = action.payload.result;
           
        })
        builder.addCase(removeItemAsync.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(removeItemAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.cartItems = state.cartItems.filter((item)=> item._id !== action.payload.doc._id);
        })
        builder.addCase(clearCartAsync.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(clearCartAsync.fulfilled, (state, action) => {
            state.isLoading = false;
            state.cartItems = [];
        });
    
    }
});

export default cartSlice.reducer;

export const selectCartItems = (state)=> state.carts.cartItems;
export const selectIsLoading = (state)=> state.carts.isLoading;

export const { clearCart} = cartSlice.actions;