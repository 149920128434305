import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialState = {
    items: [],
    all_items: [],
    related:[],
    mightLike:[],
    filter:{
        title:'',
        category:''
    },
    maxPrice: 0,
    minPrice: 0,
    isLoading: false,
}

export const productsFetch = createAsyncThunk(
    "productsFetch",
    async () => {
        const response = await axios.get("https://swarnsutra.com/api/products");
        return response?.data;
    }
)

export const fetchRelatedProducts = createAsyncThunk(
    "fetchRelatedProducts",
    async (filter) => {
        const response = await axios.post("https://swarnsutra.com/api/relatedProducts", filter );
        return response?.data;
    }
)

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        
        searchByName: (state, action) => {
            const {name, value} = action.payload;
            const {all_items} = state;
            state.filter = {...state.filter, [name]: value};
            const {title} = state.filter;
            let products = [...all_items];

            if(title){
                products = products.filter((product)=>{
                    return product.title.toLowerCase().includes(title);
                })

                state.items = products;
            }
        },
        sortByCategory: (state, action) => {
            const {name, value} = action.payload;
            state.filter = {...state.filter, [name]: value};
            const {category} = state.filter;
            if(category){
                const sortedProducts = state.all_items.filter((product) =>
                    product.category === category
                )

                state.items = sortedProducts;
            }
        },
        sortByPrice(state, action) {
            const tempSortProduct = [...state.items];
            const value = action.payload;
            if (value === "LTH") {
                const filterdProd = tempSortProduct.sort((item1, item2) => item1.price - item2.price);
                state.items = filterdProd;
            }
            else {
                const filterdProd = tempSortProduct.sort((item1, item2) => item2.price - item1.price);
                state.items = filterdProd;
            }
        },
        sortByRange(state, action) {
            const value = action.payload;
            if (value) {
                const filterdProd = state.all_items.filter((item) => item.price <= value);
                state.items = filterdProd;
            }
        },
        getMaxAndMin: (state, action) => {
            let priceArr = state.all_items.map((currItem) => currItem.price);
            state.maxPrice = Math.max(...priceArr);
            state.minPrice = Math.min(...priceArr);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(productsFetch.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(productsFetch.fulfilled, (state, action) => {
            state.isLoading = false;
            state.items = action.payload;
            state.all_items = action.payload;
        })
        builder.addCase(productsFetch.rejected, (state, action) => {
            toast.error('Something went wrong!');
        })
        builder.addCase(fetchRelatedProducts.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchRelatedProducts.fulfilled, (state, action) => {
            state.isLoading = false;
            const{related, randomProducts} = action.payload;
            state.related = related;
            state.mightLike = randomProducts;
        });
    }
});


export default filterSlice.reducer;
export const selectRelatedProducts = (state)=> state.filters.related;
export const selectmightLikeProducts = (state)=> state.filters.mightLike;
export const { sortByCategory, sortByPrice, sortByRange, searchByName, getMaxAndMin } = filterSlice.actions;