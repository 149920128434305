import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useRef, useState } from 'react'
import JoditEditor from 'jodit-react';
import { toast } from 'react-toastify';
import Loading from '../Pages/Loading';
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../store/slices/UserSlice";
import { useNavigate } from 'react-router-dom';

const config = {
  placeholder: 'Additional Information...',
  "enter": "P",
  "showCharsCounter": false,
  "showWordsCounter": false,
  "showXPathInStatusbar": false,
  "disablePlugins": "add-new-line",
  "askBeforePasteHTML": false,
  "askBeforePasteFromWord": false,
  "defaultActionOnPaste": "insert_only_text",
  "toolbar": false,
}
const NewProduct = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [fabric, setFabric] = useState('');
  const [price, setPrice] = useState('');
  const [color, setColor] = useState('');
  const [size, setSize] = useState('');
  const [quantity, setQuantity] = useState('');
  const [section, setSection] = useState('');
  const [rating, setRating] = useState('');
  const [designer, setDesigner] = useState('');
  const [gender, setGender] = useState('');
  const [mainCat, setMainCat] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [bulletPoint, setBulletPoint] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [HSNCode, setHSNCode] = useState('');
  const [productCode, setProductCode] = useState('');
  const [GST, setGST] = useState('');
  const [Sold, setSold] = useState('');
  const [fileArray, setFileArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const { allMainCategory, allSubCategory } = useSelector(state => state.category);

  const dispatch = useDispatch();
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  const editor = useRef(null);

  const uploadImage = (event) => {
    const files = event.target?.files;
    setFileArray([...fileArray, files[0]]);
    const fileArr = Array.from(files);
    const imageArray = fileArr.map((file) => {
      return {
        url: URL.createObjectURL(file), name: file.name, id: images.length, size: file.size, type: file.type, lastModifiedDate
          : file.lastModifiedDate
      };
    })
    setImages(prevImages => prevImages.concat(imageArray));
  }

  const createProduct = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (fileArray?.length > 0) {
      const data = new FormData();
      for (const file of fileArray) {
        data.append("image", file);
      }

      data.append("title", title);
      data.append("category", category);
      data.append("fabric", fabric);
      data.append("price", price);
      data.append("color", color);
      data.append("size", size);
      data.append("section", section);
      data.append("designer", designer);
      data.append("mainCat", mainCat);
      data.append("gender", gender);
      data.append("quantity", quantity);
      data.append("description", description);
      data.append("HSNCode", HSNCode);
      data.append("productCode", productCode);
      data.append("additionalInfo", additionalInfo);
      data.append("bulletPoint", bulletPoint);
      data.append("rating", rating);
      data.append('GST', GST);
      data.append('Sold', Sold);

      try {
        const res = await axios.post('https://swarnsutra.com/api/upload', data, { headers: { 'authorization': userInfo.token } });
        if (res.data.status == 200) {
          setTitle('');
          setCategory('');
          setFabric('');
          setPrice('');
          setColor('');
          setBulletPoint('');
          setRating('');
          setDescription('');
          setAdditionalInfo('');

          setImages([]);
          setFileArray([]);

          setLoading(false)
          toast.success("Product Added Successfully")
        } else if (res.data.status == 505) {
          setLoading(false);
          dispatch(userLogOut());
          localStorage.removeItem("userInfo")
          toast.error(`${res.data.message}`);
          navigate('/login');
        } else {
          setLoading(false)
          toast.error("Something went wrong")
        }

      } catch (error) {
        setLoading(false)
        toast.error("Internal Server Error");
      }
    }
  }

  if (loading) {
    return (
      <Loading />
    )
  }
  return (
    <Box sx={{
      margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 60px 280px' },
      padding: '20px 45px',
      backgroundColor: 'rgb(248,250, 255)'
    }}
      component="form"
      onSubmit={createProduct}
    >
      <Box mb={2} py={2} bgcolor='dodgerblue' display='flex' alignItems='center' sx={{width: '100%'}}>
        <Typography color='white' pl={2}>Please fill below details to add the product.</Typography>
      </Box>

      <Box my={2}>
        <TextField fullWidth variant="outlined" label="Product Name" value={title} onChange={(e) => setTitle(e.target.value)} required />
      </Box>
      <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
        <FormControl fullWidth required>
          <InputLabel >Main Category</InputLabel>
          <Select
            value={mainCat}
            label="Main Category"
            onChange={(e) => setMainCat(e.target.value)}
          >

            {
              allMainCategory.map((mainCategory) => (
                <MenuItem value={mainCategory} key={mainCategory}>{mainCategory}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel >Category</InputLabel>
          <Select
            value={category}
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
          >
            {
              allSubCategory.map((subCategory) => (
                <MenuItem value={subCategory} key={subCategory}>{subCategory}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Stack>
      <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
        <TextField fullWidth variant="outlined" label="Price" value={price} onChange={(e) => setPrice(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="Color" value={color} onChange={(e) => setColor(e.target.value)} required />
      </Stack>
      <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
        <TextField fullWidth variant="outlined" label="Size" value={size} onChange={(e) => setSize(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="Bullet Point" value={bulletPoint} onChange={(e) => setBulletPoint(e.target.value)} required />
      </Stack>
      <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
        <TextField fullWidth variant="outlined" label="Gender" value={gender} onChange={(e) => setGender(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="Desinger" value={designer} onChange={(e) => setDesigner(e.target.value)} required />
      </Stack>
      <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
        <TextField fullWidth variant="outlined" label="Rating" value={rating} onChange={(e) => setRating(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="Section(Ex:Trending,Banarasi)" value={section} onChange={(e) => setSection(e.target.value)} required />
      </Stack>
      <Box mb={2}>
        <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
          <TextField fullWidth variant="outlined" label="Fabric" value={fabric} onChange={(e) => setFabric(e.target.value)} required />
          <TextField fullWidth variant="outlined" label="Quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
        </Stack>
      </Box>
      <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
        <TextField fullWidth variant="outlined" label="HSN Code" value={HSNCode} onChange={(e) => setHSNCode(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="Product Code" value={productCode} onChange={(e) => setProductCode(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="GST %" value={GST} onChange={(e) => setGST(e.target.value)} required />
        <TextField fullWidth variant="outlined" label="Sold Quantity" value={Sold} onChange={(e) => setSold(e.target.value)} required />
      </Stack>
      <Box mb={2}>
        <TextField label="Description" multiline variant="filled" fullWidth value={description} onChange={(e) => setDescription(e.target.value)} required />
      </Box>
      <Box mb={2}>
        <JoditEditor
          ref={editor}
          value={additionalInfo}
          config={config}
          onChange={content => setAdditionalInfo(content)}
        />
      </Box>

      <Box mb={2} display='flex' flexWrap='wrap' gap={2}>
        {
          images?.length > 0 && images.map((file) => (
            <Box key={file.id}>
              <img src={file.url} width='100px' height='100px' alt='upload' /><br />
              <small>{file.name}</small><br />
              <small>{file.lastModifiedDate.toLocaleString('en-IN', {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",

              })}</small>
              <Button onClick={() => setImages(images.filter((currImage) => currImage.id !== file.id))}>Remove</Button>
            </Box>
          ))
        }

      </Box>
      <label style={{
        display: 'inline-block',
        height: '50px',
        border: '1px solid black',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '6px'
      }}>
        Add Image
        <br />
        <input type='file' accept='image/jpg' onChange={uploadImage} hidden />
      </label>
      {
        images.length >= 4 && <p>you can upload maximum 4 images</p>
      }
      <br /><br />

      <Button variant='contained' type='submit'>Upload</Button>
    </Box>
  )
}

export default NewProduct;