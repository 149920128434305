// this components will take shipping info of the user

import React, { useEffect, useState } from 'react'
import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PublicIcon from '@mui/icons-material/Public';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import { addShippingDetails, addUserAddressAsync, fetchUserInfoAsync, selectIsLoading, selectUserInfo, updateUserAsync, userLogedIn } from '../store/slices/UserSlice';
import CheckoutSteps from '../Components/CheckoutSteps';
import { useForm } from 'react-hook-form';
import { selectCartItems } from '../store/slices/CartSlice';
import Loading from './Loading';


const Shipping = () => {
  const [deliveryAddress, SetDeliveryAddress] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const userInfo = useSelector(selectUserInfo);
  const isLoading = useSelector(selectIsLoading);
  const cartItems = useSelector(selectCartItems);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      street: '',
      city: '',
      state: '',
      phone: '',
      pincode: '',
      country: 'India'
    }
  });

  const handleAddress = (e) => {
    SetDeliveryAddress(userInfo?.address[e.target.value]);
  }

  const onSubmit = (data) => {
    if (deliveryAddress) {
      localStorage.setItem("ShippingInfo", JSON.stringify(data));
      dispatch(addShippingDetails(data));

    }
    else {
      dispatch(addUserAddressAsync({ email: user.email, address: data }));
      localStorage.setItem("ShippingInfo", JSON.stringify(data));
      dispatch(addShippingDetails(data));
    }
    navigate('/order');
  }

  useEffect(() => {
    if (deliveryAddress) {
      setValue('name', deliveryAddress.name);
      setValue('street', deliveryAddress.street);
      setValue('city', deliveryAddress.city);
      setValue('state', deliveryAddress.state);
      setValue('phone', deliveryAddress.phone);
      setValue('pincode', deliveryAddress.pincode);
    }
  }, [deliveryAddress, setValue]);

  useEffect(() => {
    dispatch(fetchUserInfoAsync(user.email));
  }, [user.email]);

  if(isLoading){
    return <Loading/>
  }

  return (
    <>
      {cartItems.length === 0 && <Navigate to='/'></Navigate>}
      <Container component="main" sx={{ py: 3 }}>
        <CheckoutSteps activeStep={0} />
        <Grid container mt={2} spacing={3}>
          <Grid item xs={12} md={7} lg={7}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',

              }}
            >

              <Typography variant='h6' color='#111827' fontFamily='Apple Symbols,Open Sans, Helvetica Neue'>Shipping Address</Typography>
              <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>

                <TextField
                  margin="normal"
                  fullWidth
                  label="Name"
                  id="name"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <HomeIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                  {
                  ...register("name", { required: "Name is required." })
                  }
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  label="Street"
                  id="street"
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <HomeIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                  {
                  ...register("street", { required: "Street is required." })
                  }
                  error={Boolean(errors.street)}
                  helperText={errors.street?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="city"
                  label="City"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LocationCityIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                  {
                  ...register("city", { required: "City is required." })
                  }
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="state"
                  label="State"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <LocationCityIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                  {
                  ...register("state", { required: "State is required." })
                  }
                  error={Boolean(errors.state)}
                  helperText={errors.state?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="phone"
                  label="Phone No"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SmartphoneIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                  {
                  ...register("phone", {
                    required: "Phone Number is required.", pattern: {
                      value: /^\d{10}$/,
                      message: "Please enter a valid 10 digits Phone Number."
                    }
                  })
                  }
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="pincode"
                  label="Pincode"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PinDropIcon color='primary' />
                      </InputAdornment>
                    )
                  }}
                  {
                  ...register("pincode", {
                    required: "Pincode is required.", maxLength: {
                      value: 6,
                      message: "PinCode must be 6 digits."
                    }
                  })
                  }
                  error={Boolean(errors.pincode)}
                  helperText={errors.pincode?.message}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  id="country"
                  label="Country"
                  defaultValue='India'
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PublicIcon color='primary' />
                      </InputAdornment>
                    )
                  }}

                />

                <Typography color='primary'>Products will be deliver to the above provided address.</Typography>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, textTransform: 'capitalize', fontSize: '1rem', marginTop: '15px', border: 'none', backgroundColor: '#ae12ae', '&:hover': { bgcolor: '#d616d6', border: 'none' } }}
                >
                  {deliveryAddress ? 'Continue' : 'Save and Continue'}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            <Box bgcolor='#F9FAFB' borderRadius={2} p={2}>
              {userInfo && userInfo.address.length > 0 ? <FormControl fullWidth>
                <FormLabel id="demo-radio-buttons-group-label">Choose Existing Address</FormLabel>
                <RadioGroup
                  name="address"
                  onChange={(e) => handleAddress(e)}
                >
                  {
                    userInfo.address?.map((address, index) => (
                      <Box display='flex' alignItems='flex-start' key={index} border='1px solid #D1D5DB' py={2} mt={2} borderRadius='0.375rem'>
                        <FormControlLabel value={index} control={<Radio />} sx={{ ml: 0, mr: 0 }} />

                        <Box>
                          <Typography color='#6B7280' fontWeight={600}>{address.name}</Typography>
                          <Typography color='#6B7280'>{address.street}, {address.city} {address.state}-{address.pincode}, {address.country}</Typography>
                          <Typography color='#6B7280'>{address.phone}</Typography>
                        </Box>

                      </Box>
                    ))
                  }
                </RadioGroup>
              </FormControl>
                :
                <Typography fontWeight={600}>Address is not added in your account.</Typography>
              }

            </Box>
          </Grid>
        </Grid>

      </Container>
    </>

  )
}

export default Shipping;
