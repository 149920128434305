import React, { useEffect, useState } from "react";
import { Box, Button, Card, Container, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Succeess = () => {
    const navigate = useNavigate();
    const [paymentStatus, setPaymentStatus] = useState(null);
    var currentTimeAndDate = new Date().toLocaleString("en-GB", {weekday:'long', year:'numeric',month:'long',day:'numeric'});
    const {userName, userEmail, shippingDetails} = useSelector((state) => state.user);
    const cartItems = useSelector((state) => state.carts.cartItems);
    const subtotal = cartItems.reduce((acc, item)=>acc + item.cartQuantity * item.price, 0);
    const shippingCharge = subtotal > 1000 ? 0 : 100;
    const total = subtotal + shippingCharge;

    const orderData = {
        ...shippingDetails,
        name:userName,
        email:userEmail,
        productName: cartItems,
        price:total,
        orderDate:currentTimeAndDate,
        payment_method: 'Online',
        payment_status:paymentStatus
    }


    console.log(orderData);


    useEffect(() => {
        // Parse the query string into an object
        const queryParams = new URLSearchParams(window.location.search);
    
        // Get the value of the payment_status parameter
        const paymentStatusParam = queryParams.get('payment_status');
        
        // Update the state with the payment_status value
        setPaymentStatus(paymentStatusParam);

        const postData=async()=>{
            const resp=await axios.post('https://swarnsutra.com/api/placeOrderAfterOnlinePayment',orderData)
            if(resp.data.status){
                toast.success("Order Placed Successfully");
            }else{
                toast.error("Something went wrong");
            }
        }

        if(paymentStatusParam=="Credit"){
            postData();
        }

      }, []);


      if(paymentStatus=="Credit"){
          return (
              <Container sx={{ padding: '2rem' }}>
                <Card sx={{ maxWidth: 345, margin:'auto', padding:'2rem',boxShadow:'none' }}>
                  <Box textAlign='center'>
                      <CheckCircleIcon color="success" sx={{ fontSize: '3rem' }} />
                      <Typography>Your Order has been Placed successfully</Typography>
                      <Button variant='contained'
                          sx={{
                              marginTop: '10px',
                              backgroundColor: '#3498db',
                              color: '#fff',
                              '&:hover': {
                                  backgroundColor: '#484848',
                                  color: '#fff',
                              },
                          }}
                          onClick={()=>navigate('/orderhistory')}
                      >View Orders</Button>
                  </Box>
                  </Card>
              </Container>
          )
      }else{
        return(
            <Card sx={{ maxWidth: 345, margin:'20px auto', padding:'2rem',boxShadow:'none'}}>
                <Box textAlign='center'>
                    <Box  display='flex' justifyContent='center' alignItems='center' width='50px' height='50px' sx={{borderRadius:'100%', margin:'auto'}}>
                     <InfoIcon color="error" fontSize="large"  />
                    </Box>
                    <Typography mt={2} color='red'>Your Payment has been failed!</Typography>
                    <Button variant='contained' sx={{
                              marginTop: '20px',
                              backgroundColor: '#484848',
                              color: '#fff',
                              '&:hover': {
                                  backgroundColor: '#404040',
                                  color: '#fff',
                              },
                          }}
                        onClick={()=>navigate('/orderhistory')}>
                        Back
                    </Button>
                </Box>
            </Card>
        )
      }


}

export default Succeess;