import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import Loading from '../Pages/Loading';

function EditBill() {
    const [orderId, setOrderId] = useState('');
    const [billNumber, setBillNumber] = useState('');
    const [productName, setProductName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            orderId: orderId,
            billNumber: billNumber
        };
        try {
            const resp=await axios.post('https://swarnsutra.com/api/updateBillNumber',data);
            if(resp.data.status==200){
                setLoading(false);
                toast.success("Bill Number Updated Successfully");
            }else{
                setLoading(false);
                toast.error(resp.data.message);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong try later!");
        }
    }

    const handleGetBill = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const resp = await axios.post('https://swarnsutra.com/api/getBillNumber', { orderId: orderId });
            if (resp.data.status == 200) {
                setBillNumber(resp.data.orderDetails.billNumber);
                setProductName(resp.data.orderDetails.productName[0].title);
                setLoading(false);
            } else {
                setLoading(false);
                toast.info(resp.data.message);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong try later!");
        }
    }

    if (loading) {
        return (
            <Loading />
        )
    }


    return (
        <Box sx={{ margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 0 280px' }, padding: '10px 0px' }}>
            <Grid container ml={5}>
                <Grid item xs={6}>
                    <Box component='form' onSubmit={handleGetBill}>
                        <h4>Edit Bill Number</h4>
                        <TextField
                            label="Enter Order Id"
                            required
                            onChange={(e) => { setOrderId(e.target.value) }}
                        />

                        <br /><br />
                        <Button type='submit' variant='outlined'>Fetch Details</Button>
                    </Box>
                </Grid>
                <Grid item xs={6} mt={2}>
                    <Box component='form' onSubmit={handleUpdate}>
                        {productName == '' ?
                            <>
                                Product Name : XYZ <br /><br />
                                Order Id : XYZ <br /> <br />
                            </> : <>
                                Product Name : {productName} <br /><br />
                                Order Id : {orderId} <br /> <br />
                            </>
                        }

                        Edit Bill Number<br />
                        <TextField required value={billNumber} onChange={(e) => { setBillNumber(e.target.value) }} /> <br /> <br />
                        <Button variant='outlined' type='submit'>Update Bill Number</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EditBill