import React, { useEffect } from 'react'
import { Box, Typography, Button, Grid } from '@mui/material';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux';
import { ordersFetch, selectOrders } from '../store/slices/OrderSlice';
import Loading from './Loading';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import OrderTracker from '../Components/OrderTracker';

const OrderHistory = () => {
    const dispatch = useDispatch();
    const orders = useSelector(selectOrders);
    const loading = useSelector(state => state.order.isLoading);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const handleCancelOrder = async (orderId) => {
        const result = window.confirm("Are you sure you want to cancel this order?");
        if (result) {
            const resp = await axios.put('https://swarnsutra.com/api/changeOrderStatus', { orderId, status: 'Cancelled' });

            if (resp.data.status == 200) {
                window.location.reload();
            }
        }
    }


    const handleDownloadInvoice = async (orderId) => {
        try {
            const resp = await axios.post('https://swarnsutra.com/api/getInvoice', { orderId: orderId });
            if (resp.data.status == 200) {
                window.location.href = resp.data.URL;
            }
            else {
                return toast.error('Some server issue try later!');
            }

        } catch (error) {
            toast.error('Something went wrong!')
        }
    }

    useEffect(() => {
        dispatch(ordersFetch(userInfo.email));
    }, [dispatch]);
    console.log(orders);

    if (loading) {
        return <Loading />
    }

    return (
        <Box>
            {
                orders.length === 0 && (
                    <Grid container textAlign="center" p={10}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CrisisAlertIcon color="primary" sx={{ fontSize: "50px" }} />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={5}>
                            <Typography mb={2}>You have not placed any order yet.</Typography>
                            <Button variant="contained"><Link to='/products' style={{ color: '#fff' }}>Buy Products </Link></Button>
                        </Grid>
                    </Grid>
                )
            }

            {orders.length > 0 &&
                <Box minWidth='22rem' maxWidth='72rem' mx='auto' py='2rem' px={{ xs: '1.5rem' }}>
                    {/* Header Part */}
                    <Box>
                        <h1 style={{ color: '#111827', fontWeight: 'bold', fontSize: '1.5rem', lineHeight: '2rem', letterSpacing: '-0.025em' }}>My Orders</h1>
                        <p style={{ color: '#6B7280', marginTop: '0.5rem', fontSize: '1rem', lineHeight: '1.5rem' }}>Check the status of recent orders, manage returns, and download invoices.</p>
                    </Box>

                    {/* Order Details */}
                    {
                        orders.map((order) => (
                            <Box border='1px solid  rgb(209, 213, 219)' mt='4rem' key={order._id}>
                                
                                <div>
                                    
                                    <Box>
                                        
                                        <div>
                                            {/* Order Header */}
                                            <Box py='1.5rem' px='1rem' sx={{ display: { md: 'flex' }, justifyContent: { md: 'space-between' }, alignItems: { md: 'center' } }} bgcolor='rgb(243 244 246)' color='rgb(249, 250, 251)' borderRadius={{ sm: '0.5rem' }} p={{ sm: '1.5rem' }}>
                                                <Box color='rgb(75 85 99)' fontSize='1rem' lineHeight='1.25rem' sx={{ display: { md: 'grid' }, gridTemplateColumns: { md: 'repeat(3, minmax(0, 1fr))' }, columnGap: { md: '1.5rem', lg: '2rem' }, }} flex={{ xs: '1 1 auto', lg: 'none' }}>

                                                    <Box display={{ xs: 'flex', md: 'block' }} justifyContent='space-between'>
                                                        <div style={{ color: 'rgb(17 24 39)', fontWeight: '500' }}>Order Number</div>
                                                        <Box sx={{ fontSize: '1rem', lineHeight: '1.25rem' }} m='0px' mt={{ md: '0.25rem' }}>{order._id}</Box>
                                                    </Box>
                                                    <Box display={{ xs: 'flex', md: 'block' }} justifyContent='space-between' pt={{ xs: '1rem', md: '0rem' }} my={{ xs: '1rem', md: '0rem' }} borderTop={{ xs: '1px solid rgb(209, 213, 219)', md: 'none' }}>
                                                        <div style={{ color: 'rgb(17 24 39)', fontWeight: '500' }}>Order Placed</div>
                                                        <Box sx={{ fontSize: '1rem', lineHeight: '1.25rem' }} m='0px' mt={{ md: '0.25rem' }} >{order.orderDate}</Box>
                                                    </Box>
                                                    <Box display={{ xs: 'flex', md: 'block' }} justifyContent='space-between' pt={{ xs: '1rem', md: '0rem' }} my={{ xs: '1rem', md: '0rem' }} borderTop={{ xs: '1px solid rgb(209, 213, 219)', md: 'none' }}>
                                                        <div style={{ color: 'rgb(17 24 39)', fontWeight: '500' }}>Total Amount</div>
                                                        <Box sx={{ fontSize: '1rem', lineHeight: '1.25rem' }} m='0px' mt={{ md: '0.25rem' }}>₹{order.totalAmount}.00</Box>
                                                    </Box>


                                                </Box>
                                                <Box display={{ sm: 'flex' }} mt={{ xs: '1.5rem', md: '0rem' }} className='space-y-4 sm:space-x-4 sm:space-y-0 md:space-x-6 lg:space-x-8'>
                                                    <Button
                                                        onClick={() => handleCancelOrder(order._id)}
                                                        sx={{ width: { xs: '100%', md: 'auto' }, color: 'rgb(55 65 81)', textTransform: 'capitalize', border: '1px solid rgb(209 213 219)', backgroundColor: 'white', '&:hover': { backgroundColor: 'rgb(249 250 251)' }, padding: '0.5rem 1rem', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)' }}
                                                    >
                                                        Cancel
                                                    </Button>

                                                    <Button
                                                        onClick={() => handleDownloadInvoice(order._id)}
                                                        sx={{ width: { xs: '100%', md: 'auto' }, mx: { sm: '1rem' }, marginTop: { xs: '0.5rem', sm: '0rem' }, color: 'rgb(55 65 81)', textTransform: 'capitalize', border: '1px solid rgb(209 213 219)', backgroundColor: 'white', '&:hover': { backgroundColor: 'rgb(249 250 251)' }, padding: '0.5rem 1rem', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)' }}
                                                    >
                                                        View Invoice
                                                    </Button>

                                                    <Button
                                                        sx={{ width: { xs: '100%', md: 'auto' }, marginTop: { xs: '0.5rem', sm: '0rem' }, color: 'rgb(55 65 81)', textTransform: 'capitalize', border: '1px solid rgb(209 213 219)', backgroundColor: 'white', '&:hover': { backgroundColor: 'rgb(249 250 251)' }, padding: '0.5rem 1rem', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)' }}
                                                    >
                                                        Return
                                                    </Button>
                                                </Box>
                                            </Box>


                                            {/* Order Items*/}
                                            <Box px={{ xs: '0.5rem', sm: '0rem' }} mt={{ xs: '1.5rem', sm: '2.5rem' }} display='flow-root'>
                                            <OrderTracker status={order.status}/>
                                                <Box my={{ xs: '-1.5rem', sm: '-2.5rem' }} >


                                                    {
                                                        order.productName && order.productName.map((item) => (
                                                            <Box py={{ xs: '1.5rem', sm: '2.5rem' }} display='flex' borderBottom='1px solid rgb(229 231 235)' key={item._id}>
                                                                <Box minWidth='0px' flex='1 1 0%'>
                                                                    <Box pr={{ sm: '1rem' }}>
                                                                        <Box display={{ sm: 'flex' }} justifyContent={{ sm: 'space-between' }}>
                                                                            <div>
                                                                                <h4 style={{ marginTop: '0', marginBottom: '8px' }} className="text-gray-900 font-medium">{item.product.title}</h4>
                                                                                {/* <p className="hidden sm:block text-sm text-gray-500 mt-2">{order.brand}</p> */}
                                                                                <Typography sx={{ marginTop: '0.5rem' }} display={{ xs: 'hidden', sm: 'block' }} color='rgb(107 114 128)' fontSize='1rem' lineHeight='1.25rem'>Quantity : {item.cartQuantity}</Typography>
                                                                            </div>
                                                                            <Typography color='rgb(17 24 39)' fontWeight={500} mt={{ xs: '0.25rem', sm: '0rem' }} ml={{ sm: '1.5rem' }}>₹{item.product.price}.00</Typography>
                                                                        </Box>
                                                                        <Box display='flex' mt={{ xs: '0.5rem', sm: '1rem' }} fontWeight={500}>
                                                                            <Link style={{ color: 'rgb(79 70 229)' }} to={`/singleproduct/${item.product._id}`}>View Product</Link>
                                                                            <Box ml={{ xs: '1rem', sm: '1.5rem' }} pl={{ xs: '1rem', sm: '1.5rem' }} borderLeft='1px solid rgb(229 231 235)'>
                                                                                <a style={{ color: 'rgb(79 70 229)' }} href="#">Buy Again</a>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box mt='1.5rem' fontWeight={500}>

                                                                        {order.status !== 'Delivered' && <div className='flex'>
                                                                            <p>Delivery: within 7 days</p>
                                                                        </div>}

                                                                    </Box>
                                                                    <Box mt='1.5rem' fontWeight={500}>
                                                                        {
                                                                            order.status === 'Delivered' ? (
                                                                                <Box display='flex' alignItems='center' gap={0.5}>
                                                                                 <DoneIcon color='primary'/> <p>{order.status}</p>
                                                                                </Box>
                                                                            )
                                                                                :
                                                                                (
                                                                                    <Box display='flex' alignItems='center' gap={0.5}>
                                                                                        <p>{order.status}</p>
                                                                                    </Box>
                                                                                )
                                                                        }

                                                                    </Box>
                                                                </Box>
                                                                <Box ml={{ xs: '1rem', sm: '1.5rem' }} m={{ sm: 0 }} mr={{ sm: '1.5rem' }} order={{ sm: '-9999' }} flexShrink={0}>
                                                                    <Box component='img' src={item.product.images[0]} sx={{ width: { xs: '6rem', sm: '10rem' }, height: { xs: '8rem', sm: '12rem' }, borderRadius: '0.5rem', objectFit: 'cover', objectPosition: 'center' }} className='col-start-2 sm:col-start-1 sm:row-span-2 sm:row-start-1' />
                                                                </Box>
                                                            </Box>
                                                        ))
                                                    }

                                                </Box>
                                            </Box>
                                        </div>
                                    </Box>
                                </div>

                                {/* Payment Details */}
                                <Box mt='5rem'>
                                    <Box py={{ xs: '1.5rem', lg: '2rem' }} px={{ xs: '1.5rem', lg: '0rem' }} borderRadius='0.5rem' bgcolor='rgb(249 250 251)' display={{ lg: 'grid' }} gridTemplateColumns={{ lg: 'repeat(12, minmax(0, 1fr))' }} columnGap={{ lg: '2rem' }}>
                                        <Box display='grid' gridTemplateColumns={{ xs: 'repeat(1, minmax(0, 1fr))', sm: 'repeat(2, minmax(0, 1fr))' }} columnGap={{ md: '2rem' }} pl={{ lg: '2rem' }} gridColumn={{ lg: 'span 7 / span 7' }} gap='1.5rem'>
                                            <div>
                                                <div style={{ color: '#111827', fontWeight: '500', fontSize: '1rem', lineHeight: '1.5rem' }}>Shipping Address</div>
                                                <Box mt='0.75rem' color='#6B7280' >
                                                    <p style={{margin:'0px'}}>{order.address.name}</p>
                                                    <span style={{ display: 'block' }}>{order.address.street}</span>
                                                    <span style={{ display: 'block' }}>{order.address.city} - {order.address.pincode}</span>
                                                    <span style={{ display: 'block' }}>{order.address.country}</span>
                                                </Box>
                                            </div>

                                            <div>
                                                <div style={{ color: '#111827', fontWeight: '500', fontSize: '1rem', lineHeight: '1.5rem' }}>Payment Method</div>
                                                <Box mt='0.75rem' >
                                                    <p style={{ color: '#111827' }}>Payment : {order.payment_method}</p>
                                                </Box>
                                            </div>
                                        </Box>
                                        <Box mt={{ xs: '2rem', lg: '0rem' }} pr={{ lg: '2rem' }} gridColumn={{ lg: 'span 5 / span 5' }}>
                                            <Box display='flex' justifyContent='space-between' alignItems='center' pb='1rem' mb='1rem' borderBottom='1px solid rgb(229, 231, 235)'>
                                                <div style={{ color: '#4B5563' }} >Subtotal</div>
                                                <div style={{ color: '#111827', fontWeight: '500' }}>₹{order.totalAmount + order.discount}.00</div>
                                            </Box>
                                            <Box display='flex' justifyContent='space-between' alignItems='center' pb='1rem' mb='1rem' borderBottom='1px solid rgb(229, 231, 235)'>
                                                <div style={{ color: '#4B5563' }}>Shipping Charge</div>
                                                <div style={{ color: '#111827', fontWeight: '500' }}>₹0.00</div>
                                            </Box>
                                            <Box display='flex' justifyContent='space-between' alignItems='center' pb='1rem' mb='1rem' borderBottom='1px solid rgb(229, 231, 235)'>
                                                <div style={{ color: 'green' }}>Discount</div>
                                                <div style={{ color: 'green', fontWeight: '500' }}> - ₹{order.discount}.00</div>
                                            </Box>
                                            <Box display='flex' justifyContent='space-between' alignItems='center' pb='1rem'>
                                                <div style={{ color: '#111827', fontWeight: '500' }} >Order Total </div>
                                                <div style={{ color: '#4F46E5', fontWeight: '500' }}>₹{order.totalAmount}.00</div>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        ))

                    }



                </Box>
            }
        </Box>
    )
}

export default OrderHistory;