import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";


const initialState = {
    category: [],
    allMainCategory: [],
    allSubCategory: [],
}


export const fetchCategories = createAsyncThunk('fetchCategories', 
    async () => {
        const response = await axios.get("https://swarnsutra.com/api/getCategory");
        return response.data;   
    }
)
export const addCategory = createAsyncThunk('addCategory', 
    async (newCategory) => {
        const response = await axios.post("https://swarnsutra.com/api/addCategory", newCategory);
        return response.data;   
    }
)
export const deleteMainCategory = createAsyncThunk('deleteMainCategory', 
    async (mainCategory) => {
        console.log(mainCategory);
        const response = await axios.post("https://swarnsutra.com/api/deleteMainCategory", mainCategory);
        return response.data;   
    }
)
export const deleteCategory = createAsyncThunk('deleteCategory', 
    async (category) => {
        console.log(category);
        const response = await axios.post("https://swarnsutra.com/api/deleteCategory", category);
        return response.data;   
    }
)
const categorySlice = createSlice({
    name:'category',
    initialState,
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(fetchCategories.fulfilled, (state, action)=>{
            state.category = action.payload;

            // Extract all main categories
            state.allMainCategory = state.category.map(category => category.mainCategory);

            // Extract all subcategories
            state.allSubCategory = state.category.flatMap(category => category.category);
        });
        builder.addCase(addCategory.fulfilled, (state, action) => {
            const { message, status } = action.payload;
            if(status === 200){
                toast.success(message);
            }
            else{
                toast.error('Something went wrong')
            }
        });
        builder.addCase(deleteMainCategory.fulfilled, (state, action) => {
            console.log(action.payload);
            const { message, status } = action.payload;
            if(status === 200){
                toast.success(message);
            }
            else if(status === 500){
                 toast.error(message);
            }
            else{
                toast.error('Something went wrong')
            }
        });
        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            console.log(action.payload);
            const { message, status } = action.payload;
            if(status === 200){
                toast.success(message);
            }
            else if(status === 500){
                 toast.error(message);
            }
            else{
                toast.error('Something went wrong')
            }
        });
    }
})

export default categorySlice.reducer;