import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../images/swarnsutra_logo.jpg';
import logo from '../images/logo1.png'
import NavLinks from "./NavLinks";
import Account from "./Account";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { AppBar, Toolbar, Box, Badge, IconButton, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCartItems } from "../store/slices/CartSlice";


const  Header = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const cartItems = useSelector(selectCartItems);
  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    // #eaeded
    <AppBar position="sticky" sx={{ backgroundColor: '#eaeded', height: '76px', boxShadow: 'none', color: '#000'}}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 500 }}>

        {/* logo */}
        <Box className="logo">
          <Link to='/'>
            <img src={logo} alt="logo" style={{width:'120px', minWidth:'80px', height:'50px', objectFit:'cover'}}/>
          </Link>
        </Box>

        <ul className="menu-link">
          <NavLinks />
        </ul>

        {/* cart, Account, menuIcon*/}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
          <IconButton onClick={() => navigate('/cart')}>
            <Badge badgeContent={cartItems.length} color="warning">
              <ShoppingCartIcon color="primary" />
            </Badge>
          </IconButton>
          {
            isAuthenticated!==''?  <Account /> : <Button sx={{ borderRadius: '9999px' }} variant="contained" onClick={() => navigate('/login')}>Login</Button>
          }

          <IconButton sx={{ fontSize: '0rem', display: { xs: 'block', md: 'none' } }} onClick={() => setOpen(!open)} >
            {
              open ? <CloseIcon /> : <MenuIcon />
            }
          </IconButton>
        </Box>

  
        {/* Mobile nav */}
        <ul className={` mobile-menu ${open ? "left-0" : "left--100"}`}>        
          <NavLinks  setOpen = {setOpen}/>
        </ul>

      </Toolbar>
    </AppBar>


  )
}

export default Header;