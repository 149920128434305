import { Box, Card, Grid, Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const CategorySection = ({ item }) => {
    const { _id, category, images } = item;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Grid item xs={6} sm={4} md={3} key={_id}>
            <Card sx={{ borderRadius: '6px', boxShadow:'none', position: 'relative', }}
            component={Link} to={`/products/${category}`}
            >
                <Box
                 onMouseOver={() => setIsHovered(true)}
                 onMouseOut={() => setIsHovered(false)}
                 >

                    <Box component='img' src={isHovered ? images[1] : images[0] } sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />

                    <Box display='flex' justifyContent='center' alignItems='center' position='absolute'  bottom={0} color='white' bgcolor='rgba(0, 0, 0, 0.53)' minWidth='100%' padding='15px'>
                        <Typography variant='subtitle2' fontFamily='Apple Symbols,Open Sans Helvetica Neue' textTransform='uppercase'>{category}</Typography>
                    </Box>
                </Box>

            </Card>
        </Grid>

    )
}

export default CategorySection;