// this is the blog component

import { Box, Container, Grid, Typography } from "@mui/material";
import content from '../blogContent';
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Blog = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
            <Box mt={2}>
            <img src="https://swarnsutra.com/api/product/Blog_Banner.jpg" width='100%'/>
            </Box>
              <Box textAlign='center' mt={2}>
              <Typography variant="h4"
              display='inline-block'
                sx={{
                    position:'relative',
                    '&::after': {
                    content: `''`,
                    position: 'absolute',
                    width: '100%',
                    height: '3px',
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor:'#4caf50',
                    textAlign:'center'
                  }}}
                >
                    Blog
                </Typography>
              </Box>
            <Grid container mt={2} position='relative' spacing={2} mb={2}>
               
                {content?.map((blog) => {
                    return (
                        <Grid key={blog.id} item xs={12} sm={6} md={4} mb='2rem'>
                           
                                <Box sx={{height:{xs:'auto', sm:'200px'}}}>
                                    <Box component='img' alt="prod_1" src={blog.image} sx={{ width: '100%', height:'100%', objectFit:{xs:'contain', sm:'cover'}, borderRadius:'6px'}} />
                                </Box>
                          
                           <Box mt={2}> 
                           <Typography variant="subtitle2" noWrap color='#4B5E63'>
                                    Date: {blog.date}
                            </Typography>
                            <Typography variant="subtitle" noWrap mt={2} display='block'>
                                   {blog.title}
                            </Typography>
                            <Typography variant="subtitle2" color='#4B5E63' mt={2} mb={2} display='block'>
                                    {blog.desc}
                            </Typography>
                            <Link to={`/blog/${blog.id}`} style={{textTransform:'uppercase', color:'#333'}}>Read More</Link>
                           </Box>
                        </Grid>
                    )
                })
                }

            </Grid>
        </Container>
    )
}

export default Blog;