import React, { useState, useRef} from 'react';
import JoditEditor from 'jodit-react';
import { Dialog, DialogContent, DialogTitle, Button, FormControl, TextField, InputLabel, Box, Typography, Stack, MenuItem, Select } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../Pages/Loading';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { userLogOut } from "../store/slices/UserSlice";

const config = {
  placeholder: 'Additional Information...',
  "enter": "P",
  "showCharsCounter": false,
  "showWordsCounter": false,
  "showXPathInStatusbar": false,
  "disablePlugins": "add-new-line",
  "askBeforePasteHTML": false,
  "askBeforePasteFromWord": false,
  "defaultActionOnPaste": "insert_only_text",
  "toolbar": false,
}

const EditProduct = ({ item }) => {
  var userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id, title, price, category, fabric, color, size, quantity, section, rating, designer, gender, mainCat, additionalInfo, bulletPoint, description, HSNCode, productCode, images, GST, Sold } = item;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [newCategory, setNewCategory] = useState(category);
  const [newFabric, setNewFabric] = useState(fabric);
  const [newPrice, setNewPrice] = useState(price);
  const [newColor, setNewColor] = useState(color);
  const [newHSNCode, setNewHSNCode] = useState(HSNCode);
  const [newProductCode, setNewProductCode] = useState(productCode);
  const [newSize, setNewSize] = useState(size);
  const [newQuantity, setNewQuantity] = useState(quantity ? quantity : 0);
  const [newSection, setNewSection] = useState(section);
  const [newRating, setNewRating] = useState(rating);
  const [newDesigner, setNewDesigner] = useState(designer);
  const [newGender, setNewGender] = useState(gender);
  const [newMainCat, setNewMainCat] = useState(mainCat);
  const [newGST, setNewGST] = useState(GST);
  const [newSold, setNewSold] = useState(Sold);
  const [newAdditionalInfo, setNewAdditionalInfo] = useState(additionalInfo);
  const [newBulletPoint, setNewBulletPoint] = useState(bulletPoint);
  const [newDescription, setNewDescription] = useState(description);
  const editor = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const editProduct = async (e) => {
    const sure = window.confirm("Are you sure you want to update product info?");
    if (sure) {
      e.preventDefault();
      setLoading(true);
      const data = { productId: _id, title: newTitle, price: newPrice, category: newCategory, fabric: newFabric, color: newColor, size: newSize, quantity: newQuantity, section: newSection, rating: newRating, designer: newDesigner, gender: newGender, mainCat: newMainCat, additionalInfo: newAdditionalInfo, bulletPoint: newBulletPoint, description: newDescription, HSNCode: newHSNCode, productCode: newProductCode, GST: newGST, Sold: newSold };
      try {
        const resp = await axios.put('https://swarnsutra.com/api/updateProduct', data, { headers: { 'authorization': userInfo.token } });
        if (resp.data.status == 200) {
          setLoading(false);
          handleClose();
          toast(`${resp.data.message}`);
          window.location.reload();
        } else if (resp.data.status == 505) {
          setLoading(false);
          dispatch(userLogOut());
          localStorage.removeItem("userInfo")
          toast.error(`${resp.data.message}`);
          navigate('/login');
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setLoading(false);
        toast.error(`Check your internet connection`);
      }
    } else {
      toast("Product updation operation cencelled!");
    }
  }


  if (loading) {
    return (
      <Loading />
    )
  }
  return (
    <div>
      <Button variant="outlined" startIcon={<EditIcon />} onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
        <DialogTitle>Edit Product</DialogTitle>

        <DialogContent>
          <Box sx={{

            padding: '20px',
            backgroundColor: 'rgb(248,250, 255)'
          }}
            component="form"
            onSubmit={editProduct}
          >
            <Box mb={4} bgcolor='dodgerblue' height='50px' display='flex' alignItems='center' overflow='hidden'>
              <Typography color='white' pl={2}>Please edit below details to Edit the product.</Typography>
            </Box>

            <Box mb={2}>
              <TextField fullWidth variant="outlined" label="Product Name" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} required />
            </Box>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <FormControl fullWidth required>
                <InputLabel >Category</InputLabel>
                <Select
                  value={newCategory}
                  label="Category"
                  onChange={(e) => setNewCategory(e.target.value)}
                >
                  <MenuItem value="Banarasi Saree">Banarasi Saree</MenuItem>
                  <MenuItem value="Designer Saree">Designer Saree</MenuItem>
                  <MenuItem value="Linen Saree">Linen Saree</MenuItem>
                  <MenuItem value="Cotton Saree">Cotton Saree</MenuItem>
                  <MenuItem value="Organic Cotton Saree">Organic Cotton Saree</MenuItem>
                  <MenuItem value="Chanderi Saree">Chanderi Saree</MenuItem>
                  <MenuItem value="Silk Saree">Silk Saree</MenuItem>
                  <MenuItem value="Cotton Silk Saree">Cotton Silk Saree</MenuItem>
                  <MenuItem value="Pashmina And Merino Stole">Pashmina And Merino Stole</MenuItem>
                  <MenuItem value="Pashmina Wool Stole">Pashmina Wool Stole</MenuItem>
                  <MenuItem value="Merino Wool Stole">Merino Wool Stole</MenuItem>
                  <MenuItem value="Silk Stole">Silk Stole</MenuItem>
                  <MenuItem value="Cotton Stole">Cotton Stole</MenuItem>
                  <MenuItem value="Organic Cotton Stole">Organic Cotton Stole</MenuItem>
                  <MenuItem value="Silk Dupatta">Silk Dupatta</MenuItem>
                  <MenuItem value="Linen Dupatta">Linen Dupatta</MenuItem>
                  <MenuItem value="Cotton Dupatta">Cotton Dupatta</MenuItem>
                  <MenuItem value="Cotton Silk Dupatta">Cotton Silk Dupatta</MenuItem>
                  <MenuItem value="Chanderi Dupatta">Chanderi Dupatta</MenuItem>
                </Select>
              </FormControl>
              <TextField fullWidth variant="outlined" label="Fabric" value={newFabric} onChange={(e) => setNewFabric(e.target.value)} required />
            </Stack>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField fullWidth variant="outlined" label="Price" value={newPrice} onChange={(e) => setNewPrice(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="Color" value={newColor} onChange={(e) => setNewColor(e.target.value)} required />
            </Stack>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField fullWidth variant="outlined" label="Size" value={newSize} onChange={(e) => setNewSize(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="Bullet Point" value={newBulletPoint} onChange={(e) => setNewBulletPoint(e.target.value)} required />
            </Stack>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField fullWidth variant="outlined" label="Gender" value={newGender} onChange={(e) => setNewGender(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="Desinger" value={newDesigner} onChange={(e) => setNewDesigner(e.target.value)} required />
            </Stack>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField fullWidth variant="outlined" label="Rating" value={newRating} onChange={(e) => setNewRating(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="Section" value={newSection} onChange={(e) => setNewSection(e.target.value)} required />
            </Stack>
            <Box mb={2}>
              <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
                <FormControl fullWidth required>
                  <InputLabel >Main Category</InputLabel>
                  <Select
                    value={newMainCat}
                    label="Main Category"
                    onChange={(e) => setNewMainCat(e.target.value)}
                  >
                    <MenuItem value="Saree">Saree</MenuItem>
                    <MenuItem value="Stole">Stole</MenuItem>
                    <MenuItem value="Dupatta">Dupatta</MenuItem>
                  </Select>
                </FormControl>
                <TextField fullWidth variant="outlined" label="Quantity" value={newQuantity} onChange={(e) => setNewQuantity(e.target.value)} required />
              </Stack>
            </Box>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField fullWidth variant="outlined" label="HSN Code" value={newHSNCode} onChange={(e) => setNewHSNCode(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="Product Code" value={newProductCode} onChange={(e) => setNewProductCode(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="GST" value={newGST} onChange={(e) => setNewGST(e.target.value)} required />
              <TextField fullWidth variant="outlined" label="Sold Quantity" value={newSold} onChange={(e) => setNewSold(e.target.value)} required />
            </Stack>
            <Box mb={2}>
              <TextField label="Description" multiline variant="filled" fullWidth value={newDescription} onChange={(e) => setNewDescription(e.target.value)} required />
            </Box>
            <Box mb={2}>
              {/* <TextField label="Additional Information" multiline variant="filled" fullWidth value={newAdditionalInfo} onChange={(e) => setNewAdditionalInfo(e.target.value)} required /> */}
              <JoditEditor
                ref={editor}
                value={newAdditionalInfo}
                config={config}
                onChange={content => setNewAdditionalInfo(content)}
              />
            </Box>
            <Box textAlign='right'>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type='submit'>Save Changes</Button>
              <Button LinkComponent={Link} to={`/dashboard/update/${_id}`}>Edit image</Button>
            </Box>
          </Box>

        </DialogContent>

      </Dialog>
    </div>
  );
}

export default EditProduct;