import React from 'react'
import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Tooltip, ListItemIcon, Typography } from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { userLogOut } from '../store/slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';

const Account = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const { userName } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        if (event.currentTarget.innerText === "Profile") {
            navigate('profile');
        }
        else if (event.currentTarget.innerText === "My Order") {
            navigate('/orderhistory');
        }
        else if (event.currentTarget.innerText === "Logout") {
            dispatch(userLogOut())
            localStorage.removeItem("userInfo")
            navigate('/');
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
                <Tooltip title={userName}>
                    <IconButton
                        onClick={handleClick}
                        size="large"
                        sx={{ ml: 0, p:0 , '&:hover': {backgroundColor: '#fff'} }}
                        aria-controls={openMenu ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                    >
                        <Avatar alt={userName} sx={{ width: 36, height: 36 }}>{userName[0].toUpperCase()}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleClose}>
                <ListItemIcon><PersonOutlineIcon fontSize='small'/></ListItemIcon>
                    <Typography>Profile</Typography>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                <ListItemIcon><LocalMallOutlinedIcon fontSize='small'/></ListItemIcon>
                <Typography>My Order</Typography>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography>Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default Account;