import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import { useEffect } from 'react';
import { productsFetch } from './store/slices/FilterSlice';
import { useDispatch } from 'react-redux';
import Layout from './Components/Layout';

const App = () => {

  // const dispatch = useDispatch();

  // useEffect(()=>{
  //   dispatch(productsFetch());
  // },[]);

  return (
    
      <BrowserRouter>
        <ToastContainer />
        <Layout/>
      </BrowserRouter>
    
  );
}

export default App;
