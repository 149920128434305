// this is the home components of the swarnsutra website

import React, { useEffect } from 'react'
import { Box, Grid,  Typography } from '@mui/material'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import ImageSlider from '../Components/ImageSlider';
import { productsFetch } from '../store/slices/FilterSlice';
import Loading from './Loading';
import CategorySection from '../Components/CategorySection';
import HomeItem from '../Components/HomeItem';


const Home = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.filters.items);
  const products = [...product].sort((item1, item2) => item1.price - item2.price);

  const trendingProd = products.filter((item) => item.section === "Trending");
  const newProd = products.filter((item) => item.section === "Banarasi");
  const banarasiSaree =  products.filter((item) => item.category === "Banarasi Saree");
  const designerSaree =  products.filter((item) => item.category === "Designer Saree");
  const designerSaree2 =  products.filter((item) => item.section === "Designer");
  const cottonSaree =  products.filter((item) => item.category === "Cotton Saree");
  const cottonSaree2 =  products.filter((item) => item.section === "Cotton");
  const linenSaree =  products.filter((item) => item.category === "Linen Saree");
  const linenSaree2 =  products.filter((item) => item.section === "Linen");
  const newStole2 =  products.filter((item) => item.section === "Stole");
  const cate3 =  products.filter((item) => item.category === "Merino Wool Stole");
  const cate4 =  products.filter((item) => item.category === "Pashmina Wool Stole");
  const cate5 =  products.filter((item) => item.category === "Organic Cotton Stole");
  const cate6 =  products.filter((item) => item.category === "Pashmina And Merino Stole");
  const cate7 =  products.filter((item) => item.category === "Silk Stole");
  const cate10 =  products.filter((item) => item.category === "Linen Dupatta");
  const cate11 =  products.filter((item) => item.category === "Cotton Silk Dupatta");
 
  const cateArr = [banarasiSaree[0], designerSaree[0],cottonSaree[0],linenSaree[0],cate11[0],cate10[0],cate5[0], cate6[0], cate3[0], cate4[0], cate7[0]];

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(productsFetch());
  }, [dispatch, pathname]);

  if(cateArr[0] === undefined){
    return(
      <Loading/>
    )
  }

  return (
    <Box >

      <Box component='div' sx={{ mt: { xs: '5px', md: 0 } }}>
        <ImageSlider />
      </Box>
      <Box marginTop="2rem" pb={3} position='relative' sx={{pl:{xs:'5%', sm:'20%'}, pr:{xs:'5%', sm:'20%'}}}>
        <FormatQuoteIcon sx={{color: 'rgb(242, 116, 106)',position: 'absolute', top:{xs:'0px', sm:'2px'}, left:{xs:'6%', sm:'21%'}, rotate:`180deg`}}/>
        <Typography color='#153a5b' fontWeight={550} sx={{ fontSize: { xs: '1rem', sm: '1.25rem', md:'1.5rem' }, paddingLeft: { xs: '2rem', sm: "2.5rem" } }}>Choose local, weave a better community - support your local weavers today!</Typography>
      </Box>


      {/* Trending Products */}
      <Box  p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' pt={2} pb={2} mb={2} textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>Trending Products</Typography>
        <Grid container spacing={3}>

          {
            trendingProd.map((item) => (
              <HomeItem key={item._id} item={item} />
            ))
          }
        </Grid>
      </Box>

      {/* Banarasi sarees */}
      <Box p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' pt={2} pb={2} mb={2} textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>"Banarasi sarees - perfect blend of tradition and style"</Typography>
        <Grid container spacing={3}>

          {
            newProd.map((item) => (
              <HomeItem key={item._id} item={item} />
            ))
          }
        </Grid>
      </Box>

      {/* Designer Saree */}
      <Box p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' pt={2} pb={2} mb={2} textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>"Experience the luxury of our designer sarees collection"</Typography>
        <Grid container spacing={3}>

          {
            designerSaree2.map((item) => (
              <HomeItem key={item._id} item={item} />
            ))
          }
        </Grid>
      </Box>

      {/* Cooton Saree */}
      <Box p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' pt={2} pb={2} mb={2} textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>"Wrap yourself in elegance with the timeless grace of cotton sarees."</Typography>
        <Grid container spacing={3}>

          {
            cottonSaree2.map((item) => (
              <HomeItem key={item._id} item={item} />
            ))
          }
        </Grid>
      </Box>

      {/* Linen Saree */}
      <Box p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' pt={2} pb={2} mb={2} textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>"Embrace the sheer sophistication of linen sarees, where style meets comfort."</Typography>
        <Grid container spacing={3}>

          {
            linenSaree2.map((item) => (
              <HomeItem key={item._id} item={item} />
            ))
          }
        </Grid>
      </Box>

      <Box p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' pt={2} pb={2} mb={2} textTransform='uppercase' fontFamily='Apple Symbols , Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>Newly Added Stole</Typography>
        <Grid container spacing={3}>

          {
            newStole2.map((item) => (
              <HomeItem key={item._id} item={item} />
            ))
          }
        </Grid>
      </Box>

      {/* Shop By Category Banarasi Saree , Stole , Designer Saree*/}
      <Box p={2}>
        <Typography variant='h6' textAlign='center' fontWeight={600} letterSpacing={2} bgcolor='#d6e6f2' p={2} textTransform='uppercase' mb={2} fontFamily='Apple Symbols,Open Sans Helvetica Neue' fontSize={{xs:'0.825rem', sm:'1rem', md:'1.25rem'}}>Shop By Category</Typography>
        <Grid container spacing={3}>
          {
            cateArr.map((item) => (
              <CategorySection key={item._id} item = {item} />
            ))
          }
        </Grid>
      </Box>
    </Box>
  )
}

export default Home;

