// user's profile update components

import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { fetchUserInfoAsync, selectIsLoading, selectUserInfo, updateUserProfileAsync, userLogOut } from "../store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useForm } from "react-hook-form";

const genders = ["Male", "Female", "Other"];
const ProfileUpdate = () => {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const user = useSelector(selectUserInfo);
    const isLoading = useSelector(selectIsLoading);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     fetch('https://swarnsutra.com/api/profile', {
    //         method: 'put',
    //         headers: {
    //             'authorization': userInfo.token,
    //             'Accept': 'Application/json',
    //             'Content-Type': 'Application/json'
    //         },
    //         body: JSON.stringify(data)
    //     }).then((result) => {
    //         result.json().then((result2) => {
    //             if (result2.status == 200) {
    //                 setLoading(false);
    //                 toast.success(result2.message);
    //                 navigate('/profile');
    //             } else if (result2.status == 505) {
    //                 setLoading(false);
    //                 dispatch(userLogOut());
    //                 localStorage.removeItem("userInfo")
    //                 toast.error(result2.message);
    //             } else {
    //                 setLoading(false);
    //                 toast.error(result2.message);
    //             }
    //         })
    //     })

    // }

    useEffect(() => {

        if (user) {
            setValue('name', user.name);
            setValue('email', user.email);
            setValue('phone', user.phone);
            setValue('age', user.age);
            // setValue('gender', user.gender);
        }

    }, [user, setValue]);


    useEffect(() => {
        dispatch(fetchUserInfoAsync(userInfo.email));
    }, [userInfo.email]);

    return (
        <>
            {isLoading ? <Loading /> :

                <Container>
                    <Box textAlign='center' mt={5}>
                        <AccountCircleIcon color="primary" fontSize="large" />
                        <h3>Update Profile</h3>
                    </Box>
                    <form noValidate onSubmit={handleSubmit((data) => {
                        dispatch(updateUserProfileAsync(data));
                        toast.success('profile Updated Successfully')
                    })}>
                        <Grid container my={5} spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                    {
                                    ...register('name', {
                                        required: 'Name is Required'
                                    })
                                    }
                                    error={Boolean(errors.name)}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    disabled
                                    {
                                    ...register('email', {
                                        required: 'Email is Required'
                                    })
                                    }
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField required fullWidth variant="outlined"
                                    label="Phone Number"
                                    type="text"
                                    {
                                    ...register("phone", {
                                        required: "Phone Number is required.", pattern: {
                                            value: /^\d{10}$/,
                                            message: "Please enter a valid 10 digits Phone Number."
                                        }
                                    })
                                    }
                                    error={Boolean(errors.phone)}
                                    helperText={errors.phone?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <TextField
                                    fullWidth
                                    label="Age"
                                    {
                                    ...register('age', {
                                        required: 'Age is Required'
                                    })
                                    }
                                    error={Boolean(errors.age)}
                                    helperText={errors.age?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel >Gender</InputLabel>
                                    <Select
                                        label="Gender"
                                        defaultValue={user?.gender !== null ? user?.gender : ''}
                                    >
                                        {genders.map((gender, index) => (
                                            <MenuItem key={index} value={gender}>{gender}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={5}>
                                <Button type='submit' variant="contained">Update</Button>
                            </Grid>
                            <Grid item xs={12} p={5}>
                                <i>if you want to update your email or name just sent us email</i>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            }
        </>
    )
}

export default ProfileUpdate;