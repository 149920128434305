// the whole product is stored here

import { Box, Button, Slider, FormControlLabel, FormControl, Grid, Radio, RadioGroup, Typography, useTheme, useMediaQuery, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormatPrice } from '../utils/FormatPrice';
import FilterDrawerComp from './FilterDrawerComp';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartAsync, selectCartItems, updateCartItemAsync } from '../store/slices/CartSlice';
import { Link } from 'react-router-dom';
import { sortByCategory, sortByPrice, sortByRange, getMaxAndMin, searchByName } from '../store/slices/FilterSlice';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Loading from './Loading';
import { toast } from 'react-toastify';

const Product = () => {
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { category } = useSelector(state => state.category);
    const cartItems = useSelector(selectCartItems);
    const filterdata = useSelector((state) => state.filters.items);
    const products = useSelector((state) => state.filters.all_items);
    const { isLoading, filter: { title }, maxPrice, minPrice } = useSelector((state) => state.filters);
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const selectPageHandler = (selectedPage) => {
        if (selectedPage >= 1 &&
            selectedPage <= Math.ceil(filterdata.length / 15) &&
            selectedPage !== page) {
            setPage(selectedPage);
        }
    }

    const filterByCategory = (event) => {
        const { name, value } = event.target;
        setPage(1);
        dispatch(sortByCategory({ name, value }));
    }

    const searchByValue = (e) => {
        const { name, value } = e.target;
        dispatch(searchByName({ name, value }));
    }

    const handleCart = (product) => {
        const itemIndex = cartItems.findIndex((item) => item.product._id === product._id);
        if (itemIndex >= 0) {

            if (cartItems[itemIndex].cartQuantity >= product.quantity) {
                toast.warning("max quantity reached");
            }
            else {
                var newQuantity = cartItems[itemIndex].cartQuantity;
                newQuantity += 1;
                dispatch(updateCartItemAsync({ id: cartItems[itemIndex]._id, quantity: newQuantity }));
                toast.success(`${product.title} quantity increased`);
            }
        }
        else {

            dispatch(addToCartAsync({ user: userInfo.userId, product: product._id, cartQuantity: 1 }));
            toast.success(`${product.title} added to cart`);
        }
    }


    const filterByPrice = (event) => {
        const value = event.target.defaultValue;
        dispatch(sortByPrice(value));
    }

    const filterByRange = (event) => {
        const value = event.target.value;
        setPage(1);
        dispatch(sortByRange(value));
    }

    useEffect(() => {
        dispatch(getMaxAndMin());
    }, [filterdata])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [products, page])

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (

        <Grid container position='relative'>
            <Grid item md={3} sx={{
                width: { xs: '100%', md: '280px' }, top: { xs: '0px', md: '76px' }, height: { xs: '', md: 'calc(100vh - 76px)' }, overflowY: 'auto', position: 'sticky', 
                
            }} >
                {
                    isMatch ? (
                        <FilterDrawerComp />
                    ) :
                        (
                            <Box pl={2} pt={2} height='100%' position='sticky' top={80} borderRight='1px solid #dee4e8'
                                sx={{ 
                                    overflowX: 'hidden', 
                                    '&::-webkit-scrollbar': {
                                        width: '8px'
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#c9f0f0',
                                        borderRadius: '8px'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#88c7c7',
                                        borderRadius: '8px'
                                    }
                                }} 
                             >
                                <Typography textAlign="center" variant='h6' fontWeight={600} textTransform='uppercase'>Filter</Typography>
                                {
                                    category.map((category) => (
                                        <Box mt={2} pl={2} key={category.mainCategory}>
                                            <FormControl>
                                                <Typography>{category.mainCategory}</Typography>
                                                <RadioGroup
                                                    name="category"
                                                >
                                                    {
                                                        category.category.map((subCategory, index) => (
                                                            <FormControlLabel key={index} value={subCategory} control={<Radio />} label={subCategory} onChange={(e) => filterByCategory(e)} />
                                                        ))
                                                    }

                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    ))
                                }

                                <Box mt={2} pl={2}>
                                    <FormControl>
                                        <Typography>Sort By Price</Typography>
                                        <RadioGroup
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="LTH" control={<Radio />} label="Low to High" onChange={(e) => filterByPrice(e)} />
                                            <FormControlLabel value="HTL" control={<Radio />} label="High to Low" onChange={(e) => filterByPrice(e)} />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                <Box width={200} mt={2} pl={2}>
                                    <Typography >Sort By Range</Typography>
                                    <Slider
                                        aria-label="Restricted values"
                                        defaultValue={200}
                                        valueLabelDisplay="auto"
                                        min={minPrice}
                                        max={maxPrice}
                                        onChange={(e) => filterByRange(e)}
                                    />

                                </Box>

                            </Box>
                        )
                }
            </Grid>

            <Grid item md={9} p={2} sx={{ width: { xs: '100%', md: `calc(100vw - 280px)` } }}>
                <Box>
                    <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                        <TextField type='text' name='title' value={title} placeholder='Search by Product Name....' fullWidth onChange={searchByValue}></TextField>
                    </Box>
                    {filterdata.length == 0 ? (
                        <Box textAlign='center' mt={12}>
                            <Typography letterSpacing={3} fontWeight='bold'>No Product Available</Typography>
                        </Box>
                    )
                        :
                        <Grid container spacing={2} mt={1}>
                            {filterdata.slice(page * 15 - 15, page * 15).map((data) => {
                                return (
                                    <Grid item lg={4} md={6} sm={6} xs={12} textAlign="center" key={data._id}>
                                        <Box p={2} borderRadius='0.375rem' border='1px solid #eee'>
                                            <Link to={`/singleproduct/${data._id}`}>
                                                <img src={data.images[0]} width='100%' height="300px" style={{ objectFit: "contain" }} />
                                            </Link>
                                            <Typography fontFamily='Apple Symbols, Open Sans, Helvetica Neue' textTransform='uppercase' textAlign='left' mt={2.5}>{data.title}</Typography>
                                            <Typography fontSize='smaller' fontFamily='Apple Symbols, Open Sans, Helvetica Neue' textTransform='uppercase' textAlign='left'>{"("}{data.fabric}{")"}</Typography>

                                            <Typography m='6px 0px' fontFamily='Apple Symbols, Open Sans, Helvetica Neue' textTransform='uppercase' textAlign='left' color='#999999'>Price: {<FormatPrice price={data.price} />}</Typography>
                                            {
                                                data.quantity == 0 ? (
                                                    <Button variant="outlined" color="error" sx={{ marginTop: '1rem' }}>Sold out</Button>
                                                )
                                                    :
                                                    (
                                                        <Button variant='contained' onClick={() => handleCart(data)} fullWidth sx={{
                                                            backgroundColor: "#222222",
                                                            marginTop: '0.5rem',
                                                            '&:hover':
                                                            {
                                                                backgroundColor: '#f68872'
                                                            },
                                                            height: '2.875rem',

                                                        }}>Add to Cart</Button>
                                                    )
                                            }
                                        </Box>
                                    </Grid>
                                )
                            })}

                            <Box width='100%' mt={2} p={2} display='flex' justifyContent='center' alignItems='center' flexWrap='wrap'>
                                <Button sx={{
                                    marginRight: '0.5rem',
                                    p: '0.5rem 0.75rem',
                                    border: '1px solid #dee2e6',
                                    color: '#333',
                                    display: `${page > 1 ? '' : 'none'}`,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                    startIcon={<ChevronLeftIcon />}
                                    onClick={() => selectPageHandler(page - 1)}
                                >Prev</Button>
                                {[...Array(Math.ceil(filterdata.length / 15))].map((_, i) => {
                                    return (
                                        <Box component='span' key={i} ml={0.5} mr={0.5} p='0.5rem 0.75rem' color={page === i + 1 ? '#fff' : '#000'}
                                            bgcolor={page === i + 1 ? '#333' : ''}
                                            border='1px solid #dee2e6'
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => selectPageHandler(i + 1)}
                                        >
                                            {i + 1}
                                        </Box>
                                    )
                                })}
                                <Button sx={{
                                    marginLeft: '0.5rem',
                                    p: '0.5rem 0.75rem',
                                    border: '1px solid #dee2e6',
                                    color: '#333',
                                    display: `${page < Math.ceil(filterdata.length / 15) ? '' : 'none'}`,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                    endIcon={<ChevronRightIcon />}
                                    onClick={() => selectPageHandler(page + 1)}
                                >Next</Button>

                            </Box>

                        </Grid>
                    }
                </Box>
            </Grid>
        </Grid>

    )
}

export default Product;