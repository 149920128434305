import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Container, Dialog, DialogContent, DialogTitle, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../Pages/Loading';
import axios from 'axios';
import { useForm } from 'react-hook-form';


const TABLE_HEAD = [
  { id: 'coupon', label: 'Coupon Code', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'expiry', label: 'Expiry Date', alignRight: false },
  { id: 'edit', label: 'Edit', alignRight: false },
  { id: 'delete', label: 'Delete', alignRight: false },
];


const Coupon = () => {


  const location = useLocation();
  const [page, setPage] = useState(0);
  const [coupons, setCoupons] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const getCoupons = async () => {
    try {
      const resp = await axios('https://swarnsutra.com/api/getCoupons');
      setCoupons(resp.data);
    } catch (error) {
      toast.error('Internal server error try later');
    }
  }

  const handleDelete = async (couponCode) => {
    setLoading(true)
    try {
      const resp = await axios.post('https://swarnsutra.com/api/deleteCoupon', { couponCode });
      if (resp.data) {
        toast.success(resp.data.message);
        setLoading(false)
      } else {
        setLoading(false)
        toast.error(`Something went wrong!`);
      }
    } catch (error) {
      setLoading(false)
      toast.info(`Check your internet connection!`);
    }

  }


  useEffect(() => {
    getCoupons();
  }, [location.pathname]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - coupons.length) : 0;
  // const isNotFound = !coupons.length;

  if (loading) {
    return (
      <Loading />
    )
  }
  return (
    <Box sx={{ margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 0 280px' }, padding: '30px 15px', backgroundColor: 'rgb(248,250, 255)' }}>
      <Container>

        <Box display='flex' justifyContent='space-between' flexWrap='wrap' mb={4}>
          <Typography variant="h5" gutterBottom fontWeight={700} sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
            All Coupons
          </Typography>

          <AddCoupon />
        </Box>

        <Card sx={{ borderRadius: '16px' }} >

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size='medium'
            >

              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (

                    <TableCell
                      sx={{ backgroundColor: "rgb(244, 246, 248)", color: 'rgb(99, 115, 129)', fontWeight: 600 }}
                      key={headCell.id}
                      align={headCell.alignRight ? 'right' : 'left'}

                    >
                      {headCell.label}
                    </TableCell>


                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {coupons?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((coupon) => {
                  const { _id, couponCode, discount, expiresAt } = coupon;

                  return (
                    <TableRow key={_id} >

                      <TableCell component="th" scope="row" >
                        <Typography variant="subtitle2" noWrap fontWeight={600}>
                          {couponCode}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">{discount}%</TableCell>
                      <TableCell align="left">{expiresAt.slice(0, 10)}</TableCell>

                      <TableCell align="left">
                        <EditCoupon
                          coupon={coupon}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Button variant='outlined' onClick={() => handleDelete(couponCode)}>Delete</Button>
                      </TableCell>
                    </TableRow>
                  );
                })
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={coupons ? coupons.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Box>
  )
}

export default Coupon;


const AddCoupon = () => {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCoupon = async (data) => {

    try {
      const resp = await axios.post('https://swarnsutra.com/api/createCoupon', data);
      if (resp.data.status === 200) {
        console.log(resp.data);
        setLoading(false)
        toast.success(resp.data.message);
      } else {
        setLoading(false)
        toast.error(`Something went wrong!`);
      }
    } catch (error) {
      setLoading(false)
      toast.info(`Check your internet connection!`);
    }
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add New Coupon
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle bgcolor='#e7e2e2'>Add Coupon</DialogTitle>

        <DialogContent>
          <Box sx={{
            padding: '20px',
          }}
            component="form"
            noValidate
            onSubmit={handleSubmit((data) => {
              console.log(data);
              handleAddCoupon(data);
              reset();
            })}
          >

            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField
                fullWidth
                variant="outlined"
                label="Coupon Code"
                {
                ...register('couponCode', {
                  required: 'Coupon Code is Required'
                })
                }
                error={Boolean(errors.couponCode)}
                helperText={errors.couponCode?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                type='tel'
                label="Discount"
                {
                ...register('discount', {
                  required: 'Discount Percentage is Required'
                })
                }
                error={Boolean(errors.discount)}
                helperText={errors.discount?.message}
              />
            </Stack>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField
                fullWidth
                variant="outlined"
                focused
                label="Expiry Date"
                type='date'
                {
                ...register('expiresAt', {
                  required: 'Expiry Date is Required'
                })
                }
                error={Boolean(errors.expiresAt)}
                helperText={errors.expiresAt?.message}

              />
            </Stack>

            <Button variant='outlined' type='submit'>Save</Button>
          </Box>

        </DialogContent>

      </Dialog>
    </div>
  );
}

const EditCoupon = ({ coupon }) => {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue('couponCode', coupon.couponCode);
    setValue('discount', coupon.discount);
    setValue('expire', coupon.expiresAt.slice(0, 10));
  }, [coupon, setValue])
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle bgcolor='#e7e2e2'>Edit Coupon</DialogTitle>

        <DialogContent>
          <Box sx={{
            padding: '20px',
          }}
            component="form"
            onSubmit={handleSubmit((data) => {
              // pass this data to api to edit the coupon
            })}
          >

            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField
                fullWidth
                variant="outlined"
                label="Coupon Code"

                {
                ...register('couponCode', {
                  required: 'Coupon Code is Required'
                })
                }
                error={Boolean(errors.couponCode)}
                helperText={errors.couponCode?.message}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Discount"

                {
                ...register('discount', {
                  required: 'Discount Percentage is Required'
                })
                }
                error={Boolean(errors.discount)}
                helperText={errors.discount?.message}
              />
            </Stack>
            <Stack mb={2} direction={{ xs: 'column', sm: 'row' }} gap={2} >
              <TextField
                fullWidth
                variant="outlined"
                label="Expiry Date"
                type='date'
                {
                ...register('expire', {
                  required: 'Expiry Date is Required'
                })
                }
                error={Boolean(errors.expire)}
                helperText={errors.expire?.message}
              />
            </Stack>

            <Button variant='outlined' type='submit'>Save</Button>
          </Box>

        </DialogContent>

      </Dialog>
    </div>
  );
}
