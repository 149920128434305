import { Container, Button, Grid, TextField, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Loading from "./Loading";
import NetworkError from "./NetworkError";
import { useDispatch, useSelector } from "react-redux";
import { addUserAddressAsync, fetchUserInfoAsync, selectIsLoading, selectUserInfo, updateUserAddressAsync } from "../store/slices/UserSlice";
import { useForm } from "react-hook-form";

const Profile = () => {
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [showEditFormByIndex, setShowEditFormByIndex] = useState(-1);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(selectUserInfo);
    const isLoading = useSelector(selectIsLoading);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const handleEdit = (addressUpdate, index) => {
        const newUser = { ...user, address: [...user.address] };
        newUser.address.splice(index, 1, addressUpdate);
        dispatch(updateUserAddressAsync(newUser));
        setShowEditFormByIndex(-1);
    }

    const handleDelete = (index) => {
        const newUser = { ...user, address: [...user.address] };
        newUser.address.splice(index, 1);
        dispatch(updateUserAddressAsync(newUser));

    }

    const handleAddAddress = (newAddress) => {
        const newUser = { email: userInfo.email, address: { ...newAddress, country: 'India' } };
        console.log(newUser);
        dispatch(addUserAddressAsync(newUser));
        setShowAddressForm(false);
    }

    const handleEditForm = (index) => {
        setShowEditFormByIndex(index);
        const address = user.address[index];
        setValue('name', address.name);
        setValue('street', address.street);
        setValue('city', address.city);
        setValue('state', address.state);
        setValue('pincode', address.pincode);
        setValue('phone', address.phone);
    }

    useEffect(() => {
        dispatch(fetchUserInfoAsync(userInfo.email));
        window.scrollTo(0, 0);
    }, [pathname]);

    if(isLoading){
        return(
            <Loading/>
        )
    }

    return (

        <Container>
            <Box textAlign='center' mt={2}>
                <h3>My Profile</h3>
                <AccountCircleIcon color="primary" sx={{ fontSize: "70px" }} />
            </Box>

            <Box display='flex' justifyContent='space-between' flexDirection={{ xs: 'column', sm: 'row' }} mt={3} py={3} px={2} bgcolor='#F9FAFB' borderRadius='0.375rem'>
                {user && <Box flex='auto' p={2}>
                    <Typography>Name: {user.name}</Typography>
                    <Typography>Email: {user.email}</Typography>
                    <Typography>Phone Number: {user.phone === null ? 'Not Added' : user.phone}</Typography>
                    <Typography>Gender: {user.gender}</Typography>
                    <Typography>Age: {user.age === null ? 'Not Added' : user.age}</Typography>
                </Box>}
                <Box flexShrink={0} display={{ sm: 'flex' }} alignItems={{ sm: 'flex-end' }} mt={{ xs: 4, md: 0 }}>
                    <Button
                        variant="outlined"
                        LinkComponent={Link}
                        to='/changepassword'
                        sx={{ width: { xs: '100%', md: 'auto' }, color: '#000', textTransform: 'capitalize', backgroundColor: '#fff', borderRadius: '0.375rem', border: '1px solid #D1D5DB' }}
                    >
                        Change Password
                    </Button>
                    <Button
                        variant="outlined"
                        LinkComponent={Link}
                        to='/profileupdate'
                        sx={{ width: { xs: '100%', md: 'auto' }, color: '#000', ml: { xs: '0rem', sm: '1.5rem' }, mt: { xs: '1rem', md: '0rem' }, textTransform: 'capitalize', backgroundColor: '#fff', borderRadius: '0.375rem', border: '1px solid #D1D5DB' }}
                    >
                        Update Profile
                    </Button>
                </Box>
            </Box>

            <Box my={4} p={2} bgcolor='#F9FAFB' borderRadius='0.375rem'>
                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'capitalize' }}
                        onClick={() => { setShowAddressForm((prev) => !prev); setShowEditFormByIndex(-1) }}
                    >Add New Address</Button>
                </Box>
                {
                    showAddressForm && (
                        <form noValidate onSubmit={handleSubmit((data) => {
                            handleAddAddress(data);
                            reset();
                        })}>
                            <Grid container my={5} pb={5} ml={{ xs: 0, sm: 0 }} width={{ xs: '100%', sm: '100%' }} spacing={{ xs: 0, sm: 4 }} borderBottom='1px solid #D1D5DB'>

                                <Grid item xs={12} sm={6}>
                                    <Typography>Name</Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label='Full Name'
                                        {
                                        ...register("name", { required: "Name is required." })
                                        }
                                        error={Boolean(errors.name)}
                                        helperText={errors.name?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Street</Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label='Street'
                                        {
                                        ...register("street", { required: "Street is required." })
                                        }
                                        error={Boolean(errors.street)}
                                        helperText={errors.street?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>City</Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label='City'
                                        {
                                        ...register('city', { required: 'City is Required' })
                                        }
                                        error={Boolean(errors.city)}
                                        helperText={errors.city?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>State</Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label='State'
                                        {
                                        ...register('state', { required: 'State is Required' })
                                        }
                                        error={Boolean(errors.state)}
                                        helperText={errors.state?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Pincode</Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label='Pincode'
                                        {
                                        ...register("pincode", {
                                            required: "Pincode is required.", maxLength: {
                                                value: 6,
                                                message: "PinCode must be 6 digits."
                                            }
                                        })
                                        }
                                        error={Boolean(errors.pincode)}
                                        helperText={errors.pincode?.message}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography>Phone</Typography>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        label='Phone'
                                        {
                                        ...register("phone", {
                                            required: "Phone Number is required.", pattern: {
                                                value: /^\d{10}$/,
                                                message: "Please enter a valid 10 digits Phone Number."
                                            }
                                        })
                                        }
                                        error={Boolean(errors.phone)}
                                        helperText={errors.phone?.message}
                                    />
                                </Grid>
                                <Box width='100%' mt={2} display='flex' justifyContent='flex-end' gap={2}>
                                    <Button variant="outlined" type="submit">Add Address</Button>
                                </Box>
                            </Grid>
                        </form>
                    )
                }
                <h3>My Addresses</h3>
                {
                    user && user.address.map((address, index) => (
                        <Box key={index}>
                            {
                                showEditFormByIndex === index && (
                                    <form noValidate onSubmit={handleSubmit((data) => {
                                        handleEdit(data, index);
                                    })}>
                                        <Grid container my={5} spacing={4}>

                                            <Grid item xs={12} sm={6}>
                                                <Typography>Name</Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label='Full Name'
                                                    {
                                                    ...register("name", { required: "Name is required." })
                                                    }
                                                    error={Boolean(errors.name)}
                                                    helperText={errors.name?.message}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography>Street</Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label='Street'
                                                    {
                                                    ...register("street", { required: "Street is required." })
                                                    }
                                                    error={Boolean(errors.street)}
                                                    helperText={errors.street?.message}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography>City</Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label='City'
                                                    {
                                                    ...register("city", { required: "City is required." })
                                                    }
                                                    error={Boolean(errors.city)}
                                                    helperText={errors.city?.message}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography>State</Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label='State'
                                                    {
                                                    ...register("state", { required: "State is required." })
                                                    }
                                                    error={Boolean(errors.state)}
                                                    helperText={errors.state?.message}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography>Pincode</Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label='Pincode'
                                                    {
                                                    ...register("pincode", {
                                                        required: "Pincode is required.", maxLength: {
                                                            value: 6,
                                                            message: "PinCode must be 6 digits."
                                                        }
                                                    })
                                                    }
                                                    error={Boolean(errors.pincode)}
                                                    helperText={errors.pincode?.message}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography>Phone Number</Typography>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label='Phone'
                                                    {
                                                    ...register("phone", {
                                                        required: "Phone Number is required.", pattern: {
                                                            value: /^\d{10}$/,
                                                            message: "Please enter a valid 10 digits Phone Number."
                                                        }
                                                    })
                                                    }
                                                    error={Boolean(errors.phone)}
                                                    helperText={errors.phone?.message}
                                                />
                                            </Grid>

                                            <Box width='100%' mt={2} display='flex' justifyContent='flex-end' gap={2}>
                                                <Button variant="outlined" onClick={() => setShowEditFormByIndex(-1)}>Cancel</Button>
                                                <Button variant="outlined" type="submit">Save</Button>
                                            </Box>

                                        </Grid>
                                    </form>
                                )
                            }
                            <Box display='flex' justifyContent='space-between' flexDirection={{ xs: 'column', sm: 'row' }} mt={3} py={3} px={2} border='1px solid #D1D5DB' borderRadius='0.375rem'>
                                <Box flex='auto'>
                                    <Typography>{address.name}</Typography>
                                    <Typography>{address.street}</Typography>
                                    <Typography>{address.city}</Typography>
                                    <Typography>{address.state} - {address.pincode}, {address.country}</Typography>
                                    <Typography>Phone : {address.phone}</Typography>
                                </Box>

                                <Box flexShrink={0} display={{ sm: 'flex' }} alignItems={{ sm: 'flex-end' }} mt={{ xs: 4, md: 0 }}>
                                    <Button
                                        onClick={() => handleEditForm(index)}
                                        sx={{ width: { xs: '100%', md: 'auto' }, color: '#000', textTransform: 'capitalize', backgroundColor: '#fff', borderRadius: '0.375rem', border: '1px solid #D1D5DB' }}>
                                        Edit
                                    </Button>
                                    <Button
                                        onClick={() => handleDelete(index)}
                                        sx={{ width: { xs: '100%', md: 'auto' }, color: '#000', ml: { xs: '0rem', sm: '1.5rem' }, mt: { xs: '1rem', md: '0rem' }, textTransform: 'capitalize', backgroundColor: '#fff', borderRadius: '0.375rem', border: '1px solid #D1D5DB' }}>
                                        Remove
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                    ))
                }
            </Box>
        </Container>

    )
}

export default Profile;


/**
 *  <Grid container my={5} spacing={4}>

                <Grid item xs={12} sm={6}>
                    <Typography>Name</Typography><TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label={`${profile.name}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Email</Typography><TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label={`${profile.email}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Phone Number</Typography><TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label={`${profile.phone}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Age</Typography><TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label={`${profile.age}`}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography>Gender</Typography><TextField
                        disabled
                        fullWidth
                        margin="normal"
                        label={`${profile.gender}`}
                    />
                </Grid>
    
                <Grid item lg={12} md={12} sm={12} xs={12} mt={5}>
                    <Button variant="outlined" LinkComponent={Link} to='/changepassword'>Change Password</Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={3}>
                    <Button variant="outlined" LinkComponent={Link} to='/profileupdate'>Update Profile</Button>
                </Grid>
            </Grid> 
 */
