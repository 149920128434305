import React, { useEffect } from 'react'
import { Container, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const ReturnPolicy = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
       <Typography mt={4}><strong>Return & Exchange Policy</strong></Typography>
       <ul>
        <li>The return or exchange can be done within 7 days after your order is delivered.</li>&nbsp;
        <li>In case the customer receives defective/wrong product(s), the reverse pickup will be generated by us and the shipping charges will be covered by us only.</li>&nbsp;
        <li><b>Note</b>: It is mandatory to capture a video/photo before shipping the package or handling it to our courier partner.</li>&nbsp;
        <li>If the customer's pin code is non-serviceable by our courier partners, then the customer is liable to self-ship the product(s) and the shipping charges  100% refundable if customer receives defective/wrong product(s).</li>&nbsp;
        <li>Please courier the product(s) to the following address:</li>
        <Typography>PC Enterprises, Sec- 28, H NO. 458, Gautam Buddha Nagar, Noida, Gautam Buddha Nagar, Uttar Pradesh, 201301.</Typography>&nbsp;
        <li>Kindly pack the product(s) properly to prevent damage during transit. Please ensure the product(s) must be in unused condition with all tags and packaging.</li>&nbsp;
        <li><b>Note</b>: We suggest you to select 'Speed Post' as your courier service rather than using other courier companies. <b>Speed Post</b> is a government-owned entity that operates India's largest postal network and it is totally reliable</li>
       </ul>

       &nbsp;
       <Typography><strong>Refund Policy & Cancellation Policy</strong></Typography>
       <ul>
        <li><b>Prepaid Orders</b>: The refund for the prepaid order will be processed within 7 working days after receiving your order at the warehouse and Quality Check of the product(s). The Refund Amount will be reflected in the respective payment mode.</li>&nbsp;
        <li><b>Note</b>: If you've placed a prepaid order using a Refund Coupon, the Value of the Coupon Code will be refunded through a Coupon and the remaining amount will be paid through your original payment mode.</li>&nbsp;
        <li><b>For COD Orders</b>: A coupon code of the ordered value will be sent to your registered email address/contact number within 48 hours after receiving your order at the warehouse and Quality Check of the product(s).</li>&nbsp;
        <li>The cancellation request for your order can be done within 24 hours of placing the order.</li>
       </ul>

       &nbsp;
       <Typography><strong>Things To Consider</strong></Typography>
       <ul>
        <li>The customers are requested to check the size guide before placing the order.</li>&nbsp;
        <li>The color of the product(s) may vary as per the customer's screen resolution.</li>&nbsp;
        <li>Please provide your WhatsApp registered number for timely updates and a better shopping experience.</li>&nbsp;
        <li>If the customer receives a torn parcel/unrelated product(s)/empty parcel, they are requested to raise a query within 48 hours of delivery. For a safer side, we suggest our customers to create an unboxing video of the parcel too.</li>
       </ul>
    </Container>
  )
}

export default ReturnPolicy;