import { Box, Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { userLogOut } from "../store/slices/UserSlice";
import Loading from "../Pages/Loading"
function Update() {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {productId}=useParams();
  const location=useLocation();
  const [loading,setLoading]=useState(false);
  const [showImage,setShowImage]=useState([]);
  const [image,setImage]=useState([]);
  var userInfo=JSON.parse(localStorage.getItem("userInfo"));

  const handleSubmit=async()=>{
    setLoading(true);
    let formData=new FormData();
    formData.append('image',image[0]);
    formData.append('productId',productId);
    try {
      const resp=await axios.post('https://swarnsutra.com/api/imageUpdate',formData,{headers:{'authorization':userInfo.token}});
      if(resp.data.status==200){
        setLoading(false);
        setShowImage([...showImage, resp.data.url]);
        toast.success(`${resp.data.message}`);
      }else if(resp.data.status==505){
        setLoading(false);
        dispatch(userLogOut());
        localStorage.removeItem("userInfo")
        toast.error(`${resp.data.message}`);
        navigate('/login');
      }else{
            setLoading(false);
            toast.error(`${resp.data.message}`);
          }
        } catch (error) {
          setLoading(false);
          toast.error('something went wrong try later!');
        }
      }
      
      const handleDelete =async (index) =>{
        setLoading(true);
        const data={
        productId,
        index
      }
      
      try {
        const resp=await axios.post('https://swarnsutra.com/api/imageDelete',data,{headers:{'authorization':userInfo.token}})
        if(resp.data.status==200){
          setLoading(false);
          setShowImage(showImage.filter((item, ind) => ind !== index))
          toast.success("Image deleted successfully");
        }else if(resp.data.status==505){
          setLoading(false);
          dispatch(userLogOut());
          localStorage.removeItem("userInfo")
          toast.error(`${resp.data.message}`);
          navigate('/login');
        }else{
          setLoading(false);
          toast.error("Something went wrong");
        }
        
      } catch (error) {
        setLoading(false);
        toast.error('Some server issue try later!');
      }
    }
    
    useEffect(()=>{
        const fetchProduct=async()=>{
          setLoading(true);
          try {
            const resp=await axios.post('https://swarnsutra.com/api/singleProduct',{id:productId});
            if(resp.data.length==0){
              setLoading(false);
              toast.error('Product not found!');
            }else{
              setLoading(false);
              setShowImage(resp.data[0].images);
            }
          } catch (error) {
            setLoading(false);
            toast.error('Something went wrong try later!');
          }
        }      
        fetchProduct();
    },[location.pathname])


if(loading){
  return(
    <Loading/>
  )
}

  return (
    <Box sx={{
      margin: { xs: '56px 0 0 0', sm: '64px 0 0 0', md: '64px 0 60px 280px' },
      padding: '20px 45px',
    }}
    >
      <h2>Edit Image</h2>
      <Grid container textAlign='center'>
      {showImage.map((result, index)=>{
        return(
          <Grid item xs={6} sm={4} md={3} mt={10} key={index}>
            <Grid item xs={12}>
              <img src={result} key={index} width='150px'/>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={()=>handleDelete(index)}>Delete</Button>
            </Grid>
          </Grid>
        )
      })}
        <Grid item xs={12} mt={10}>
          <input required onChange={(e)=>{setImage(e.target.files)}} type='file'/>
          <Button variant='contained' onClick={handleSubmit}>Add Image</Button>  
        </Grid>
      </Grid>
      
    
    </Box>
  )
}

export default Update