import React, { useState } from "react";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Lightbox from "yet-another-react-lightbox";
import { Fullscreen, Thumbnails, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Button } from "@mui/material";

export default function FullScreenModal({ images }) {
  const [open, setOpen] = useState(false);

  const imageArray = images.map((image) => ({ src: image }));

  return (
    <>
      <Button
        type="button"
        disableRipple
        sx={{
          color: '#333',
          position: 'absolute',
          top: 0,
          right: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          minWidth: '50px',
          '&:hover': { backgroundColor: 'transparent' }
        }}
        onClick={() => setOpen(true)}
      >
        <FullscreenIcon fontSize="large" />
      </Button>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={imageArray}
        plugins={[Fullscreen, Thumbnails, Zoom]}
      />
    </>
  );
}
