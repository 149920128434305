import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialState = {
    orders : [],
    currentOrder: [],
    totalAmount: 0,
    discount: 0,
    isLoading: false,
}

export const ordersFetch = createAsyncThunk(
    "ordersFetch",
    async(userEmail)=>{
            const response = await axios.post("https://swarnsutra.com/api/getUserOrder", {email:userEmail})
            return response?.data;
    }
)



const orderSlice = createSlice({
    name:"order",
    initialState,
    reducers:{
        orderPlaced:(state, action)=>{
            // const{productName, orderDate} = action.payload;
            // state.orderList = productName;
            // state.date = orderDate;
            // localStorage.setItem("orderItems", JSON.stringify(state.orderList));
            // localStorage.setItem("date", JSON.stringify(state.date));
            
            const {cartItems, totalAmount, discount} = action.payload;
            state.currentOrder = cartItems;
            state.totalAmount = totalAmount;
            state.discount = discount;
        },
        clearCurrentOrder:(state, action)=>{
            state.currentOrder = [];
            state.totalAmount = 0;
            state.discount = 0;
        },
        

    },
     extraReducers: (builder)=>{
        builder.addCase(ordersFetch.pending, (state, action)=>{
            state.isLoading = true;
        });
        builder.addCase(ordersFetch.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.orders = action.payload;
        });
        builder.addCase(ordersFetch.rejected, (state, action)=>{
           state.isLoading = false;
           toast.error("Please Check Your Internet Connection");
        });
    }
});

export default orderSlice.reducer;

export const selectOrders = (state)=> state.order.orders;
export const selectCurrentOrder = (state)=> state.order.currentOrder;
export const selectTotalAmount = (state)=> state.order.totalAmount;
export const selectDiscount = (state)=> state.order.discount;
export const {orderPlaced, clearCurrentOrder} = orderSlice.actions;