import { Box, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import content from '../blogContent';

const SingleBlog = () => {
    const {id} = useParams();
    const { pathname } = useLocation();
    
    const blogContent = content.filter((blog)=> blog.id == id);
    const{image, title, fulldesc, desc2, desc3, desc4, desc5, desc6} = blogContent[0];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Container>
        <Box>
            <Box >
                <Box component='img' src={image} width='100%' sx={{height:{xs:'100%', md:'85vh'},objectFit:'cover'}} />
            </Box>
            <Typography variant="h6" mb={2} mt={2} textAlign='center' fontFamily='Lucida Console, Courier New, monospace'>{title}</Typography>
            <Typography mb={2} fontSize='1.25rem'>{fulldesc}</Typography>
            <Typography mb={2} fontSize='1.25rem'>{desc2}</Typography>
            <Typography mb={2} fontSize='1.25rem'>{desc3}</Typography>
            <Typography mb={2} fontSize='1.25rem'>{desc4}</Typography>
            <Typography mb={2} fontSize='1.25rem'>{desc5}</Typography>
            <Typography mb={2} fontSize='1.25rem'>{desc6}</Typography>
        </Box>
        </Container>
    )
}

export default SingleBlog;