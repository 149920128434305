import {
  AppBar, Box, IconButton, Toolbar, Typography, Drawer, Divider, List, ListItem,
  ListItemButton, ListItemIcon, ListItemText
}
  from '@mui/material';
import React from 'react'
import logo from '../images/swarnsutra_logo.jpg'
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';

import DashboardIcon from '@mui/icons-material/Dashboard';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userLogOut } from '../store/slices/UserSlice';

const drawerWidth = 280;

const navConfig = [
  { title: 'Dashboard', path: '/dashboard', icon: <DashboardIcon /> },
  { title: 'All Products', path: '/dashboard/allproducts', icon: <ShoppingBasketIcon /> },
  { title: 'Orders', path: '/dashboard/orders', icon: <InboxIcon /> },
  { title: 'Users', path: '/dashboard/users', icon: <PersonIcon /> },
  { title: 'Edit Bill Number', path: '/dashboard/editBill', icon: <EditIcon /> },
  { title: 'Coupon', path: '/dashboard/coupon', icon: <LocalOfferIcon /> },
  { title: 'Add Category', path: '/dashboard/category/new', icon: <CategoryIcon /> },
  { title: 'Delete Category', path: '/dashboard/category/delete', icon: < DeleteIcon/>},
  { title: 'Logout', path: '/', icon: <LogoutIcon /> },

]
const AdminNav = (props) => {

  const { window } = props;
  const { userName } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (title, path) => {
    if (title === "Logout") {
      dispatch(userLogOut());
      localStorage.removeItem("userInfo");
      navigate('/');
    } else {
      navigate(path);
    }
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Box padding='10px 20px'>
        <Link to='/dashboard'>
          <img src={logo}
            style={{ width: '80px', objectFit: 'cover', color: 'transparent', borderRadius: '50%' }}
          />
        </Link>
      </Box>
      <Box margin='20px 20px 40px 20px' padding='16px 20px' bgcolor='rgba(145, 158, 171, 0.12)' borderRadius='9px' display='flex' alignItems='center'>
        <Box width='40px' height='40px'>
          <img src='https://minimal-kit-react.vercel.app/assets/images/avatars/avatar_25.jpg'
            style={{ width: '100%', height: '100%', objectFit: 'cover', textAlign: 'center', color: 'transparent', borderRadius: '50%' }}
          />
        </Box>
        <Box ml='16px'>
          <Typography variant='h6' fontSize='0.875rem' color='rgb(33, 43, 54)' fontWeight={600}>{userName}</Typography>
        </Box>
      </Box>
      <Divider />

      <Box>
        <List sx={{
          maxHeight:'400px',
          overflowY:'auto',
          pb: '3.5rem',
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#c9f0f0',
            borderRadius: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#88c7c7',
            borderRadius: '8px'
          }
        }}>
          {navConfig.map(({ title, path, icon }) => (
            <ListItem key={title} disablePadding>
              <ListItemButton onClick={() => handleNavigation(title, path)} sx={{
                bgcolor: '#fff',

                '&.active': {
                  color: 'rgb(33, 43, 54)',
                  bgcolor: 'rgba(145, 158, 171, 0.16)',
                  fontWeight: '700',
                },
              }}
              >
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText disableTypography primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'rgb(248,250,255)',
          boxShadow: 'none',
          backdropFilter: `blur(6px)`,
          backgroundColor: 'rgba(249, 250, 251, 0.8)',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >

        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
          >
            <MenuIcon sx={{ color: "rgb(99, 115, 129)" }} />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography sx={{ color: 'rgb(99, 115, 129)' }}>Admin Panel</Typography>
          </Box>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        position='fixed'
        sx={{
          width: { md: drawerWidth }, flexShrink: { md: 0 }, backgroundColor: 'rgb(249, 250, 255)', height: '100%', zIndex: '1200', overflow: { sm: '', md: 'hidden' } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflowY:'hidden' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, overflowY:'hidden' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>

  )
}

export default AdminNav;