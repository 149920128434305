// this components will check either payment is success or failed and display corressponding ui

import { Box, Button, Container, Grid} from '@mui/material'
import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from 'axios';
function Checking() {
    const {userName, userEmail, shippingDetails} = useSelector((state) => state.user);
    const cartItems = useSelector((state) => state.carts.cartItems);
    const subtotal = cartItems.reduce((acc, item)=>acc + item.cartQuantity * item.price, 0);
    const shippingCharge = subtotal > 1000 ? 0 : 100;
    const tax = subtotal * 0.18;
    const total = subtotal + tax + shippingCharge;
    const [searchParams, setSearchParams] = useSearchParams();
    const status =searchParams.get("payment_status");
    const paymentId = searchParams.get("payment_id");
    

        function sucessData(){
            const data = {
                name:userName,
                email:userEmail,
                payment_id:paymentId,
                status:"Success",
                price:total,
                date:Date(Date.now()),
                productName: cartItems
            }
            axios.post("https://swarnsutra.com/api/catchOrderData",data).then((resp)=>{
            })
            return(
                <Container>
                    <Grid container textAlign="center" p={10}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <CheckCircleIcon color='primary' fontSize='large'/>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <h3>Transaction Success</h3>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <i>Thank your for shopping with us</i>
                        </Grid>
                        <Grid item lg={12} mt={2} md={12} sm={12} xs={12}>
                            <Button LinkComponent={Link} to="/">Go Back</Button>
                        </Grid>
                    </Grid>
                </Container>
            )
        }

         function errorData(){
            const data = {
                status:"Failed",
                date:Date(Date.now()),
                price:total,
                payment_id:paymentId,
                productName: cartItems,
                email:userEmail,
                name:userName
            }
            axios.post("https://swarnsutra.com/api/catchOrderData",data)
            return(
                <Container>
                    <Grid container textAlign="center" p={10}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <ErrorIcon color='error' fontSize='large'/>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <h3>Transaction Failed...</h3>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p>If your money is debited from your account.</p>
                            <i>Your money will be automatically refunded back to the same payment method you used to make this payment in 5-7 working days.</i>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                            Payment Id : {paymentId}
                        </Grid>
                        <Grid item lg={12} mt={2} md={12} sm={12} xs={12}>
                            <Button LinkComponent={Link} to="/">Go Back</Button>
                        </Grid>
                    </Grid>
                </Container>
            )
        }

    return (
        <>
            {status=="Failed"?<>{errorData()}</>:<>{sucessData()}</>}
        </>
    )
}

export default Checking