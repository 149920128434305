// this is the footer component

import { Box, Typography, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import banner from '../images/about.jpeg'
import img1 from '../images/g1.jpg'
import img2 from '../images/g2.jpg'
import img3 from '../images/g3.jpg'
import img4 from '../images/g4.jpg'
import img5 from '../images/g5.jpg'
import img6 from '../images/g6.jpg'
import img7 from '../images/g7.jpg'
import img8 from '../images/g8.jpg'
import img9 from '../images/g9.jpg'


function About() {
    const [isHovered, setIsHovered] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (

        <Container>
            {/* <Typography variant="h4" textAlign='center' fontWeight={500} mt={2}>About Us</Typography>
            <Grid container  mt={5} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' textAlign='center'>
                
                <Grid item xs={12} sm={12} md={12} lg={12} p={2}>
                     <Box component='img' src={banner} sx={{objectFit:'contain',width:'34%', borderRadius:'100%', border:'1px solid gray', height:{sm:'95%',md:'92%'}}}></Box>
                </Grid>
                <Grid item  xs={12} sm={12} md={12} lg={12} p={2} sx={{margin:'0px 4rem'}}>
                     <Typography>Here at Swarn sutra, we believe in the purest and finest art of fabric production. We create nature based fabric ranging from stoles to sarees woven especially by hand-operated weaving machines or looms. Thus, aiming at creating a special market place for all the indigenous weavers in the rural areas.</Typography>
                     <Typography paragraph mt={2}>
                     These Fabrics are perfectly crafted to add that extra elegance to your personality combined with additional softness. We pride ourselves by allocating a part of profit to the education funds for the children of these handloom weavers so that they can help us revive the Handloom Industry.
                     </Typography>
                     <Typography textAlign='right'>Ms. Pranjali</Typography>
                </Grid>
            
            </Grid>
            
            / Gallery /
             <Typography variant="h4" textAlign='center' fontWeight={500} mt={8} mb={2}>Gallery</Typography>
            <Grid container spacing={2} mb={5} textAlign="center">
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box component='img' className="aboutimg" src={img1} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"} }></Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box component='img' className="aboutimg" src={img2} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img3}sx={{width:{xs:'100%', sm:'350px'},  boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img4} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img5} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img6} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img9} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img7} sx={{ width:{xs:'100%', sm:'350px'}, height:'260px', boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img8} sx={{width:{xs:'100%', sm:'350px'},  height:'260px', boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
            </Grid> */}

            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' margin='20px auto' padding= '10px 20px'>
                <Typography variant="h4" mb={2}
                sx={{
                    position:'relative',
                    '&::after': {
                    content: `''`,
                    position: 'absolute',
                    width: '100%',
                    height: '3px',
                    display: 'block',
                    margin: '0 auto',
                    backgroundColor:'#4caf50'
            
                  }}}
                >
                    About us
                </Typography>
                <Typography fontSize='1.25rem' color='#666'>"Preserving Timeless Craftsmanship, We Weave the Threads of Tradition."</Typography>
            </Box>

            <Grid container spacing={4} mt={2} mb={2} padding= '10px 20px'>
                <Grid item sm={12} md={6} overflow='hidden'>

                        <Box component='img' src={banner} maxWidth='100%' sx={{height:'auto', objectFit:'cover', borderRadius:'8px', transition:'0.5s ease',
                        transform: isHovered ? 'scale(1.5)' : ''
                    }} 
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    />
                    </Grid>

                    <Grid item sm={12} md={6} >
                       
                        <Typography fontSize='1.125rem'>
                        Swarn Sutra is committed to pursuing the purest and greatest art of cloth making. Our goal is to create nature-inspired textiles ranging from stoles to sarees, all delicately woven on hand-operated weaving machines or looms. By doing so, we hope to create a unique marketplace that recognises and promotes skilled indigenous weavers in remote locations.
                        </Typography>
                        <Typography mt={1} fontSize='1.125rem'>Each cloth we make demonstrates our dedication to quality and craftsmanship. We take care in creating textiles that not only complement your own taste but are also very soft and comfortable. Our weavers pour their knowledge and love into each thread, guaranteeing that each item bears the stamp of their talent and commitment.
                        Aside from our commitment to making magnificent fabrics, we believe firmly in giving back to the communities that foster and maintain our business. That is why a part of our revenues are                    donated to education funding for the children of these handloom weavers. We hope to empower the future generation and help to the resuscitation and expansion of the handloom industry by             sponsoring their education.
                        </Typography>
                        <Typography mt={1} fontSize='1.125rem'>At Swarn Sutra, we foresee a future in which the rich history of handloom weaving flourishes and skilled craftsmen are recognised and supported. By purchasing our textiles, you are not only embracing elegance and quality, but you are also becoming a part of this great cause, promoting good change in the lives of these brilliant weavers and their communities.</Typography>
                        <Typography textAlign='right' mt={2} fontWeight='bold'>Ms. Pranjali</Typography>
                    </Grid>
                
            </Grid>

            
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' margin='20px auto' padding='10px 20px'>
                <Typography variant="h4" mb={2} mt={8}
                    sx={{
                        position: 'relative',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            width: '100%',
                            height: '3px',
                            display: 'block',
                            margin: '0 auto',
                            backgroundColor: '#4caf50'
                        }
                    }}
                >
                    Gallery
                </Typography>
            </Box>
            <Grid container spacing={2} mb={5} textAlign="center">
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box component='img' className="aboutimg" src={img1} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"} }></Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Box component='img' className="aboutimg" src={img2} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img3}sx={{width:{xs:'100%', sm:'350px'},  boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img4} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img5} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img6} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img9} sx={{width:{xs:'100%', sm:'350px'}, boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img7} sx={{ width:{xs:'100%', sm:'350px'}, height:'260px', boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} >
                    <Box component='img' className="aboutimg" src={img8} sx={{width:{xs:'100%', sm:'350px'},  height:'260px', boxShadow:" rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px"}}></Box>
                </Grid>
            </Grid>
            
        </Container>

    )
}

export default About;