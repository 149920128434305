/* the signup component */

import React, { useState } from 'react'
import { Avatar, Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Form, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './Loading';
import axios from 'axios';
import { render } from '@testing-library/react';

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow ] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [OTP, setOTP ] = useState('');
  const data={
    name:name,
    email:email,
    password:password
  }

  const handleSubmit=async (e)=> {
    e.preventDefault();
    if(password==cPassword){
      setLoading(true);
        try {
          const resp=await axios.post('https://swarnsutra.com/api/signup',data);
          if(resp.data.status==200){
            setLoading(false)
            toast.success("OTP sent successfully Please check your email inbox or spam folder");
            setShow(true);
          }else{
            setLoading(false);
            toast.error(resp.data.message);
          }
      } catch (error) {
          setLoading(false);
          toast.error("Something went wrong try later!");
      }
    }else{
      toast.error("Password does not match!");
    }
  }

  const handleVerify=async(e)=>{
    e.preventDefault();
    setShow(false);
    setLoading(true);
    const data={
      email:email,
      otp:OTP
    }
    try {
      const resp=await axios.post('https://swarnsutra.com/api/verifySignup',data);
      if(resp.data.status==200){
        setLoading(false);
        toast.success(`${resp.data.message}`);
        navigate('/login');
      }else{
        setLoading(false);
        setShow(true);
        toast.error(`${resp.data.message}`);
      }
    } catch (error) {
        setLoading(false);
        setShow(true);
        toast.error(`Something went wrong try later!`);
    }
    
  }

if(loading){
  return(
    <Loading/>
  )
}

if(show){
  return(
  <Container component="main" maxWidth="xs" sx={{paddingBottom:"2rem",boxShadow:"rgb(32 32 32 / 8%) 0px 10px 32px 0px;"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form"  onSubmit={handleVerify} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      required
                      fullWidth
                      value={name}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      required
                      fullWidth
                      value={email}
                      disabled
                    />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  required
                  fullWidth
                  label="Enter OTP"
                  autoFocus
                  onChange={(e)=>setOTP(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Validate Otp
            </Button>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>  
  )
}

  return (
        <Container component="main" maxWidth="xs" sx={{ paddingBottom: "2rem", boxShadow: "rgb(32 32 32 / 8%) 0px 10px 32px 0px;", marginBottom: '2rem' }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    label="Full Name"
                    autoFocus
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    required
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    autoComplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    required
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    autoComplete="off"
                    onChange={(e) => setCPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Signup
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  Already have an account?{" "}
                  <Link to="/login" variant="body2">
                    {"Sign in"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
  )
}

export default Signup;
