/* this is the login component */

import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Container, CssBaseline, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux';
import { userLogedIn } from '../store/slices/UserSlice';
import Loading from './Loading';
import axios from 'axios';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [eye, setEye] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const data = { email, password };
  const redirect = location.search ? location.search.split('=')[1] : '/';

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data={
        email:email,
        password:password
      }
      const resp=await axios.post('https://swarnsutra.com/api/login',data);
      if(resp.data.status==200){
        setLoading(false);
        localStorage.clear();
        dispatch(userLogedIn(resp.data.userInfo))
        JSON.parse(localStorage.getItem("userInfo")).role === "admin" ? navigate('/dashboard'): navigate(redirect);
        toast.success(`Welcome ${resp.data.userInfo.name}`);
      }else if(resp.data.status==501){
        setLoading(false);
        toast.info(`${resp.data.message}`);
        navigate('/verifylogin');
      }else{
        setLoading(false);
        toast.error(`${resp.data.message}`);
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Something went wrong try later!`);
    }
  }

  return (
    <>
      {loading ? <Loading /> :
        <Container component="main" maxWidth="xs" sx={{ paddingBottom: "2.5rem", boxShadow: "rgb(32 32 32 / 8%) 0px 10px 32px 0px;", marginBottom: '2rem' }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <AccountCircleIcon color='primary' />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={eye ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setEye(!eye)}>
                        {eye ? <VisibilityIcon color='primary' /> : <VisibilityOffIcon color='primary' />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to={('/resetaccount')} style={{ color: "#1976d2" }}>Forgot password?</Link>
                </Grid>
                <Grid item>
                  Don't have an account?{" "}
                  <Link to="/signup" variant="body2" style={{ color: "#1976d2" }}>
                    {"Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      }
    </>
  )
}

export default Login;
