// this components will display the payment option COD and Online

import { Box, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,Button, Paper } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import CheckoutSteps from '../Components/CheckoutSteps';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState} from "react";
import axios from 'axios';
import Loading from './Loading';
import { userLogOut } from '../store/slices/UserSlice';
import { selectCurrentOrder, selectDiscount, selectTotalAmount } from '../store/slices/OrderSlice';

const Payment = () => {
    const dispatch = useDispatch();
    const [loading,setLoading]=useState(false);
    var currentTimeAndDate = new Date().toLocaleString("en-GB", {weekday:'long', year:'numeric',month:'long',day:'numeric'});
    const navigate=useNavigate();
    var userInfo=JSON.parse(localStorage.getItem("userInfo"));
    const [check,setCheck]=useState(null);
    const {userName, userEmail, shippingDetails} = useSelector((state) => state.user);
    // const cartItems = useSelector((state) => state.carts.cartItems);
    // const subtotal = cartItems.reduce((acc, item)=>acc + item.cartQuantity * item.price, 0);
    // const shippingCharge = subtotal > 1000 ? 0 : 100;
    // const total = subtotal + shippingCharge;
    const currentOrder = useSelector(selectCurrentOrder);
    const totalAmount = useSelector(selectTotalAmount);
    const discount = useSelector(selectDiscount);
   
    const codData = {
        address: {...shippingDetails},
        name:userName,
        email:userEmail,
        productName: currentOrder,
        totalAmount,
        discount,
        orderDate:currentTimeAndDate,
        payment_method: check
    }
    
    
    
    const onlineData={
        purpose:"Creating New Order",
        amount:totalAmount,
        buyer_name:userName,
        email:userEmail,
        redirect_url:'https://swarnsutra.com/api/order/success',
        phone:shippingDetails.phone,
        orderDate:currentTimeAndDate,
        payment_method: check
    }
 
    
      async function processPaymet(e){
        e.preventDefault();
        if(check=="Online"){
            setLoading(true);
            try {
                const resp=await axios.post("https://swarnsutra.com/api/onlineOrder",onlineData,{'headers':{'authorization':userInfo.token,}});
                if(resp.data.status==200){
                    window.location.href=resp.data.url;
                }else if(resp.data.status==505){
                    setLoading(false);
                    dispatch(userLogOut());
                    localStorage.removeItem("userInfo")
                    toast.error(`${resp.data.message}`);
                    navigate('/login');
                }else{
                    setLoading(false);
                    toast.error(`${resp.data.message}`)
                }

            } catch (error) {
                setLoading(false);
                toast.error("Something went wrong !!");
            }
        }
        else if(check=="COD"){
            setLoading(true);
            try {
                const re=await fetch("https://swarnsutra.com/api/codOrder",{ 
                    method:'post', 
                    headers:{ 
                        'authorization':userInfo.token,
                        'Accept':'Application/json', 
                        'Content-Type':'Application/json' 
                    }, 
                    body:JSON.stringify(codData) 
                });
                
                const resp = await re.json();
                if(resp.status==200){
                    setLoading(false);
                    navigate('/order/successfull');
                    localStorage.removeItem("cartItems");
                    toast.success("Order has been placed. Thanks for Shopping with us!");
    
                }else if(resp.status==505){
                    setLoading(false);
                    dispatch(userLogOut());
                    localStorage.removeItem("userInfo")
                    toast.error(`${resp.message}`);
                    navigate('/login');
                }else{
                    setLoading(false)
                    toast.error(`${resp.message}`);
                }
            } catch (error) {
                setLoading(false);
                toast.error("Some server issue try later!");
            }    
        }else{
            setLoading(false);
            toast.error("Please Select Option");
        }
    }
    return (
        <>
        {currentOrder.length === 0 && <Navigate to='/'></Navigate>}
        {loading?<Loading/>:
        
        <Container sx={{ padding: { sm: '1rem 1.2rem', xs: '1rem 2rem' } }}>
            <CheckoutSteps activeStep={2} />
            <Box maxWidth='sm' padding='1rem 2rem'  margin='2rem auto' bgcolor='#F9FAFB' borderRadius='0.375rem'>
            <Box component='form' onSubmit={processPaymet} >
                <FormControl>
                    <FormLabel >Choose Payment Method</FormLabel>
                    <RadioGroup
                        defaultValue="none"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="COD" name='payment' control={<Radio />} label="Cash on Delivery" onChange={(e)=>setCheck(e.target.value)}/>
                        <FormControlLabel value="Online" name='payment' control={<Radio />} label="Oline Payment" onChange={(e)=>setCheck(e.target.value)}/>
                    </RadioGroup>
                <Button type='submit' variant='contained' sx={{marginTop:'1rem'}}>Place Order</Button>
                </FormControl><br/>
            </Box>
            </Box>
        </Container>
        }
        </>
    )
}

export default Payment;


 