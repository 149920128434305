// this components shows all item which is added by the user

import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { clearCart, removeItemAsync, selectCartItems, selectIsLoading, updateCartItemAsync } from '../store/slices/CartSlice'
import { toast } from 'react-toastify';
import Loading from './Loading';

const Cart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const cartItems = useSelector(selectCartItems);
    const isLoading = useSelector(selectIsLoading);
    
    const { isAuthenticated } = useSelector((state) => state.user);
    const totalAmount = cartItems?.reduce((total, item) => {
        total = total + item.product.price * item.cartQuantity;
        return total;
    }, 0);

    const updateCart = (item, action) => {
        var newQuantity = item.cartQuantity;
        if (action === 'dec') {
            if (item.cartQuantity > 1) {
                newQuantity -= 1;
                dispatch(updateCartItemAsync({ id: item._id, quantity: newQuantity }));
                toast.success(`${item.product.title} quantity decreased`);  
            }
        }
        else {
            if(item.cartQuantity >= item.product.quantity){
                toast.warning("max quantity reached");
            }
            else{
                newQuantity += 1;
                dispatch(updateCartItemAsync({ id: item._id, quantity: newQuantity }));
                toast.success(`${item.product.title} quantity increased`);   
            }
        }
    }
    const removeItem = (itemId) => {
        dispatch(removeItemAsync(itemId));
    }


    const checkoutHandler = (e) => {
        if (isAuthenticated) {
            navigate('/shipping');
        }
        else {
            navigate('/login?redirect=/shipping');
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if(isLoading){
       return <Loading/>
    }

    return (
        <Box maxWidth='76rem' mx='auto' px={3} py={3}>
            <Typography variant='h5' color='#111827' fontWeight='700' fontFamily='Apple Symbols,Open Sans, Helvetica Neue'>Your Cart Items</Typography>
            {cartItems.length == 0 ? (
                <Box textAlign="center" mt={2} p={4} minWidth='250px'>
                    <ShoppingBagIcon sx={{ fontSize: '6rem' }} color='info' />
                    <Typography variant="h6">Your Cart is Empty!</Typography>
                    <Typography>Add a few products and come here to checkout.</Typography>
                </Box>
            )
                :
                (
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={8}>
                            <Box mt={2}>

                                {
                                    cartItems && cartItems.map((item) => (
                                        <Box key={item._id} display='flex' sx={{ py: { xs: '1.5rem', sm: '2.5rem' } }} borderTop='1px solid #E5E7EB' minWidth='250px'>
                                            <Box>
                                                <Box component='img' src={item.product.images[0]} sx={{ objectFit: 'cover', objectPosition: 'center', borderRadius: '0.375rem', maxWidth: '100%', width: { xs: '6rem', sm: '12rem' }, height: { xs: '10rem', sm: '100%', md:'100%'} }} />
                                            </Box>
                                            <Box display='flex' justifyContent='space-between' flexDirection='column' sx={{ ml: { xs: '1rem', sm: '1.5rem' } }} flex='1 1 0%'>
                                                <Box position='relative' sx={{ display: { md: 'grid' }, gridTemplateColumns: { md: 'repeat(2, minmax(0, 1fr))' }, columnGap: { md: '1.5rem' } }}>
                                                    <Box>
                                                        <Box display='flex' justifyContent='space-between'>
                                                            <Typography color='#1F2937' sx={{ fontWeight: '500', lineHeight: '1.25rem', margin: '0px', marginBottom: { xs: '0.25rem', sm: '1rem' } }}>{item.product.title}</Typography>
                                                        </Box>
                                                        <Box display='flex' lineHeight='1.25rem' mt='0.25rem'>
                                                            <Typography sx={{ color: '#6B7280', marginBottom: { xs: '0.25rem', sm: '1rem' } }}>{item.product.category}</Typography>
                                                            <Typography sx={{ color: '#6B7280', paddingLeft: '1rem', marginLeft: '1rem', borderLeft: '1px solid #9CA0A5', marginBottom: { xs: '0.25rem', sm: '1rem' } }}>{item.product.color}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography sx={{ color: '#6B7280', marginTop: '0px', marginBottom: { xs: '0.25rem', sm: '1rem' } }}>Price: ₹{item.product.price}.00</Typography>
                                                            <Typography sx={{ color: '#6B7280', margin: '0px', marginBottom: { xs: '0.25rem', sm: '1rem' } }}>Total: ₹{item.cartQuantity * item.product.price}.00</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ mt: { sm: '0px' } }}>

                                                        <Box display='flex' gap={2} alignItems='center' >
                                                            <Button variant='outlined' sx={{ borderRadius: '100%', fontSize: '1rem', minWidth: 35, padding: '3px' }} onClick={() => updateCart(item, 'inc')}>+</Button>
                                                            {item.cartQuantity}
                                                            <Button variant='outlined' sx={{ borderRadius: '100%', fontSize: '1rem', minWidth: 35, padding: '3px' }} onClick={() => updateCart(item, 'dec')}>-</Button>
                                                        </Box>
                                                        <Box position='absolute' top={0} right={0} zIndex={100}>
                                                            <button style={{ color: '#111827', display: 'inline-flex', backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }} onClick={() => removeItem(item._id)}><ClearIcon /></button>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <p style={{ color: '#111827', fontWeight: '500', fontSize: '0.875rem', lineHeight: '1.25rem', margin: '0px', marginTop: '0.25rem' }}>Qty: {item.cartQuantity}</p>
                                            </Box>

                                        </Box>
                                    ))
                                }
                            </Box>
                            <Box>
                                <Button variant='contained' onClick={() => dispatch(clearCart())}>Clear Cart</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} mt={2}>
                            <Box border='1px solid #e3d7d7' bgcolor='#F3F4F6' borderRadius={2} p={2}>
                                <Box >

                                    <Box display='flex' justifyContent='space-between' mb={1}>
                                        <Typography fontSize='1.125rem' color='#6B7280'>SubTotal</Typography>
                                        <Typography fontSize='1.125rem'>₹ {totalAmount}/-</Typography>
                                    </Box>

                                    <Typography paragraph>Taxes and Shipping Calculated at Checkout</Typography>
                                    <Button variant='contained' fullWidth onClick={checkoutHandler}>Checkout</Button>
                                    <Button variant='contained' fullWidth sx={{ marginTop: '7px' }} LinkComponent={Link} to='/products'>Continue Shopping</Button>

                                    {/* <Button variant='contained' fullWidth sx={{ textTransform: 'capitalize', fontSize: '1rem', marginTop: '15px', border: 'none', backgroundColor: '#ae12ae', '&:hover': { bgcolor: '#d616d6', border: 'none' } }}>Checkout</Button> */}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                )
            }

        </Box>
    )
}

export default Cart;

