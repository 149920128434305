import React, { useState, useEffect } from 'react'
import { Container , Box, Typography, Grid} from '@mui/material';
import { useLocation } from 'react-router-dom';
import img1 from '../images/g1.jpg'

const GoalPage = () => {
    const [isHovered, setIsHovered] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <Container>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' margin='20px auto' padding='10px 20px'>
                <Typography variant="h4" mb={2}
                    sx={{
                        position: 'relative',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            width: '100%',
                            height: '3px',
                            display: 'block',
                            margin: '0 auto',
                            backgroundColor: '#4caf50'
                        }
                    }}
                >
                    Our Goal
                </Typography>
                <Typography fontSize='1.25rem' color='#666'>Revitalizing India's Handloom Industry: Legacy, Sustainability, and Global Recognition</Typography>
            </Box>

            <Grid container spacing={4} mt={2} mb={2} padding='10px 20px'>
                <Grid item  xs={12} md={6} overflow='hidden'>

                    <Box component='img' src={img1} maxWidth='100%' sx={{
                        height: { xs: 'auto', md: '400px' }, objectFit: 'cover', borderRadius: '8px', transition:'0.5s ease',
                        transform: isHovered ? 'scale(1.5)' : ''
                    }}
                    onMouseOver={() => setIsHovered(true)}
                    onMouseOut={() => setIsHovered(false)}
                    />
                </Grid>

                <Grid item  xs={12} md={6}>
                   
                    <Typography mt={2} fontSize='1.125rem'>
                    Our goal is to revitalise and elevate the Indian traditional handloom industry via the preservation of its rich legacy, the promotion of sustainable practises, the empowerment of craftsmen, and the promotion of worldwide recognition. We aspire to establish a thriving ecosystem that embraces the distinctive creativity and craftsmanship of handloom textiles via innovation, education, and cooperation, assuring a successful future for our talented craftsmen and the cultural legacy they carry forward.
                    </Typography>
                    
                </Grid>

            </Grid>
        </Container>
  )
}

export default GoalPage;