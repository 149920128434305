// when user's profile is not verified but user's complete signup process

import React, { useState } from 'react'
import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {  Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './Loading';

const VerifyLogin = () => {
  const [loading,setLoading]=useState(false);
  const [email,setEmail]=useState(null)
  const data={email}
const navigate=useNavigate();
const handleSubmit=(e)=>{
  e.preventDefault();
  setLoading(true);
  fetch("https://swarnsutra.com/api/otpSendNonVerified",{ 
    method:'post', 
    headers:{ 
      'Accept':'Application/json', 
      'Content-Type':'Application/json' 
    }, 
    body:JSON.stringify(data)
  }).then((result)=>{ 
    result.json().then((result2)=>{ 
      if(result2.status==200){ 
      setLoading(false);
      toast.success(result2.message) 
      localStorage.setItem("Info",JSON.stringify(result2));
      navigate('/verifyotp');
    }else{
      setLoading(false);
      toast.warn(result2.message);
    }
   }) 
}) 
}
  return (
    <>
    {loading?<Loading/>:
    
    <Container component="main" maxWidth="xs" sx={{paddingBottom:"2rem",boxShadow:"rgb(32 32 32 / 8%) 0px 10px 32px 0px",marginBottom:'2rem'}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify Your Account
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  label="Enter Your Email"
                  fullWidth
                  autoComplete="email"
                  onChange={(e)=>setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send OTP
            </Button>
            </Grid>
          </Grid>
          </Box>
        </Box>
      </Container>
    }
  </>
  )
}

export default VerifyLogin;
